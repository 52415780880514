import { Button, Checkbox, Col, Form, Input, notification, Row, Select, Switch } from 'antd';
import { Catcher } from 'commons';
import { updateUser } from 'core/auth/duck';
import { fetchCashboxes } from 'core/cash/duck';
import { setDashboardMode } from 'core/dashboard/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, orderTypes } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { fetchRequisites, selectRequisites } from '../../modals/SuppliersReportModal/redux/duck';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    warehouses: state.warehouses.warehouses,
    user: state.auth,
    requisites: selectRequisites(state),
    cashboxes: state.cash.cashboxes
});

const mapDispatchToProps = {
    fetchCashboxes,
    fetchWarehouses,
    fetchRequisites,
    setDashboardMode,
    updateUser
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SettingsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ordersView: false,
            warehouses: [],
            stations: []
        };
    }

    componentDidMount() {
        this.getEmployeeSettings();
        this.props.fetchWarehouses();
        this.props.fetchRequisites();
        this.props.fetchCashboxes();
        this.props.setDashboardMode('stations');
        this.getStations();
    }

    getEmployeeSettings = async () => {
        const { employeeId } = this.props;
        const settings = await fetchAPI('GET', 'employee/post/settings', { employeeId }, null);
        this.setState({ ...settings[0] });
    };

    getStations = async () => {
        const stations = await fetchAPI('GET', '/businesses/stations', null, null, { handleErrorInternally: true });

        this.setState({ stations });
    };

    saveSettings = async () => {
        const { employeeId, user, updateUser } = this.props;
        const {
            businessRequisitesId,
            warehouseId,
            cashBoxId,
            postNum,
            dashboardFilter,
            ordersView,
            cashierKey,
            defaultSparePartRTL,
            orderType,
            orderPaymentMethod,
            defaultOrderSort
        } = this.state;

        await fetchAPI(
            'PUT',
            `/employee/post/settings/${employeeId}`,
            null,
            {
                businessRequisitesId: businessRequisitesId || null,
                warehouseId: warehouseId || null,
                defaultOrderSort: defaultOrderSort || null,
                cashBoxId: cashBoxId || null,
                postNum: postNum || null,
                dashboardFilter: dashboardFilter || null,
                ordersView: Boolean(ordersView) || false,
                cashierKey: cashierKey || null,
                defaultSparePartRTL: defaultSparePartRTL || false,
                orderType: orderType || null,
                orderPaymentMethod: orderPaymentMethod || null
            },
            {
                handleErrorInternally: true
            }
        );

        updateUser({
            ...user,
            businessRequisitesId,
            warehouseId,
            cashBoxId,
            postNum,
            dashboardFilter,
            ordersView,
            cashierKey,
            defaultSparePartRTL,
            orderType,
            orderPaymentMethod
        });

        notification.success({
            message: this.props.intl.formatMessage({
                id: 'barcode.success'
            })
        });
    };

    render() {
        const { warehouses, requisites, cashboxes, positions, user } = this.props;
        const {
            businessRequisitesId,
            warehouseId,
            cashBoxId,
            postNum,
            dashboardFilter,
            ordersView,
            cashierKey,
            stations,
            orderType,
            orderPaymentMethod,
            defaultOrderSort,
            defaultSparePartRTL
        } = this.state;

        const isGrantedAccess = !isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_WINDOW_SETTINGS, accesses.ROWO);

        return (
            <Catcher>
                <React.Fragment>
                    <Form>
                        <Row className={Styles.row}>
                            <Col span={7}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_settings.business_requisitions' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    disabled={
                                        positions.findIndex(({ postName }) => postName === 'MASTER_RECEIVER') === -1 ||
                                        isGrantedAccess
                                    }
                                    onChange={value => {
                                        this.setState({
                                            businessRequisitesId: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'employee_settings.business_requisitions'
                                    })}
                                    style={{ marginBottom: 8, display: 'block', width: 350 }}
                                    value={businessRequisitesId}
                                >
                                    {requisites.map(elem => (
                                        <Option key={elem.id} value={elem.id}>
                                            {elem.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={7}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_settings.warehouse' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    disabled={
                                        positions.findIndex(
                                            ({ postName }) =>
                                                postName === 'MASTER_RECEIVER' || postName === 'SPARE_PARTS_SPECIALIST'
                                        ) === -1 || isGrantedAccess
                                    }
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({
                                            warehouseId: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'employee_settings.warehouse'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 350 }}
                                    value={warehouseId}
                                >
                                    {warehouses.map(elem => (
                                        <Option key={elem.id} value={elem.id}>
                                            {elem.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={7}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_settings.cashbox' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    disabled={
                                        positions.findIndex(
                                            ({ postName }) => postName === 'MASTER_RECEIVER' || postName === 'CASHIER'
                                        ) === -1 || isGrantedAccess
                                    }
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({
                                            cashBoxId: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'employee_settings.cashbox'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 350 }}
                                    value={cashBoxId}
                                >
                                    {cashboxes.map(({ name, id }) => (
                                        <Option key={id} value={id}>
                                            {name}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={7}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_settings.post' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    disabled={
                                        positions.findIndex(({ postName }) => postName === 'MECHANIC') === -1 ||
                                        isGrantedAccess
                                    }
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({
                                            postNum: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'employee_settings.post'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 350 }}
                                    value={postNum}
                                >
                                    {stations.map(({ name, num }) => (
                                        <Option key={num} value={num}>
                                            {name}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={7}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_settings.dashboard' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    disabled={
                                        positions.findIndex(({ postName }) => postName === 'MASTER_RECEIVER') === -1 ||
                                        isGrantedAccess
                                    }
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({
                                            dashboardFilter: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'employee_settings.dashboard'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 350 }}
                                    value={dashboardFilter}
                                >
                                    <Option value='1D'>1D</Option>
                                    <Option value='7D'>7D</Option>
                                    <Option value='MECH'>
                                        <FormattedMessage id='dashboard-page.mech' />
                                    </Option>
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={7}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_settings.view_all_orders' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Switch
                                    checked={ordersView}
                                    disabled={
                                        positions.findIndex(({ postName }) => postName === 'MECHANIC') === -1 ||
                                        isGrantedAccess
                                    }
                                    onChange={() => {
                                        this.setState({
                                            ordersView: !ordersView
                                        });
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={7}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_settings.EGS_key' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Input
                                    disabled={
                                        positions.findIndex(({ postName }) => postName === 'CASHIER') === -1 ||
                                        isGrantedAccess
                                    }
                                    onChange={event => {
                                        this.setState({
                                            cashierKey: event.target.value
                                        });
                                    }}
                                    style={{
                                        maxWidth: 350
                                    }}
                                    value={cashierKey}
                                />
                            </Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={7}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='storage_funds.order_type' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    disabled={
                                        positions.findIndex(({ postName }) => postName === 'MASTER_RECEIVER') === -1 ||
                                        isGrantedAccess
                                    }
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({
                                            orderType: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'storage_funds.order_type'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 350 }}
                                    value={orderType}
                                >
                                    {orderTypes.map(elem => (
                                        <Option key={`${elem}-aggr`} value={elem}>
                                            <FormattedMessage id={`aggregate.${elem}`} />
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={7}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_settings.pay_type' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    disabled={isGrantedAccess}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({
                                            orderPaymentMethod: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'employee_settings.pay_type'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 350 }}
                                    value={orderPaymentMethod}
                                >
                                    <Option key='cash' value='cash'>
                                        <FormattedMessage id='add_order_form.cash' />
                                    </Option>
                                    <Option key='visa' value='visa'>
                                        <FormattedMessage id='add_order_form.visa' />
                                    </Option>
                                    <Option key='noncash' value='noncash'>
                                        <FormattedMessage id='add_order_form.non-cash' />
                                    </Option>
                                </Select>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={7}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='defaultOrderSort' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({
                                            defaultOrderSort: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'defaultOrderSort'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 350 }}
                                    value={defaultOrderSort}
                                >
                                    {[
                                        'datetime_desc',
                                        'datetime_asc',
                                        'beginDatetime_desc',
                                        'beginDatetime_asc',
                                        'deliveryDatetime_desc',
                                        'deliveryDatetime_asc',
                                        'successDatetime_desc',
                                        'successDatetime_asc'
                                    ].map(val => (
                                        <Option key='cash' value={val}>
                                            <FormattedMessage id={`sort.${val}`} />
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={7}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='Запчастист за замовчуванням в RTL' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    checked={defaultSparePartRTL}
                                    disabled={
                                        positions.findIndex(
                                            ({ postName }) =>
                                                postName === 'MASTER_RECEIVER' || postName === 'SPARE_PARTS_SPECIALIST'
                                        ) === -1 || isGrantedAccess
                                    }
                                    onChange={value => {
                                        this.setState({
                                            defaultSparePartRTL: !defaultSparePartRTL
                                        });
                                    }}
                                    style={{
                                        maxWidth: 350
                                    }}
                                    value={defaultSparePartRTL}
                                />
                            </Col>
                        </Row>
                    </Form>
                </React.Fragment>
                <div className={Styles.okBtnStyle}>
                    <Button
                        disabled={isGrantedAccess}
                        onClick={async () => {
                            await this.saveSettings();
                            this.getEmployeeSettings();
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='save' />
                    </Button>
                </div>
            </Catcher>
        );
    }
}
