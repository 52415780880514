import { AutoComplete, Button, Input, InputNumber, Modal, Select, Tooltip, notification } from 'antd';
import { MODALS } from 'core/modals/duck';
import _ from 'lodash';
import { DetailCatalogueModal, WMSCellsModal } from 'modals';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StockIcon } from 'theme';
import { buildStoreGroupsTree, fetchAPI, numeralFormatter, numeralParser } from 'utils';

import * as constants from 'pages/Storage/constants';
import AddStoreProductModal from './AddStoreProductModal';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

@injectIntl
class AddProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            editMode: false,
            alertModalVisible: false,
            storeGroupsTree: [],
            storageProducts: [],
            brandSearchValue: '',
            visible: false,
            showCellModal: false,
            showFromCellModal: false,
            brandId: undefined,
            brandName: undefined,
            detailCode: undefined,
            groupId: undefined,
            tradeCode: undefined,
            detailName: undefined,
            uktz: undefined,
            addToAddress: undefined,
            getFromAddress: undefined,
            sellingPrice: 0,
            stockPrice: 0,
            quantity: 1,
            docProductUnitId: 1,
            orderId: undefined,
            detailCatalogueFormDocs: true,
            storageBalance: [
                { messageId: 'storage.in_stock', count: 0 },
                { messageId: 'storage.reserve', count: 0 },
                { messageId: 'storage.in_orders', count: 0 },
                { messageId: 'storage.ordered', count: 0 },
                { messageId: 'storage.deficit', count: 0 },
                { messageId: 'storage.min', count: 0 },
                { messageId: 'storage.max', count: 0 },
                { messageId: 'storage.to_order', count: 0 }
            ]
        };

        this.debouncedQuerySearch = _.debounce(value => {
            this.getStorageProductsByQuery(value);
        }, 1000);

        this.confirmAlertModal = this.confirmAlertModal.bind(this);
        this.cancelAlertModal = this.cancelAlertModal.bind(this);
    }

    getStoreGroups = async () => {
        const storeGroups = await fetchAPI('GET', 'store_groups', null, null, {
            handleErrorInternally: true
        });
        buildStoreGroupsTree(storeGroups);
    };

    getStorageProductsByQuery = async query => {
        const { operationCode } = this.props;
        if (operationCode === 'PRI') {
            const { list: storageProducts } = await fetchAPI(
                'GET',
                'store_products',
                { query, pageSize: 25, withoutPhoto: true, source: 'PRODUCTION' },
                null,
                {
                    handleErrorInternally: true
                }
            );
            this.setState({ storageProducts });
        } else if (operationCode === 'DSO') {
            const { list: storageProducts } = await fetchAPI(
                'GET',
                'store_products',
                { query, pageSize: 25, withoutPhoto: true, source: 'DISASSEMBLY' },
                null,
                {
                    handleErrorInternally: true
                }
            );
            this.setState({ storageProducts });
        } else {
            const { list: storageProducts } = await fetchAPI(
                'GET',
                'store_products',
                { query, pageSize: 25, withoutPhoto: true },
                null,
                {
                    handleErrorInternally: true
                }
            );
            this.setState({ storageProducts });
        }
    };

    getProductId = async (detailCode, brandId, productId) => {
        const { type, documentType } = this.props;
        const { storageBalance, detailName, quantity } = this.state;
        let storageProduct = null;
        if (productId) {
            storageProduct = await fetchAPI('GET', `store_products/${productId}`, null, null, {
                handleErrorInternally: true
            });
        } else {
            const { list } = await fetchAPI(
                'GET',
                'store_products/',
                {
                    filterCode: detailCode,
                    brandId,
                    pageSize: 15
                },
                null,
                {
                    handleErrorInternally: true
                }
            );
            storageProduct = list.find(({ code, brandId: brand }) => code === detailCode && brand === brandId);
        }

        if (storageProduct) {
            let addToAddress = null;
            let getFromAddress = null;
            const preferAddress = _.get(storageProduct, 'cellAddresses[0]');

            if (type === constants.INCOME || documentType === constants.ORDERINCOME) {
                addToAddress = preferAddress;
            } else if (type === constants.EXPENSE || type === constants.TRANSFER) {
                getFromAddress = preferAddress;
            }
            storageBalance[0].count = storageProduct.countInWarehouses;
            storageBalance[1].count = storageProduct.reservedCount;
            storageBalance[2].count = storageProduct.countInOrders;
            storageBalance[3].count = storageProduct.countInStoreOrders;
            storageBalance[4].count = storageProduct.lack;
            storageBalance[5].count = storageProduct.min;
            storageBalance[6].count = storageProduct.max;
            storageBalance[7].count = storageProduct.quantity;

            this.setState({
                detailCode: storageProduct.code,
                groupId: storageProduct.groupId,
                productId: storageProduct.id,
                detailName: storageProduct.name,
                uktz: storageProduct.uktz,
                brandId: storageProduct.brandId,
                brandName: storageProduct.brand && storageProduct.brand.name,
                tradeCode: storageProduct.tradeCode,
                quantity: storageProduct.quantity || 1,
                stockPrice: storageProduct.stockPrice || storageProduct.purchasePrice,
                sellingPrice: storageProduct.sellingPrice,
                // stockPrice: (this.props.sellingPrice ?
                //     storageProduct.stockPrice * (storageProduct.group && storageProduct.group.multiplier || 1.4) :
                //     storageProduct.stockPrice) || 0,
                addToAddress:
                    (storageProduct.defaultWarehouseId === this.props.warehouseId ? addToAddress : undefined) ||
                    storageProduct.getFromAddress,
                getFromAddress:
                    (storageProduct.defaultWarehouseId === this.props.warehouseId ? getFromAddress : undefined) ||
                    storageProduct.getFromAddress,
                orderId: storageProduct.orderId,
                docProductUnitId: storageProduct.productUnitId,
                unit: storageProduct.unit,
                sourceForProduct: storageProduct.source
            });

            return true;
        }

        storageBalance[0].count = 0;
        storageBalance[1].count = 0;
        storageBalance[2].count = 0;
        storageBalance[3].count = 0;
        storageBalance[4].count = 0;
        storageBalance[5].count = 0;
        storageBalance[6].count = 0;
        storageBalance[7].count = 0;
        this.setState({
            groupId: undefined,
            productId: undefined,
            detailName: this.props.warning ? detailName : undefined,
            quantity: quantity || 1
        });

        return false;
    };

    confirmAlertModal() {
        const { setModal } = this.props;
        const {
            detailCode,
            name,
            brandId,
            brandName,
            sellingPrice,
            stockPrice,
            quantity,
            getFromAddress,
            addToAddress,
            orderId,
            uktz,
            productUnitId,
            docProductUnitId,
            storeGroupId
        } = this.state;

        setModal(MODALS.STORE_PRODUCT, {
            brandId,
            brandName,
            groupId: storeGroupId || 1000000,
            code: detailCode,
            productUnitId: docProductUnitId,
            name,
            onClose: async () => {
                await this.cancelAlertModal();
                this.setState({
                    loading: false
                });
            },
            onSubmit: async id => {
                try {
                    const detail = await fetchAPI('GET', `store_products/${id}`);
                    const {
                        brand,
                        code,
                        name,
                        // uktz,
                        // getFromAddress,
                        // addToAddress,
                        // sellingPrice,
                        // quantity,
                        tradeCode
                        // orderId,
                        // productUnitId
                        // purchasePrice,
                    } = detail;

                    await this.props.addDocProduct({
                        productId: id,
                        detailCode: code,
                        brandName: brand.name,
                        brandId: brand.id,
                        tradeCode,
                        detailName: name,
                        getFromAddress,
                        addToAddress,
                        uktz,
                        stockPrice: Number(stockPrice || 0),
                        sellingPrice: Number(sellingPrice || 0),
                        quantity: quantity || 1, // should be sellingPrice count or stockPrice count depends on documentType,
                        orderId,
                        docProductUnitId
                    });
                    this.handleCancel();
                } catch (error) {
                    this.setState({
                        loading: false
                    });
                    notification.error({
                        message: this.props.intl.formatMessage({ id: 'storage_document.error.is_already_exist' })
                    });
                }
            }
        });
        this.setState({
            alertModalVisible: false
        });
    }

    cancelAlertModal() {
        this.setState({
            alertModalVisible: false,
            loading: false
        });
    }

    loadingTrue = async () => {
        this.setState({
            loading: true
        });
    };

    handleOk = async () => {
        const {
            intl: { formatMessage },
            product,
            operationCode
        } = this.props;
        const {
            editMode,
            brandId,
            brandName,
            detailCode,
            tradeCode,
            groupId,
            detailName,
            uktz,
            stockPrice,
            quantity,
            productId,
            sellingPrice,
            addToAddress,
            getFromAddress,
            comment,
            orderId,
            docProductUnitId,
            sourceForProduct
        } = this.state;

        try {
            if (sourceForProduct !== 'PRODUCTION' && operationCode === 'PRI') {
                notification.error({
                    message: formatMessage({ id: 'storage_document.error.message.PRI' })
                });

                return;
            }

            if (sourceForProduct !== 'DISASSEMBLY' && operationCode === 'DSO') {
                notification.error({
                    message: formatMessage({ id: 'storage_document.error.message.DSO' })
                });

                return;
            }

            if (!brandId || !detailCode) {
                notification.error({
                    message: formatMessage({ id: 'storage_document.error.required_fields' })
                });

                return;
            }

            const isProduct = productId
                ? true
                : await fetchAPI(
                      'GET',
                      'store_products/',
                      {
                          filterCode: detailCode,
                          brandId,
                          pageSize: 15
                      },
                      null,
                      {
                          handleErrorInternally: true
                      }
                  );

            if (!productId) {
                isProduct.list = _.get(isProduct, 'list', []).filter(
                    ({ code, brandId: brand }) => code === detailCode && brand === brandId
                );
            }

            const storageProductFind =
                _.get(isProduct.list, 'length', []) && isProduct !== true
                    ? isProduct.list.find(({ code, brandId: brand }) => code === detailCode && brand === brandId)
                    : undefined;

            if (storageProductFind) {
                await this.props.addDocProduct({
                    productId: storageProductFind.id,
                    detailCode: storageProductFind.id,
                    brandName: storageProductFind.brandName,
                    brandId: storageProductFind.brandId,
                    tradeCode: tradeCode || storageProductFind.tradeCode,
                    detailName: detailName || storageProductFind.name,
                    uktz: uktz || storageProductFind.uktz,
                    stockPrice: stockPrice || storageProductFind.stockPrice,
                    sellingPrice: sellingPrice || storageProductFind.sellingPrice,
                    quantity: quantity || storageProductFind.quantity,
                    groupId: groupId || storageProductFind.groupId,
                    sum: quantity * stockPrice || storageProductFind.quantity * storageProductFind.stockPrice,
                    sellingSum:
                        quantity * sellingPrice || storageProductFind.quantity * storageProductFind.sellingPrice,
                    addToAddress,
                    getFromAddress,
                    ordersAppurtenancies: storageProductFind.ordersAppurtenancies || null,
                    comment,
                    orderId,
                    docProductUnitId: docProductUnitId || storageProductFind.productUnitId
                });
                this.handleCancel();
            } else if (!isProduct || !_.get(isProduct.list, 'length', [])) {
                this.setState({
                    alertModalVisible: true,
                    loading: true
                });
                const { listParts } = await fetchAPI(
                    'GET',
                    'products/search/v2/m3',
                    {
                        code: detailCode,
                        brandId: brandId === 8000 ? undefined : brandId,
                        page: 1,
                        pageSize: 1
                    },
                    null,
                    { handleErrorInternally: true }
                );
                if (listParts && listParts.length) {
                    this.setState({
                        detailName: listParts[0].name,
                        name: listParts[0].name,
                        storeGroupId: listParts[0].storeGroupId
                    });
                }
            } else if (editMode) {
                this.setState({
                    loading: true
                });
                await this.props.editDocProduct(this.props.product.key, {
                    productId,
                    detailCode,
                    brandName,
                    brandId,
                    tradeCode,
                    detailName,
                    uktz,
                    stockPrice,
                    sellingPrice,
                    quantity,
                    groupId,
                    sum: quantity * stockPrice,
                    sellingSum: quantity * sellingPrice,
                    addToAddress,
                    getFromAddress,
                    ordersAppurtenancies: product.ordersAppurtenancies,
                    comment,
                    orderId,
                    docProductUnitId
                });
                this.handleCancel();
            } else {
                this.setState({
                    loading: true
                });
                await this.props.addDocProduct({
                    productId,
                    detailCode,
                    brandName,
                    brandId,
                    tradeCode,
                    detailName,
                    uktz,
                    stockPrice,
                    quantity,
                    groupId,
                    sellingPrice,
                    sum: quantity * stockPrice,
                    sellingSum: quantity * sellingPrice,
                    addToAddress,
                    getFromAddress,
                    comment,
                    orderId,
                    docProductUnitId
                });
                this.handleCancel();
            }
        } catch (error) {
            this.setState({
                loading: false
            });
            console.log(error);
            notification.error({
                message: this.props.intl.formatMessage({ id: 'storage_document.error.is_already_exist' })
            });
        }
    };

    handleCancel() {
        this.setState({
            brandSearchValue: '',
            visible: false,
            brandId: undefined,
            brandName: undefined,
            detailCode: undefined,
            groupId: undefined,
            productId: undefined,
            tradeCode: undefined,
            detailName: undefined,
            uktz: undefined,
            addToAddress: undefined,
            getFromAddress: undefined,
            showCellModal: false,
            showFromCellModal: false,
            purchasePrice: 0,
            sellingPrice: 0,
            stockPrice: 0,
            quantity: 1,
            editMode: false,
            comment: undefined,
            orderId: undefined,
            docProductUnitId: undefined,
            name: undefined,
            loading: false,
            storeGroupId: undefined,
            sourceForProduct: undefined
        });
        this.props.hideModal();
    }

    componentDidUpdate(prevProps, prevState) {
        const { product, operationCode } = this.props;

        if (!prevProps.visible && this.props.visible) {
            if (product) {
                this.setState({
                    editMode: true,
                    brandId: product.brandId,
                    brandName: product.brandName,
                    detailCode: product.detailCode,
                    tradeCode: product.tradeCode,
                    groupId: product.groupId,
                    detailName: product.detailName,
                    uktz: product.uktz,
                    stockPrice: product.stockPrice,
                    quantity: product.quantity,
                    productId: product.productId,
                    ordersAppurtenancies: product.ordersAppurtenancies,
                    sellingPrice: product.sellingPrice,
                    addToAddress: product.addToAddress,
                    getFromAddress: product.getFromAddress,
                    comment: product.comment,
                    orderId: product.orderId,
                    sourceForProduct: product.product.source
                });
            }
        }
        if (prevState.productId !== this.state.productId && operationCode === 'SRT' && !product) {
            this.setState({
                sellingPrice: this.state.stockPrice
            });
        }
    }

    componentDidMount() {
        this.getStoreGroups();
    }

    selectProduct = async product => {
        const { cells, type, documentType } = this.props;
        const { storageBalance } = this.state;
        if (product) {
            let addToAddress;
            let getFromAddress;
            // let preferAddress = product.cellAddresses
            //     ? cells.find(cell => cell.address === product.cellAddresses[0] && cell.enabled)
            //     : undefined;
            // preferAddress = preferAddress ? preferAddress.address : undefined;

            const preferAddress = _.get(product, 'cellAddresses[0]');

            if (type === constants.INCOME || documentType === constants.ORDERINCOME) {
                addToAddress = preferAddress;
            } else if (type === constants.EXPENSE || type === constants.TRANSFER) {
                getFromAddress = preferAddress;
            }

            if (type === constants.INCOME || documentType === constants.ORDERINCOME || type === constants.TRANSFER) {
                addToAddress = preferAddress;
            } else if (type === constants.EXPENSE) {
                getFromAddress = product.cellAddress || preferAddress;
            }

            storageBalance[0].count = product.countInWarehouses;
            storageBalance[1].count = product.reservedCount;
            storageBalance[2].count = product.countInOrders;
            storageBalance[3].count = product.countInStoreOrders;
            storageBalance[4].count = product.lack;
            storageBalance[5].count = product.min;
            storageBalance[6].count = product.max;
            storageBalance[7].count = product.quantity;

            await this.setState({
                productId: product.id || product.productId,
                brandId: product.brandId || product.supplierBrandId,
                brandName: product.brand && product.brand.name,
                detailCode: product.productCode,
                detailName: product.name,
                name: product.name,
                storeGroupId: product.storeGroupId,
                uktz: product.uktz,
                tradeCode: product.tradeCode,
                stockPrice: Math.round(product.purchasePrice * 10000) / 10000 || 0,
                sellingPrice: Math.round(product.price * 10000) / 10000 || 0,
                quantity: product.quantity || 1,
                // addToAddress: addToAddress || product.addToAddress,
                // getFromAddress: getFromAddress || product.getFromAddress,

                addToAddress:
                    (product.defaultWarehouseId === this.props.warehouseId ? addToAddress : undefined) ||
                    product.getFromAddress,
                getFromAddress:
                    (product.defaultWarehouseId === this.props.warehouseId ? getFromAddress : undefined) ||
                    product.getFromAddress,
                docProductUnitId: product.productUnitId,
                unit: product.unit,
                sourceForProduct: product.source
            });
        }
    };

    setSupplier = async item => {
        const {
            businessSupplierId,
            retailPrice,
            supplierBrandId,
            purchasePrice,
            supplierOriginalCode,
            partNumber,
            id,
            itemName
        } = item;
        await this.setState({
            productId: id || null,
            brandId: businessSupplierId || supplierBrandId || null,
            detailCode: supplierOriginalCode || partNumber,
            detailName: itemName || '',
            name: itemName || '',
            stockPrice: Math.round(purchasePrice * 10000) / 10000 || 0,
            sellingPrice: Math.round(retailPrice * 10000) / 10000 || 0,
            quantity: 1
        });
    };

    render() {
        const { mapperData, id, isMobile, counterpartId, documentType } = this.props;
        const {
            storageProducts,
            alertModalVisible,
            brandId,
            detailCode,
            detailName,
            uktz,
            stockPrice,
            quantity,
            storageBalance,
            sellingPrice,
            addToAddress,
            getFromAddress,
            comment,
            showCellModal,
            showFromCellModal,
            visible,
            orderId,
            loading,
            docProductUnitId,
            detailCatalogueFormDocs
        } = this.state;

        const purchaseSum = Math.round(quantity * stockPrice * 10000) / 10000;
        const sellingSumData = mapperData.sellingPriceEqualsPurchasePrice
            ? stockPrice
            : mapperData.sellingPriceDisabled
            ? 0
            : sellingPrice;
        const sellingSum = Math.round(quantity * sellingSumData * 10000) / 10000;

        return (
            <Modal
                destroyOnClose
                footer={
                    <div>
                        <Button
                            key='back'
                            onClick={() => {
                                this.handleCancel();
                            }}
                        >
                            <FormattedMessage id='stock_table.button.cancel' />
                        </Button>
                        <Button
                            key='submit'
                            loading={loading}
                            onClick={async () => {
                                await this.handleOk();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='ok' />
                        </Button>
                    </div>
                }
                maskClosable={false}
                onCancel={() => {
                    this.handleCancel();
                }}
                open={this.props.visible}
                width='fit-content'
                zIndex={200}
            >
                <div
                    style={{
                        display: 'flex',
                        flexWrap: isMobile && 'wrap',
                        justifyContent: !isMobile ? 'space-between' : 'end',
                        alignItems: 'flex-end',
                        margin: '24px 0 0 0',
                        gap: 4
                    }}
                >
                    <div
                        className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                        style={{ width: isMobile && 'calc(100% - 24px)' }}
                    >
                        {isMobile && <FormattedMessage id='order_form_table.detail_code' />}

                        <Tooltip placement='top' title={<FormattedMessage id='order_form_table.detail_code' />}>
                            <AutoComplete
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                placeholder={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <div>
                                            <FormattedMessage id='order_form_table.detail_code' />
                                        </div>
                                        <div
                                            style={{
                                                color: 'red'
                                            }}
                                        >
                                            *
                                        </div>
                                    </div>
                                }
                                // onBlur={() => {
                                //     this.getProductId(detailCode, brandId);
                                // }}
                                onChange={value => {
                                    this.setState({
                                        detailCode: String(value).replace(/[^A-Za-z0-9\u0400-\u04FF]/g, ''),
                                        tradeCode: undefined
                                    });

                                    if (value.length >= 3) {
                                        this.debouncedQuerySearch(value);
                                    }
                                }}
                                onSelect={value => {
                                    // await this.setState({
                                    //     detailCode: option.code,
                                    //     detailName: option.detail_name,
                                    //     stockPrice: option.stock_price || option.purchase_price,
                                    //     sellingPrice: option.selling_price
                                    // });
                                    this.getProductId(undefined, undefined, value);
                                }}
                                style={{
                                    minWidth: !isMobile ? 160 : '89%'
                                }}
                                value={detailCode}
                            >
                                {storageProducts.map(elem => {
                                    return (
                                        <Option key={elem.id} value={elem.id}>
                                            {elem.code}
                                        </Option>
                                    );
                                })}
                            </AutoComplete>
                        </Tooltip>
                    </div>
                    {/* <StockProductsModal
                        brandFilter={brandName}
                        brandId={brandId}
                        codeFilter={detailCode}
                        selectProduct={this.selectProduct}
                        stockMode
                        user={this.props.user}
                    /> */}
                    <Button
                        icon={<StockIcon />}
                        onClick={() => {
                            this.setState({
                                visible: true
                            });
                        }}
                        style={{ width: isMobile && '24px' }}
                    />

                    <div
                        className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                        style={{ minWidth: !isMobile ? 140 : '100%' }}
                    >
                        {isMobile && <FormattedMessage id='order_form_table.brand' />}
                        <Tooltip placement='top' title={<FormattedMessage id='order_form_table.brand' />}></Tooltip>
                        <Select
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: !isMobile ? 220 : 'auto'
                            }}
                            filterOption={(input, option) => {
                                return (
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(option.value).indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            onBlur={() => {
                                this.setState({
                                    brandSearchValue: ''
                                });
                            }}
                            onSearch={input => {
                                this.setState({
                                    brandSearchValue: input
                                });
                            }}
                            onSelect={(value, option) => {
                                this.setState({
                                    brandId: value,
                                    tradeCode: undefined,
                                    brandName: option.children
                                });
                            }}
                            placeholder={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div>
                                        <FormattedMessage id='order_form_table.brand' />
                                    </div>
                                    <div
                                        style={{
                                            color: 'red'
                                        }}
                                    >
                                        *
                                    </div>
                                </div>
                            }
                            showSearch
                            style={{
                                maxWidth: !isMobile && 140
                            }}
                            value={brandId}
                        >
                            {this.props.brands.map((elem, index) => (
                                <Option key={index} supplier_id={elem.supplierId} value={elem.brandId}>
                                    {elem.brandName}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div
                        className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                        style={{ minWidth: !isMobile ? 80 : '100%', width: 329 }}
                    >
                        {isMobile && <FormattedMessage id='directories.orders' />}
                        <Tooltip title={<FormattedMessage id='directories.orders' />}>
                            <Input
                                allowClear
                                className={Styles.inputStoreDocService}
                                onChange={value => {
                                    this.setState({
                                        orderId: undefined
                                    });
                                }}
                                onClick={() => {
                                    this.props.saveModal();
                                    this.props.setModal(MODALS.SELECT_ORDER, {
                                        onSubmit: value => {
                                            this.setState({
                                                orderId: value
                                            });
                                        },
                                        onClose: () => {
                                            this.props.loadModal(MODALS.STORE_DOC_SERVICE);
                                        }
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'store_doc_service.source_tooltip'
                                })}
                                value={orderId}
                            />
                        </Tooltip>
                    </div>

                    {this.props.type == constants.EXPENSE || this.props.type == constants.TRANSFER ? (
                        <div
                            className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                            style={{ minWidth: !isMobile ? 80 : '100%', width: 255 }}
                        >
                            {isMobile && <FormattedMessage id='wms.from_cell' />}
                            <Tooltip placement='top' title={<FormattedMessage id='wms.from_cell' />}>
                                <Input
                                    allowClear
                                    onChange={e => {
                                        if (!e.target.value) {
                                            this.setState({
                                                getFromAddress: null
                                            });
                                        }
                                    }}
                                    onClick={() => {
                                        this.setState({ showFromCellModal: true });
                                    }}
                                    value={getFromAddress}
                                />
                                <WMSCellsModal
                                    confirmAction={getFromAddress => {
                                        this.setState({
                                            getFromAddress
                                        });
                                    }}
                                    fixedWarehouse
                                    hideModal={() => {
                                        this.setState({ showFromCellModal: false });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'wms.from_cell'
                                    })}
                                    visible={Boolean(showFromCellModal)}
                                    warehouseId={this.props.warehouseId}
                                />
                            </Tooltip>
                        </div>
                    ) : null}
                    {this.props.type == constants.INCOME ||
                    this.props.documentType == constants.ORDERINCOME ||
                    this.props.type == constants.TRANSFER ? (
                        <div
                            className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                            style={{ minWidth: !isMobile ? 80 : '100%' }}
                        >
                            {isMobile && <FormattedMessage id='wms.cell' />}
                            <Tooltip placement='top' title={<FormattedMessage id='wms.cell' />}>
                                <Input
                                    allowClear
                                    onChange={e => {
                                        if (!e.target.value) {
                                            this.setState({
                                                addToAddress: null
                                            });
                                        }
                                    }}
                                    onClick={() => {
                                        this.setState({ showCellModal: true });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'wms.cell'
                                    })}
                                    value={addToAddress}
                                />
                                <WMSCellsModal
                                    confirmAction={addToAddress => {
                                        this.setState({
                                            addToAddress
                                        });
                                    }}
                                    fixedWarehouse
                                    hideModal={() => {
                                        this.setState({ showCellModal: false });
                                    }}
                                    visible={Boolean(showCellModal)}
                                    warehouseId={this.props.incomeWarehouseId}
                                />
                            </Tooltip>
                        </div>
                    ) : null}

                    <div
                        className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                        style={{ marginRight: isMobile && 4 }}
                    >
                        {isMobile && <FormattedMessage id='order_form_table.count' />}
                        <span className={Styles.priceslNumberInput}>
                            <Tooltip placement='top' title={<FormattedMessage id='order_form_table.count' />}>
                                <InputNumber
                                    decimalSeparator=','
                                    formatter={quantity => numeralFormatter(quantity)}
                                    max={this.props.maxOrdered ? storageBalance[3].count : undefined}
                                    min={0.0001}
                                    onChange={value => {
                                        this.setState({
                                            quantity: value
                                        });
                                    }}
                                    onStep={() => this.setState({})}
                                    parser={quantity => numeralParser(quantity)}
                                    precision={4}
                                    style={{
                                        fontSize: 13
                                    }}
                                    value={quantity}
                                />
                            </Tooltip>
                        </span>
                    </div>

                    <React.Fragment>
                        <div
                            className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                            style={{ marginRight: isMobile && 4 }}
                        >
                            {isMobile && <FormattedMessage id='order_form_table.purchasePrice' />}
                            <span className={Styles.priceslNumberInput}>
                                <Tooltip
                                    placement='top'
                                    title={<FormattedMessage id='order_form_table.purchasePrice' />}
                                >
                                    <InputNumber
                                        controls={false}
                                        decimalSeparator=','
                                        disabled={mapperData.purchasePriceDisabled || this.props.maxOrdered}
                                        formatter={stockPrice => numeralFormatter(stockPrice)}
                                        min={0}
                                        onChange={stockPrice => this.setState({ stockPrice })}
                                        onStep={() => this.setState({})}
                                        parser={stockPrice => numeralParser(stockPrice)}
                                        precision={4}
                                        style={{
                                            fontSize: stockPrice > 999 ? 11 : 13
                                        }}
                                        value={stockPrice}
                                    />
                                </Tooltip>
                            </span>
                        </div>
                        <div className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}>
                            {isMobile && <FormattedMessage id='storage_document.sell_price' />}
                            <span className={Styles.priceslNumberInput}>
                                <Tooltip placement='top' title={<FormattedMessage id='storage_document.sell_price' />}>
                                    <InputNumber
                                        controls={false}
                                        decimalSeparator=','
                                        disabled={this.props.maxOrdered || mapperData.sellingPriceDisabled}
                                        formatter={value => numeralFormatter(value)}
                                        min={0}
                                        onChange={value => {
                                            this.setState({
                                                sellingPrice: value
                                            });
                                        }}
                                        onStep={() => this.setState({})}
                                        parser={value => numeralParser(value)}
                                        precision={4}
                                        style={{
                                            fontSize: stockPrice > 999 ? 11 : 13
                                        }}
                                        value={
                                            mapperData.sellingPriceEqualsPurchasePrice
                                                ? stockPrice
                                                : mapperData.sellingPriceDisabled
                                                ? 0
                                                : sellingPrice
                                        }
                                    />
                                </Tooltip>
                            </span>
                        </div>
                    </React.Fragment>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: isMobile && 'wrap',
                        alignItems: 'flex-end',
                        margin: !isMobile ? '8px 0 8px 0' : '12px 0 12px 0',
                        justifyContent: !isMobile ? 'space-between' : 'end',
                        gap: 4
                    }}
                >
                    {this.props.type == constants.INCOME && !this.props.priceDisabled && (
                        <div
                            style={{
                                minWidth: !isMobile ? 140 : '100%',
                                width: 345,
                                maxWidth: 345
                            }}
                        >
                            <Tooltip
                                placement='bottom'
                                title={
                                    <div>
                                        <FormattedMessage id='order_form_table.detail_code' /> (
                                        <FormattedMessage id='storage.supplier' />)
                                    </div>
                                }
                            >
                                <Input
                                    disabled
                                    placeholder={`${this.props.intl.formatMessage({
                                        id: 'order_form_table.detail_code'
                                    })} (${this.props.intl.formatMessage({
                                        id: 'storage.supplier'
                                    })})`}
                                    readOnly
                                    style={{
                                        color: 'black'
                                    }}
                                />
                            </Tooltip>
                        </div>
                    )}
                    <div
                        style={{
                            minWidth: !isMobile ? 140 : '100%',
                            width: 328,
                            maxWidth: 328
                        }}
                    >
                        {isMobile && <FormattedMessage id='order_form_table.detail_name' />}
                        <Tooltip placement='bottom' title={<FormattedMessage id='order_form_table.detail_name' />}>
                            <Input
                                disabled
                                onChange={event => {
                                    this.setState({
                                        detailName: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_name'
                                })}
                                readOnly
                                style={{
                                    color: 'black'
                                }}
                                value={detailName}
                            />
                        </Tooltip>
                    </div>
                    <div className={Styles.lowerBlockStyle} style={{ minWidth: isMobile && '100%' }}>
                        {isMobile && <FormattedMessage id='storage.trade_code' />}
                        <Tooltip placement='bottom' title={<FormattedMessage id='storage.trade_code' />}>
                            <Input
                                onChange={event => {
                                    this.setState({
                                        uktz: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'storage.trade_code'
                                })}
                                style={{
                                    color: 'black',
                                    maxWidth: 204,
                                    marginRight: 4,
                                    minWidth: isMobile && '100%'
                                }}
                                value={uktz}
                            />
                        </Tooltip>
                    </div>
                    <div className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}>
                        {isMobile && <FormattedMessage id='services_table.units' />}
                        <Tooltip placement='bottom' title={<FormattedMessage id='services_table.units' />}>
                            <Select
                                allowClear
                                dropdownMatchSelectWidth={100}
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={value => {
                                    this.setState({
                                        docProductUnitId: value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'services_table.units_placeholder'
                                })}
                                showSearch
                                style={{ width: 90, color: 'var(--text)', textAlign: 'right', marginRight: 4 }}
                                value={docProductUnitId}
                            >
                                {(this.props.units || []).map((elem, index) => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.shortcut}
                                    </Option>
                                ))}
                            </Select>
                        </Tooltip>
                    </div>
                    {!this.props.priceDisabled && (
                        <React.Fragment>
                            <div
                                className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                                style={{
                                    maxWidth: '180px'
                                    // marginLeft: 100
                                }}
                            >
                                {isMobile && <FormattedMessage id='purchase_sum' />}
                                <span className={Styles.priceslNumberInput}>
                                    <Tooltip placement='bottom' title={<FormattedMessage id='purchase_sum' />}>
                                        <InputNumber
                                            controls={false}
                                            decimalSeparator=','
                                            formatter={value => numeralFormatter(value)}
                                            onStep={() => this.setState({})}
                                            parser={value => numeralParser(value)}
                                            precision={4}
                                            readOnly
                                            style={{
                                                color: 'black',
                                                fontSize: quantity * stockPrice > 999 ? 11 : 13
                                            }}
                                            value={purchaseSum}
                                        />
                                    </Tooltip>
                                </span>
                            </div>
                            <div className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}>
                                {isMobile && <FormattedMessage id='storage_gocument.sell_sum' />}
                                <span className={Styles.priceslNumberInput}>
                                    <Tooltip
                                        placement='bottom'
                                        title={<FormattedMessage id='storage_gocument.sell_sum' />}
                                    >
                                        <InputNumber
                                            controls={false}
                                            decimalSeparator=','
                                            formatter={value => numeralFormatter(value)}
                                            onStep={() => this.setState({})}
                                            parser={value => numeralParser(value)}
                                            precision={4}
                                            readOnly
                                            style={{
                                                color: 'black',
                                                fontSize: quantity * stockPrice > 999 ? 11 : 13

                                                // marginLeft: 10,
                                            }}
                                            value={sellingSum}
                                        />
                                    </Tooltip>
                                </span>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 8
                    }}
                >
                    {mapperData.allowProductComment && (
                        <div>
                            <div
                                style={{
                                    maxWidth: 250,
                                    marginRight: 5
                                    // marginLeft: 100
                                }}
                            >
                                <div>
                                    <FormattedMessage id='comment' />
                                </div>
                                <TextArea
                                    allowClear
                                    onChange={e => this.setState({ comment: e.target.value })}
                                    placeholder={this.props.intl.formatMessage({ id: 'comment' })}
                                    value={comment}
                                />
                            </div>
                        </div>
                    )}
                    <div style={{ display: 'flex' }}>
                        {this.props.type == constants.ORDER && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: isMobile && 'wrap',
                                    justifyContent: isMobile ? 'space-between' : 'flex-start'
                                }}
                            >
                                {storageBalance.map((elem, key) => {
                                    const message =
                                        this.props.intl.formatMessage({ id: elem.messageId }) || elem.messageId;

                                    return (
                                        <div
                                            key={key}
                                            className={
                                                !isMobile ? Styles.addProductItemWrap : Styles.addProducOrderWrapMobile
                                            }
                                        >
                                            <Tooltip placement='bottom' title={message}>
                                                <InputNumber
                                                    decimalSeparator=','
                                                    readOnly
                                                    style={{
                                                        color: 'black'
                                                    }}
                                                    value={elem.count}
                                                />
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>

                <DetailCatalogueModal
                    addDocProduct={this.props.addDocProduct}
                    brandId={brandId}
                    brands={this.props.brands}
                    clientId={documentType == constants.CLIENT ? counterpartId : undefined}
                    code={detailCode}
                    detailCatalogueFormDocs={detailCatalogueFormDocs}
                    handleCancel={this.props.hideModal}
                    hideModal={() => {
                        this.setState({
                            visible: undefined
                        });
                    }}
                    id={id}
                    setSupplier={this.setSupplier}
                    user={this.props.user}
                    onSelect={this.selectProduct}
                    // suppliers={suppliers}
                    // treeData={treeData}
                    visible={Boolean(visible)}
                    warehouseId={this.props.warehouseId}
                />

                <AddStoreProductModal
                    alertVisible={alertModalVisible}
                    cancelAlertModal={this.cancelAlertModal}
                    confirmAlertModal={this.confirmAlertModal}
                    {...this.state}
                >
                    <FormattedMessage id='storage_document.error.product_not_found' />
                </AddStoreProductModal>
            </Modal>
        );
    }
}

export default AddProductModal;
