import {
    CloseOutlined,
    DeleteOutlined,
    DollarOutlined,
    EditOutlined,
    FileProtectOutlined,
    InfoOutlined,
    MenuOutlined,
    PercentageOutlined,
    PlusOutlined,
    PrinterOutlined,
    SaveOutlined,
    SwapOutlined
} from '@ant-design/icons';
import {
    Button,
    DatePicker,
    Dropdown,
    Input,
    Menu,
    Modal,
    Select,
    Space,
    Table,
    Tabs,
    Tooltip,
    notification
} from 'antd';
import { Layout, Numeral } from 'commons';
import { DateRangePicker, HamburgerMenu } from 'components';
import { AddSalaryDocModalCalculated } from 'containers/SalariesDocumentContainer/components/AddSalaryDocModalCalculated';
import { fetchCashboxes } from 'core/cash/duck';
import { MODALS, loadModal, saveModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _, { get } from 'lodash';
import { RefactoredCashOrderModal } from 'modals';
import { CASH_ORDER_TYPES, COUNTERPARTY_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { DocumentsIcon, LogsIcon, PencilIcon } from 'theme';
import { buildSupplierOptions, fetchAPI, getCurrency, goTo } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import {
    SalaryAddRowModal,
    SalaryAdditionTable,
    SalaryEditRowModal,
    SalaryLogsTab,
    SalaryRelatedDocs
} from './components';

import Styles from './styles.m.css';

const { TabPane } = Tabs;

const { Option } = Select;

const requiredField = () => <b style={{ color: 'var(--required)' }}> *</b>;

const defWidth = {
    laborParts: '20%',
    type: '7%',
    btns: '5%',
    menu: '5%',
    date: '8%',
    condition: '10%',
    order: '15%',
    basis: '8%',
    caller: '10%',
    calculation: '10%',
    value: '7%',
    discount: '5%',
    sumPercent: '7%',
    sal: '8%'
};

const mapStateToProps = state => ({
    user: state.auth,
    cashboxes: state.cash.cashboxes,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setModal,
    saveModal,
    loadModal,
    fetchCashboxes
};

const statuses = [
    {
        title: <FormattedMessage id='employee_salary_rules.status.NEW' />,
        key: 'NEW',
        status: 'NEW'
    },
    {
        title: <FormattedMessage id='employee_salary_rules.status.DONE' />,
        key: 'DONE',
        status: 'DONE'
    },
    {
        title: <FormattedMessage id='employee_salary_rules.status.CANCEL' />,
        key: 'CANCEL',
        status: 'CANCEL'
    }
];

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SalaryDocumentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allEmployees: [],
            data: {},
            dataRows: [],
            selectedRows: [],
            selectedRowKeys: undefined,
            requisites: [],
            onlyForCurrentEmployee: true,
            additions: undefined,
            showControls: false
        };

        this.columns = () => [
            {
                title: () => {
                    const {
                        intl: { formatMessage },
                        match: {
                            params: { id }
                        }
                    } = this.props;

                    const { selectedRows, data } = this.state;
                    const actionsMenu = (
                        <Menu className={Styles.actionMenuDropdown}>
                            <Menu.Item
                                key='groupSalaryTable.deleted'
                                disabled={!selectedRows.length || _.get(data, 'status') !== 'NEW'}
                            >
                                <div
                                    onClick={() => {
                                        if (_.get(data, 'status') === 'NEW') {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'add_order_form.delete_confirm'
                                                }),
                                                onOk: async () => {
                                                    await fetchAPI(
                                                        'DELETE',
                                                        'delete/salary/rows',
                                                        null,
                                                        {
                                                            id: Number(id),
                                                            rowIds: selectedRows.map(({ id }) => id)
                                                        },
                                                        {
                                                            handleErrorInternally: true
                                                        }
                                                    );
                                                    notification.success({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'details_table.deleted'
                                                        })
                                                    });
                                                    this.fetchSalaryDocData();
                                                },
                                                zIndex: 2001
                                            });
                                        }
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionsMenu} />;
                },
                key: 'actions',
                align: 'center',
                width: defWidth.menu,
                render: row => {
                    const actionsMenu = () => (
                        <Menu>
                            <Menu.Item
                                key='groupSalaryTable.delete'
                                disabled={_.get(this.state.data, 'status') !== 'NEW'}
                            >
                                <div
                                    onClick={() => {
                                        if (_.get(this.state.data, 'status') === 'NEW') {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'add_order_form.delete_confirm'
                                                }),
                                                onOk: async () => {
                                                    await fetchAPI(
                                                        'DELETE',
                                                        'delete/salary/rows',
                                                        null,
                                                        {
                                                            id: Number(this.props.match.params.id),
                                                            rowIds: [row.id]
                                                        },
                                                        {
                                                            handleErrorInternally: true
                                                        }
                                                    );
                                                    notification.success({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'details_table.deleted'
                                                        })
                                                    });
                                                    this.fetchSalaryDocData();
                                                },
                                                zIndex: 2001
                                            });
                                        }
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    <FormattedMessage id='delete' />
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        <HamburgerMenu actionsMenu={actionsMenu}>
                            <Button
                                data-qa='btn_show_hamburger_menu_modal_services_table_order_page'
                                icon={<MenuOutlined />}
                            />
                        </HamburgerMenu>
                    );
                }
            },
            {
                title: () => (
                    <div className={Styles.headerActions}>
                        <Tooltip placement='top' title={<FormattedMessage id='add' />}>
                            <Button
                                disabled={
                                    // _.get(this.state.data, 'status') !== 'NEW'
                                    this.state.data
                                        ? this.state.data.status !== 'NEW'
                                        : null &&
                                          !isGrantAccessed(
                                              this.props.user,
                                              grants.ACCOUNTING_SALARIES_SALARY_LINES_MANUAL_ADDITION_CORRECTION_LINE
                                          )
                                }
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    this.setState({
                                        rowModalVisible: true
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                ),
                key: 'buttonGroup',
                align: 'center',
                width: defWidth.btns,
                render: (row, _, key) => {
                    return (
                        <div className={Styles.rowActions}>
                            <Tooltip placement='top' title={<FormattedMessage id='labors_table.add_edit_button' />}>
                                <Button
                                    data-qa='btn_add_edit_button_service_product_modal_services_table_order_page'
                                    disabled={this.state.data ? this.state.data.status !== 'NEW' : null}
                                    icon={<PencilIcon />}
                                    onClick={() => {
                                        this.editProduct(row);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='employee_salary_rules.condition' />,
                width: defWidth.condition,
                dataIndex: 'ruleName',
                key: 'ruleName',
                render: ruleName => (
                    <div className={Styles.datetime}>{ruleName || <FormattedMessage id='long_dash' />}</div>
                )
            },
            {
                title: <FormattedMessage id='locations.type' />,
                width: defWidth.type,
                dataIndex: 'type',
                key: 'type',
                render: type => (
                    <div className={Styles.datetime}>
                        {<FormattedMessage id={`employee_salary_rules.${type}`} /> || (
                            <FormattedMessage id='long_dash' />
                        )}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.rule_one' />,
                width: defWidth.basis,
                dataIndex: 'percentFromConstant',
                key: 'percentFromConstant',
                render: (percentFromConstant, row) => (
                    <div className={Styles.datetime}>
                        <div>
                            {percentFromConstant ? (
                                <FormattedMessage id={`employee_salary_rules.${percentFromConstant}`} />
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </div>
                        <div>
                            {row.mark ? (
                                <FormattedMessage id={`employee_salary_rules.${row.mark}`} />
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </div>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.order_n_car' />,
                width: defWidth.order,
                key: 'orderNCar',
                render: row => (
                    <div>
                        <Link to={`${row.order && row.order.startsWith('RTL') ? book.rtl : book.order}/${row.orderId}`}>
                            {row.order || ''}
                        </Link>{' '}
                        {row.vehicle || ''}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.labors_parts_name' />,
                width: defWidth.laborParts,
                key: 'laborsParts',
                render: row => {
                    const allLabors = row.labors
                        ? row.labors.map(elem => <div className={Styles.datetime}>{`${elem.name};`}</div>)
                        : [];
                    const allParts = row.parts
                        ? row.parts.map(elem => <div className={Styles.datetime}>{`${elem.name};`}</div>)
                        : [];

                    const combinedInfo = [...allLabors, ...allParts];

                    return combinedInfo || row.comment;
                }
            },

            {
                title: <FormattedMessage id='date' />,
                dataIndex: 'date',
                width: defWidth.date,
                align: 'center',
                key: 'date',
                render: date => (
                    <div className={Styles.datetime}>
                        {date ? dayjs(date).format('YYYY-MM-DD') : <FormattedMessage id='long_dash' />}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='info_modal.value' />,
                width: defWidth.value,
                dataIndex: 'orderSum',
                key: 'orderSum',
                render: orderSum => (
                    <div className={Styles.datetime}>
                        {orderSum ? Number(orderSum).toFixed(2) : <FormattedMessage id='long_dash' />}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.sum_%' />,
                width: defWidth.sumPercent,
                key: 'percentOrSum',
                render: row =>
                    row.mark == 'COUNT' && row.percent ? (
                        <div className={Styles.datetime}>{Number(row.percent).toFixed(2)}</div>
                    ) : row.percent && row.mark !== 'COUNT' ? (
                        <div className={Styles.datetime}>{`${row.percent} %`}</div>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    )
            },
            {
                title: <FormattedMessage id='order_form_table.discount' />,
                width: defWidth.discount,
                dataIndex: 'discount',
                key: 'discount',
                render: discount => (
                    <div className={Styles.datetime}>
                        {discount ? <FormattedMessage id='yes' /> : <FormattedMessage id='no' />}
                    </div>
                )
            },

            {
                title: <FormattedMessage id='employee_salary_rules.sal' />,
                width: defWidth.sal,
                dataIndex: 'salary',
                key: 'salary',
                render: (salary, row) => (
                    <div
                        style={{
                            color: row.creationType == 'AUTO' ? 'black' : 'red'
                        }}
                    >
                        {Number(salary).toFixed(2)}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.creation_type' />,
                width: defWidth.type,
                dataIndex: 'creationType',
                render: creationType => (
                    <div className={Styles.datetime}>{creationType == 'AUTO' ? 'Auto' : 'Manual'}</div>
                )
            }
        ];

        const { user } = props;
    }

    componentDidMount() {
        this.fetchSalaryDocData();

        this.props.fetchCashboxes();
        this.getAllEmployees();
        this.getRequisites();
    }

    componentDidUpdate(prevProps, prevState) {
        const { data, employeeId, allEmployees } = this.state;
        const currentEmp = allEmployees.find(({ id }) => id == _.get(data, 'employeeId'));
        if (prevState.data !== this.state.data) {
            this.fetchAddition();
            this.setState({
                employeeId: _.get(data, 'employeeId'),
                startDate: dayjs(_.get(data, 'startDate')),
                endDate: dayjs(_.get(data, 'endDate')),
                creationDate: dayjs(_.get(data, 'creationDate')),
                doneDate: _.get(data, 'doneDate') !== null ? dayjs(_.get(data, 'doneDate')) : dayjs(),

                sum: _.get(data, 'sum'),
                comment: _.get(data, 'comment')
            });
        }

        if (prevProps.isMobile !== this.props.isMobile) {
            this.setState({
                activeKey: this.props.isMobile ? 'info' : 'calculated'
            });
        }
    }

    getRequisites = async () => {
        const data = await fetchAPI('GET', 'businesses/requisites', null, null, {
            handleErrorInternally: true
        });
        this.setState({ requisites: data });
    };

    getAllEmployees = async () => {
        const employees = await fetchAPI('GET', 'employees', null, null, { handleErrorInternally: true });
        this.setState({
            allEmployees: employees
        });
    };

    fetchSalaryDocData = async () => {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        const { page, pageSize, query } = this.state;

        const data = await fetchAPI('GET', `/salary_doc/${id}`, undefined, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            data,
            dataRows: data.documentRows,
            ...(data.requisiteId ? { selectedRequisiteId: data.requisiteId } : {})
        });
    };

    saveSalaryDocData = async isBrutto => {
        const {
            match: {
                params: { id }
            }
        } = this.props;

        const {
            data,

            employeeId,
            startDate,
            endDate,

            creationDate,
            doneDate,

            comment,

            selectedRequisiteId
        } = this.state;

        await fetchAPI(
            'PUT',
            `/salary_doc/${id}`,
            null,
            {
                isBrutto,
                requisiteId: selectedRequisiteId,
                employeeId,
                startDate,
                endDate,
                doneDate,
                creationDate,
                status: _.get(data, 'status'),
                type: _.get(data, 'type'),
                // sum: _.get(data, 'sum'),
                // rateSum: _.get(data, 'rateSum'),
                incomeTax: _.get(data, 'incomeTax') || 0,
                insuranceTax: _.get(data, 'insuranceTax') || 0,
                otherTaxes: _.get(data, 'otherTaxes') || 0,
                comment: comment || ' '
            },
            { handleErrorInternally: true }
        );
        this.fetchSalaryDocData();
    };

    calcSalary = async (id, sDate, eDate) => {
        await fetchAPI(
            'POST',
            '/recalculate/employee/salary',
            undefined,
            {
                employeeIds: id,
                startDate: sDate,
                endDate: eDate,
                status: 'ACCRUED'
            },
            { handleErrorInternally: true }
        );
    };

    calcSalaryByAllEmps = async (id, sDate, eDate) => {
        const { data } = this.state;
        await fetchAPI(
            'POST',
            '/recalculate/employee/salary',
            undefined,
            {
                employeeIds: _.get(data, 'employeeId'),
                startDate: sDate,
                endDate: eDate,
                status: 'ACCRUED'
            },
            { handleErrorInternally: true }
        );
    };

    printSalaryReport = async () => {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        const { startDate, doneDate, data } = this.state;
        const response = await fetchAPI(
            'GET',
            '/employee/salaryDoc/report',
            {
                ids: [Number(id)],
                startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined,
                endDate: doneDate ? dayjs(doneDate).format('YYYY-MM-DD') : undefined,
                status: _.get(data, 'status')
            },
            null,
            { rawResponse: true, handleErrorInternally: true }
        );

        const reportFile = await response.blob();

        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
    };

    editProduct(row) {
        this.setState({
            rowEditVisible: true,
            editRow: row
        });
    }

    checkField = (nettoValue, bruttoValue) => {
        const { data } = this.state;

        const result =
            get(data, 'employeeIsNettoSalary') && !get(data, 'isBrutto')
                ? nettoValue
                : get(data, 'isBrutto') || !get(data, 'employeeIsNettoSalary')
                ? bruttoValue
                : 0;

        return result;
    };

    fetchAddition = async () => {
        const {
            match: {
                params: { id }
            }
        } = this.props;

        const { data } = this.state;

        const response = await fetchAPI(
            'GET',
            '/salary/doc/addition',
            { docId: id, status: _.get(data, 'status') || undefined },
            null,
            {
                handleErrorInternally: true
            }
        );

        this.setState({
            additions: response
        });
    };

    toggleControls = () => {
        this.setState(prevState => ({ showControls: !prevState.showControls }));
    };

    render() {
        const {
            match: {
                params: { id }
            },
            user,
            setModal,
            cashboxes,
            isMobile
        } = this.props;

        const {
            allEmployees,
            data,
            visibleCalcSalaryModal,
            dataRows,
            employeeId,
            startDate,
            endDate,
            sum,
            creationDate,
            doneDate,
            otherTaxes,
            comment,
            selectedRowKeys,
            selectedRows,
            rowModalVisible,

            rowEditVisible,
            editRow,
            selectedRequisiteId,
            requisites,
            additions,
            showControls
        } = this.state;

        const dateFormat = 'DD-MM-YYYY';

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        const totalRemaining =
            (additions && this.checkField(additions.nettoPayoutSum, additions.bruttoPayoutSum)) + data.amountLeft;

        const remaining = _.get(data, 'sum', 0) + _.get(data, 'amountLeft', 0);

        const filteredCashboxes = cashboxes ? cashboxes.filter(({ active }) => active) : [];

        const menu = (
            <Menu>
                {_.get(data, 'status') !== 'NEW' &&
                    isGrantAccessed(user, grants.ACCOUNTING_SALARIES_SALARY_HEADER_SALARY_UNPOSTING) && (
                        <Menu.Item
                            key='NEW'
                            onClick={async () => {
                                await fetchAPI(
                                    'PUT',
                                    `/salary_doc/${id}`,
                                    null,
                                    {
                                        requisiteId: selectedRequisiteId,
                                        employeeId: data.employeeId,
                                        startDate: startDate || data.startDate,
                                        endDate: endDate || data.endDate,
                                        doneDate: doneDate || data.doneDate,
                                        status: 'NEW',
                                        type: data.type,
                                        isBrutto: data.isBrutto || false,
                                        incomeTax: data.incomeTax || 0,
                                        insuranceTax: data.insuranceTax || 0,
                                        otherTaxes: data.otherTaxes || 0,
                                        comment: data.comment || ' '
                                    },
                                    { handleErrorInternally: true }
                                );
                                notification.success({
                                    message: this.props.intl.formatMessage({ id: 'barcode.success' })
                                });
                                this.fetchSalaryDocData();
                            }}
                        >
                            <FormattedMessage id='employee_salary_rules.status.NEW' />
                        </Menu.Item>
                    )}
                {_.get(data, 'status') !== 'DONE' && (
                    <Menu.Item
                        key='DONE'
                        onClick={async () => {
                            await fetchAPI(
                                'PUT',
                                `/salary_doc/${id}`,
                                null,
                                {
                                    requisiteId: selectedRequisiteId,
                                    employeeId: data.employeeId,
                                    startDate: startDate || data.startDate,
                                    endDate: endDate || data.endDate,
                                    doneDate: doneDate || data.doneDate,
                                    isBrutto: data.isBrutto || false,
                                    status: 'DONE',
                                    type: data.type,
                                    incomeTax: data.incomeTax || 0,
                                    insuranceTax: data.insuranceTax || 0,
                                    otherTaxes: data.otherTaxes || 0,
                                    comment: data.comment || ' '
                                },
                                { handleErrorInternally: true }
                            );
                            notification.success({
                                message: this.props.intl.formatMessage({ id: 'barcode.success' })
                            });
                            this.fetchSalaryDocData();
                        }}
                    >
                        <FormattedMessage id='employee_salary_rules.status.DONE' />
                    </Menu.Item>
                )}
                {_.get(data, 'status') !== 'CANCEL' && _.get(data, 'status') !== 'DONE' && (
                    <Menu.Item
                        key='CANCEL'
                        onClick={async () => {
                            await fetchAPI(
                                'PUT',
                                `/salary_doc/${id}`,
                                null,
                                {
                                    requisiteId: selectedRequisiteId,
                                    employeeId: data.employeeId,
                                    startDate: startDate || data.startDate,
                                    endDate: endDate || data.endDate,
                                    doneDate: doneDate || data.doneDate,
                                    status: 'CANCEL',
                                    type: data.type,
                                    isBrutto: data.isBrutto || false,
                                    incomeTax: data.incomeTax || 0,
                                    insuranceTax: data.insuranceTax || 0,
                                    otherTaxes: data.otherTaxes || 0,
                                    comment: data.comment || ' '
                                },
                                { handleErrorInternally: true }
                            );
                            notification.success({
                                message: this.props.intl.formatMessage({ id: 'barcode.success' })
                            });
                            this.fetchSalaryDocData();
                        }}
                    >
                        <FormattedMessage id='employee_salary_rules.status.CANCEL' />
                    </Menu.Item>
                )}
            </Menu>
        );

        const isHeaderAccessible = isGrantAccessed(user, grants.ACCOUNTING_SALARIES_SALARY_HEADER);

        const formContent = (
            <div
                style={{
                    borderBottom: '1px solid var(--lightGray)'
                }}
            >
                <div
                    style={{
                        margin: '15px 0px 5px 0px;',
                        padding: '0 0 15px',
                        display: isMobile ? 'block' : 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div
                        style={{
                            width: isMobile ? '100%' : '20%'
                        }}
                    >
                        <FormattedMessage id='employee' />
                        {requiredField()}
                        <div
                            style={{
                                marginBottom: 12,
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Select
                                allowClear
                                disabled={_.get(data, 'status') == 'CANCEL' || _.get(data, 'status') == 'DONE' || !isHeaderAccessible}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        employeeId: value
                                    });
                                }}
                                showSearch
                                style={{ display: 'block', width: '100%' }}
                                value={employeeId}
                                optionFilterProp='children'
                                // mode='multiple'
                                placeholder={this.props.intl.formatMessage({
                                    id: 'search'
                                })}
                            >
                                {employeeId
                                    ? buildSupplierOptions(allEmployees, {
                                          id: employeeId,
                                          name: _.get(data, 'employee')
                                      })
                                          .filter(({ disabled }) => !disabled)
                                          .map(({ id, name, surname }) => (
                                              <Option key={id} value={id}>
                                                  {`${name} ${surname}`}
                                              </Option>
                                          ))
                                    : allEmployees
                                          .filter(({ disabled }) => !disabled)
                                          .map(({ id, name, surname }) => (
                                              <Option key={id} value={id}>
                                                  {`${name} ${surname}`}
                                              </Option>
                                          ))}
                            </Select>

                            {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEES_LIST) && (
                                <Tooltip title={<FormattedMessage id='edit' />}>
                                    <Button
                                        className={Styles.editIcon}
                                        disabled={!isHeaderAccessible}
                                        icon={<EditOutlined />}
                                        onClick={() => {
                                            goTo(`${book.employeesPage}/${employeeId}`);
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>

                        <div className={Styles.fieldsMargin}>
                            <span
                                style={{
                                    marginBottom: 8
                                }}
                            >
                                <FormattedMessage id='setting-salary.period' />
                            </span>
                            <DateRangePicker
                                allowClear
                                dateRange={[startDate, endDate]}
                                disabled={
                                    _.get(data, 'status') == 'CANCEL' ||
                                    _.get(data, 'status') == 'DONE' ||
                                    !isHeaderAccessible
                                }
                                onDateChange={([startDate, endDate]) => {
                                    this.setState({ startDate: startDate.hour(9), endDate: endDate.hour(9) });
                                }}
                                popupStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 200
                                }}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className={Styles.fieldsMargin}>
                            <span
                                style={{
                                    marginBottom: 8
                                }}
                            >
                                <FormattedMessage id='storage_document.business_requisites' />
                            </span>
                            <Select
                                disabled={_.get(data, 'status') == 'CANCEL' || _.get(data, 'status') == 'DONE'|| !isHeaderAccessible}
                                fieldNames={{ value: 'id', label: 'name' }}
                                onSelect={value => this.setState({ selectedRequisiteId: value })}
                                options={requisites}
                                value={selectedRequisiteId}
                                showSearch
                                // style={{ display: 'block', width: '300px' }}
                                // value={employeeId}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            width: isMobile ? '100%' : '20%'
                        }}
                    >
                        <div className={Styles.fieldsMargin}>
                            <FormattedMessage id='universal_filters_tags.beginDate' />

                            <DatePicker
                                allowClear
                                disabled={
                                    _.get(data, 'status') == 'CANCEL' ||
                                    _.get(data, 'status') == 'DONE' ||
                                    !isHeaderAccessible
                                }
                                format={dateFormat}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        creationDate: value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'select_date'
                                })}
                                style={{
                                    width: '100%',
                                    marginTop: 4
                                }}
                                value={creationDate}
                            />
                        </div>
                        <div className={Styles.fieldsMargin}>
                            <FormattedMessage id='storage_journal.closing_date' />

                            <DatePicker
                                allowClear
                                disabled
                                // disabled={
                                //     _.get(data, 'status') == 'CANCEL' ||
                                //     _.get(data, 'status') == 'DONE' ||
                                //     !isHeaderAccessible
                                // }
                                format={dateFormat}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        doneDate: value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'storage_journal.closing_date'
                                })}
                                style={{
                                    width: '100%',
                                    marginTop: 4
                                }}
                                value={doneDate}
                            />
                        </div>
                        <div className={Styles.fieldsMargin}>
                            <FormattedMessage id='order_from_table_date_doc' />
                            <DatePicker
                                allowClear
                                disabled={
                                    _.get(data, 'status') == 'CANCEL' ||
                                    _.get(data, 'status') == 'DONE' ||
                                    !isHeaderAccessible
                                }
                                format={dateFormat}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        doneDate: value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'select_date'
                                })}
                                style={{
                                    width: '100%',
                                    marginTop: 4
                                }}
                                value={doneDate}
                            />
                        </div>
                        <div className={Styles.fieldsMargin} data-qa='comment_storage_document_form'>
                            <FormattedMessage id='comment' />
                            <Input
                                onChange={e => {
                                    this.setState({
                                        comment: e.target.value
                                    });
                                }}
                                style={{
                                    width: isMobile ? '100%' : '250%',
                                    marginTop: 4
                                }}
                                type='textarea'
                                value={comment}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            width: isMobile ? '100%' : '30%'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    paddingRight: 8
                                }}
                            >
                                <div className={Styles.sumWrapper}>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        <FormattedMessage id='employee_salary_rules.accrual' />
                                    </span>
                                    <Numeral
                                        className={Styles.sumNumeral}
                                        currency={getCurrency()}
                                        mask='0,0.00'
                                        nullText='0'
                                    >
                                        {(additions && additions.accrualSum) || data.accrualSum || 0}
                                    </Numeral>
                                </div>

                                <div className={Styles.sumWrapper}>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        <FormattedMessage id='employee_salary_rules.salary' />
                                    </span>
                                    <Numeral
                                        className={Styles.sumNumeral}
                                        currency={getCurrency()}
                                        mask='0,0.00'
                                        nullText='0'
                                    >
                                        {_.get(data, 'rateSum') || 0}
                                    </Numeral>
                                </div>

                                <div className={Styles.sumWrapperLast}>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        <FormattedMessage id='employee_salary_rules.total_accrued' />
                                    </span>
                                    <Numeral
                                        className={Styles.sumNumeral}
                                        currency={getCurrency()}
                                        mask='0,0.00'
                                        nullText='0'
                                    >
                                        {(additions && additions.totalAccrualSum) || data.totalAccrualSum || 0}
                                    </Numeral>
                                </div>

                                <div className={Styles.sumWrapper}>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        <FormattedMessage id='employee_salary_rules.taxes' />
                                    </span>
                                    <Numeral
                                        className={Styles.sumNumeral}
                                        currency={getCurrency()}
                                        mask='0,0.00'
                                        nullText='0'
                                    >
                                        {(additions &&
                                            this.checkField(additions.nettoTotalTaxSum, additions.bruttoTotalTaxSum)) ||
                                            this.checkField(data.nettoTotalTaxSum, data.bruttoTotalTaxSum) ||
                                            0}
                                    </Numeral>
                                </div>
                                <div className={Styles.sumWrapperLast}>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        <FormattedMessage id='employee_salary_rules.PENALTY' />
                                    </span>
                                    <Numeral
                                        className={Styles.sumNumeral}
                                        currency={getCurrency()}
                                        mask='0,0.00'
                                        nullText='0'
                                    >
                                        {(additions &&
                                            this.checkField(
                                                additions.nettoDeductionsTotalSum,
                                                additions.bruttoDeductionsTotalSum
                                            )) ||
                                            this.checkField(
                                                data.nettoDeductionsTotalSum,
                                                data.bruttoDeductionsTotalSum
                                            ) ||
                                            0}
                                    </Numeral>
                                </div>
                                <div className={Styles.sumWrapper}>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        <FormattedMessage id='employee_salary_rules.payment' />
                                    </span>
                                    <Numeral
                                        className={Styles.sumNumeral}
                                        currency={getCurrency()}
                                        mask='0,0.00'
                                        nullText='0'
                                    >
                                        {(additions &&
                                            this.checkField(additions.nettoPayoutSum, additions.bruttoPayoutSum)) ||
                                            this.checkField(data.nettoPayoutSum, data.bruttoPayoutSum) ||
                                            0}
                                    </Numeral>
                                </div>
                            </div>
                            <div
                                className={Styles.sumWrapper}
                                data-qa='remain_storage_document_form'
                                style={{
                                    background: 'var(--static)',
                                    fontSize: 16,
                                    height: '160px',
                                    minWidth: '180px',
                                    width: '100%',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    padding: '0 12px',
                                    cursor: 'pointer'
                                }}
                            >
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        marginBottom: 12,
                                        color: null
                                    }}
                                >
                                    <FormattedMessage id='remain' />:
                                    <Link
                                        to={{
                                            pathname: book.editEmployee.replace(':id', _.get(data, 'employeeId')),
                                            state: {
                                                specificTab: 'empDebtContainer'
                                            }
                                        }}
                                    >
                                        <span className={Styles.remainSum}>
                                            <Numeral
                                                className={Styles.totalSum}
                                                currency={getCurrency()}
                                                mask='0,0.00'
                                                nullText='0'
                                            >
                                                {totalRemaining}
                                            </Numeral>
                                        </span>
                                    </Link>
                                </div>
                                <div
                                    data-qa='paid_storage_document_form'
                                    style={{
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    <FormattedMessage id='paid' />:
                                    <Link
                                        to={{
                                            pathname: book.editEmployee.replace(':id', _.get(data, 'employeeId')),
                                            state: {
                                                specificTab: 'empDebtContainer'
                                            }
                                        }}
                                    >
                                        <span className={Styles.remainSum}>
                                            <Numeral
                                                className={Styles.totalSum}
                                                currency={getCurrency()}
                                                mask='0,0.00'
                                                nullText='0'
                                            >
                                                {_.get(data, 'sum') - remaining}
                                            </Numeral>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <Layout
                controls={
                    <Space size='small'>
                        {isMobile && (
                            <Button
                                className={Styles.fixedMenuButton}
                                icon={<MenuOutlined style={{ fontSize: 20 }} />}
                                onClick={this.toggleControls}
                                size='large'
                                type='text'
                            />
                        )}

                        <div
                            className={
                                isMobile && !showControls
                                    ? Styles.blockStyleMobileNone
                                    : isMobile
                                    ? Styles.blockStyleMobile
                                    : Styles.blockStyle
                            }
                        >
                            <div className={Styles.headerActionBtns}>
                                {_.get(data, 'status') !== 'CANCEL' && (
                                    <Dropdown
                                        data-qa='dropdown_current_salries_table'
                                        disabled={
                                            _.get(data, 'status') == 'CANCEL' ||
                                            (_.get(data, 'status') == 'DONE' &&
                                                !isGrantAccessed(
                                                    user,
                                                    grants.ACCOUNTING_SALARIES_SALARY_HEADER_SALARY_UNPOSTING
                                                ))
                                        }
                                        overlay={menu}
                                    >
                                        <div>
                                            <Button
                                                disabled={
                                                    _.get(data, 'status') == 'CANCEL' ||
                                                    (_.get(data, 'status') == 'DONE' &&
                                                        !isGrantAccessed(
                                                            user,
                                                            grants.ACCOUNTING_SALARIES_SALARY_HEADER_SALARY_UNPOSTING
                                                        ))
                                                }
                                                icon={
                                                    <SwapOutlined
                                                        style={{
                                                            fontSize: 24
                                                        }}
                                                    />
                                                }
                                                type='text'
                                            >
                                                {!isMobile && (
                                                    <span style={{ verticalAlign: 'text-bottom' }}>
                                                        <FormattedMessage id='change_status_dropdown.change_status' />
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </Dropdown>
                                )}
                            </div>
                            <div className={Styles.headerActionBtns}>
                                <Button
                                    disabled={_.get(data, 'status') == 'CANCEL' || _.get(data, 'status') == 'DONE'}
                                    icon={
                                        <SaveOutlined
                                            style={{
                                                fontSize: 24
                                            }}
                                        />
                                    }
                                    onClick={async () => {
                                        await fetchAPI(
                                            'PUT',
                                            `/salary_doc/${id}`,
                                            null,
                                            {
                                                requisiteId: selectedRequisiteId,
                                                employeeId,
                                                startDate,
                                                endDate,
                                                doneDate,
                                                creationDate,
                                                status: _.get(data, 'status'),
                                                type: _.get(data, 'type'),
                                                // sum: _.get(data, 'sum'),
                                                // rateSum: _.get(data, 'rateSum'),
                                                incomeTax: _.get(data, 'incomeTax') || 0,
                                                insuranceTax: _.get(data, 'insuranceTax') || 0,
                                                otherTaxes: _.get(data, 'otherTaxes') || 0,
                                                comment: comment || ' '
                                            },
                                            { handleErrorInternally: true }
                                        );
                                        this.fetchSalaryDocData();
                                    }}
                                    type='text'
                                />
                            </div>
                            {isGrantAccessed(user, grants.ACCOUNTING_SALARIES_SALARY_HEADER_PAYMENT) && (
                                <div className={isMobile ? Styles.headerActionBtnsMobile : Styles.headerActionBtns}>
                                    <Button
                                        disabled={_.get(data, 'status') == 'CANCEL'}
                                        icon={
                                            <DollarOutlined
                                                style={{
                                                    fontSize: 24
                                                }}
                                            />
                                        }
                                        onClick={() => {
                                            setModal(MODALS.REFACTORED_CASH_ORDER, {
                                                initValues: {
                                                    type: CASH_ORDER_TYPES.EXPENSE,
                                                    counterpartyType: COUNTERPARTY_TYPES.EMPLOYEE,
                                                    employeeId: _.get(data, 'employeeId'),
                                                    analyticsUniqueId: 31,
                                                    comment: _.get(data, 'comment'),
                                                    salary: true,
                                                    cashBoxId: _.get(filteredCashboxes, '[0].id'),
                                                    salaryDocId: Number(id),
                                                    sum: totalRemaining
                                                },
                                                onCashOrderCreatedCallback: () => this.fetchSalaryDocData()
                                            });
                                        }}
                                        type='text'
                                    />
                                </div>
                            )}
                            {isGrantAccessed(user, grants.ACCOUNTING_SALARIES_SALARY_HEADER_PRINT) && (
                                <div className={Styles.headerActionBtns}>
                                    <Button
                                        disabled={_.get(data, 'status') === 'CANCEL'}
                                        icon={
                                            <PrinterOutlined
                                                style={{
                                                    fontSize: 24
                                                }}
                                            />
                                        }
                                        onClick={() => this.printSalaryReport()}
                                        type='text'
                                    />
                                </div>
                            )}
                            <div className={Styles.headerActionBtns}>
                                <Tooltip title={<FormattedMessage id='setting-salary.to_calc_salary' />}>
                                    <Button
                                        disabled={_.get(data, 'status') == 'CANCEL' || _.get(data, 'status') == 'DONE'}
                                        icon={
                                            <PercentageOutlined
                                                style={{
                                                    fontSize: 24
                                                }}
                                            />
                                        }
                                        onClick={() => {
                                            this.setState({
                                                visibleCalcSalaryModal: true
                                            });
                                        }}
                                        type='text'
                                    />
                                </Tooltip>
                            </div>
                            <div className={Styles.headerActionBtns}>
                                <Button
                                    icon={
                                        <CloseOutlined
                                            style={{
                                                fontSize: !isMobile ? 24 : 20,
                                                color: 'var(--primary)'
                                            }}
                                        />
                                    }
                                    onClick={async () => {
                                        goTo(book.salariesPage);
                                    }}
                                    type='text'
                                />
                            </div>
                        </div>
                    </Space>
                }
                title={
                    <div>
                        {_.get(data, 'status') ? (
                            <FormattedMessage id={`employee_salary_rules.status.${_.get(data, 'status')}`} />
                        ) : undefined}{' '}
                        {`SAL-${_.get(user, 'businessId', undefined)}-${id}`}
                    </div>
                }
            >
                <React.Fragment>
                    {!isMobile && formContent}
                    <div
                        id='StorageTabs'
                        style={{
                            background: 'white'
                        }}
                    >
                        <Tabs
                            activeKey={this.state.activeKey}
                            className={Styles.orderFormsTabs}
                            onTabClick={key => {
                                this.setState({
                                    activeKey: key
                                });
                            }}
                            size='small'
                            type='card'
                        >
                            {isMobile && (
                                <TabPane
                                    key='info'
                                    tab={
                                        <div className={Styles.tabStyle}>
                                            <InfoOutlined className={Styles.iconsStyle} />
                                            <div>
                                                {`${this.props.intl.formatMessage({
                                                    id: 'add_order_form.general'
                                                })}`}
                                            </div>
                                        </div>
                                    }
                                >
                                    {formContent}
                                </TabPane>
                            )}
                            <TabPane
                                key='calculated'
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <DollarOutlined className={Styles.iconsStyle} />
                                        <div>
                                            {`${this.props.intl.formatMessage({
                                                id: 'Нарах. ЗП'
                                            })}`}
                                        </div>
                                    </div>
                                }
                            >
                                <Table
                                    className={Styles.table}
                                    columns={this.columns()}
                                    dataSource={dataRows}
                                    pagination={false}
                                    rowKey='id'
                                    rowSelection={rowSelection}
                                    scroll={isMobile ? { x: 1100 } : { x: 800, y: '60vh' }}
                                />
                            </TabPane>
                            <TabPane
                                key='addition'
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <FileProtectOutlined className={Styles.iconsStyle} />
                                        <div>
                                            {`${this.props.intl.formatMessage({
                                                id: 'П-тки та утрим.'
                                            })}`}
                                        </div>
                                    </div>
                                }
                            >
                                <SalaryAdditionTable
                                    activeKey={this.state.activeKey}
                                    additions={additions}
                                    data={data}
                                    disabled={_.get(data, 'status') !== 'NEW'}
                                    fetchAddition={this.fetchAddition}
                                    id={id}
                                    saveSalaryDocData={this.saveSalaryDocData}
                                    status={_.get(data, 'status')}
                                />
                            </TabPane>
                            <TabPane
                                key='docs'
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <DocumentsIcon className={Styles.iconsStyle} />
                                        <div>
                                            {`${this.props.intl.formatMessage({
                                                id: 'add_order_form.docs'
                                            })}`}
                                        </div>
                                    </div>
                                }
                            >
                                <SalaryRelatedDocs
                                    activeKey={this.state.activeKey}
                                    id={id}
                                    status={_.get(data, 'status')}
                                />
                            </TabPane>
                            <TabPane
                                key='logs'
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <LogsIcon className={Styles.iconsStyle} />
                                        <div>
                                            {this.props.intl.formatMessage({
                                                id: 'sal_logs'
                                            })}
                                        </div>
                                    </div>
                                }
                            >
                                <SalaryLogsTab
                                    activeKey={this.state.activeKey}
                                    documentId={id}
                                    employees={allEmployees}
                                />
                            </TabPane>
                        </Tabs>
                    </div>

                    <RefactoredCashOrderModal />
                    <AddSalaryDocModalCalculated
                        allEmployees={allEmployees}
                        empId={_.get(data, 'employeeId')}
                        endD={_.get(data, 'endDate')}
                        fetchSalaryDocData={this.fetchSalaryDocData}
                        hideModal={() => {
                            this.setState({
                                visibleCalcSalaryModal: false
                            });
                        }}
                        id={id}
                        onlyForCurrentEmployee
                        startD={_.get(data, 'startDate')}
                        visible={visibleCalcSalaryModal}
                    />
                    {/* <CalcSalaryModal
                        allEmployees={allEmployees}
                        calcSalary={this.calcSalary}
                        calcSalaryByAllEmps={this.calcSalaryByAllEmps}
                        empId={_.get(data, 'employeeId')}
                        onlyForCurrentEmployee
                    /> */}
                    <SalaryAddRowModal
                        allEmployees={allEmployees}
                        empId={_.get(data, 'employeeId')}
                        fetchSalaryDocData={this.fetchSalaryDocData}
                        hideModal={() => {
                            this.setState({
                                rowModalVisible: false
                            });
                        }}
                        id={id}
                        isMobile={this.props.isMobile}
                        visible={rowModalVisible}
                    />
                    <SalaryEditRowModal
                        allEmployees={allEmployees}
                        editRow={editRow}
                        fetchSalaryDocData={this.fetchSalaryDocData}
                        hideModal={() => {
                            this.setState({
                                rowEditVisible: false
                            });
                        }}
                        id={id}
                        isMobile={this.props.isMobile}
                        visible={rowEditVisible}
                    />
                </React.Fragment>
            </Layout>
        );
    }
}
