export const ORD = {
    "business": {
        "name": {
            "ua": "Назва станції",
            "en": "Station Name",
            "cz": "Název stanice",
            "bg": "Име на станцията"
        },
        "address": {
            "ua": "Адреса",
            "en": "Address",
            "cz": "Adresa",
            "bg": "Адрес"
        },
        "phones": {
            "ua": "Номер телефону станції",
            "en": "Station Phone Number",
            "cz": "Telefonní číslo stanice",
            "bg": "Телефонен номер на станцията"
        },
        "logo": {
            "original": {
                "path": {
                    "ua": "Посилання",
                    "en": "Link",
                    "cz": "Odkaz",
                    "bg": "Връзка"
                },
                "width": {
                    "ua": "Ширина",
                    "en": "Width",
                    "cz": "Šířka",
                    "bg": "Широчина"
                },
                "height": {
                    "ua": "Висота",
                    "en": "Height",
                    "cz": "Výška",
                    "bg": "Височина"
                }
            }
        },
        "primaryLogoUrl": {
            "ua": "URL посилання основного логотипу",
            "en": "Primary Logo URL",
            "cz": "URL odkaz základního loga",
            "bg": "URL адрес на основното лого"
        },
        "secondaryLogoUrl": {
            "ua": "URL посилання додаткового логотипу",
            "en": "Secondary Logo URL",
            "cz": "URL odkaz dalšímu logu",
            "bg": "URL адрес на допълнителното лого"
        },
        "domain": {
            "ua": "Домен",
            "en": "Domain",
            "cz": "Doména",
            "bg": "Домен"
        },
        "normHourPrice": {
            "ua": "Ціна за годину",
            "en": "Price per Hour",
            "cz": "Cena za hodinu",
            "bg": "Цена на час"
        },
        "phoneCode": {
            "$code": {
                "ua": "Код номеру телефона",
                "en": "Phone Code",
                "cz": "Kód telefonního čísla",
                "bg": "Код за телефон"
            },
            "$number": {
                "ua": "Номер",
                "en": "Number",
                "cz": "Číslo",
                "bg": "Номер"
            },
            "$country": {
                "ua": "Країна",
                "en": "Country",
                "cz": "Země",
                "bg": "Държава"
            }
        },
        "packageStatus": {
            "ua": "Статус пакета",
            "en": "Package Status",
            "cz": "Status balíčku",
            "bg": "Статус на пакета"
        },
        "currency": {
            "ua": "Валюта",
            "en": "Currency",
            "cz": "Měna",
            "bg": "Валута"
        },
        "base64PrimaryLogo": {
            "ua": "Base64 основного логотипу",
            "en": "Base64 Primary Logo",
            "cz": "Base64 základního loga",
            "bg": "Base64 на основното лого"
        },
        "quantityDecimalPlaces": {
            "ua": "Кількість десяткових знаків для кількості",
            "en": "Decimal Places for Quantity",
            "cz": "Počet desetinných míst pro množství",
            "bg": "Брой десетични знаци за количество"
        },
        "priceDecimalPlaces": {
            "ua": "Кількість десяткових знаків для ціни",
            "en": "Decimal Places for Price",
            "cz": "Počet desetinných míst pro cenu",
            "bg": "Брой десетични знаци за цена"
        },
        "sumDecimalPlaces": {
            "ua": "Кількість десяткових знаків для суми",
            "en": "Decimal Places for Sum",
            "cz": "Počet desetinných míst pro částku",
            "bg": "Брой десетични знаци за сума"
        }
    },
    "businessRequisite": {
        "name": {
            "ua": "Назва",
            "en": "Name",
            "cz": "Název",
            "bg": "Име"
        },
        "address": {
            "ua": "Адреса",
            "en": "Address",
            "cz": "Adresa",
            "bg": "Адрес"
        },
        "bank": {
            "ua": "Банк",
            "en": "Bank",
            "cz": "Banka",
            "bg": "Банка"
        },
        "ifi": {
            "ua": "Код банку",
            "en": "Bank Code",
            "cz": "Kód banky",
            "bg": "Код на банката"
        },
        "ca": {
            "ua": "рахунок IBAN",
            "en": "IBAN account",
            "cz": "číslo účtu IBAN",
            "bg": "IBAN сметка"
        },
        "itn": {
            "ua": "Код ЄДРПОУ",
            "en": "EDRPOU Code",
            "cz": "Kód EDRPOU",
            "bg": "Код на ЕДРПОУ"
        },
        "formType": {
            "ua": "Тип форми",
            "en": "Form Type",
            "cz": "Typ formuláře",
            "bg": "Тип на формата"
        },
        "formName": {
            "ua": "Назва форми",
            "en": "Form Name",
            "cz": "Název formuláře",
            "bg": "Име на формата"
        },
        "isTaxPayer": {
            "ua": "Є платником податків",
            "en": "Is Taxpayer",
            "cz": "Je plátcem daní",
            "bg": "Данъчно задължен"
        },
        "calculationMethod": {
            "ua": "Метод розрахунку",
            "en": "Calculation Method",
            "cz": "Metoda výpočtu",
            "bg": "Метод на изчисление"
        },
        "taxRate": {
            "ua": "Податкова ставка",
            "en": "Tax Rate",
            "cz": "Daňová sazba",
            "bg": "Данъчна ставка"
        },
        "ipn": {
            "ua": "ІПН",
            "en": "IPN",
            "cz": "IČ",
            "bg": "ИПН"
        },
        "certificateNumber": {
            "ua": "Номер сертифікату",
            "en": "Certificate Number",
            "cz": "Číslo certifikátu",
            "bg": "Номер на сертификата"
        },
        "contractNumber": {
            "ua": "Номер контракту",
            "en": "Contract Number",
            "cz": "Číslo smlouvy",
            "bg": "Номер на контракта"
        },
        "contractDate": {
            "ua": "Дата контракту",
            "en": "Contract Date",
            "cz": "Datum smlouvy",
            "bg": "Дата на контракта"
        },
        "director": {
            "ua": "Директор",
            "en": "Director",
            "cz": "Ředitel",
            "bg": "Директор"
        }
    },
    "client": {
        "middleName": {
            "ua": "По батькові",
            "en": "Middle Name",
            "cz": "Střední jméno",
            "bg": "Собствено име"
        },
        "status": {
            "ua": "Статус",
            "en": "Status",
            "cz": "Status",
            "bg": "Статус"
        },
        "birthday": {
            "ua": "Дата народження",
            "en": "Date of Birth",
            "cz": "Datum narození",
            "bg": "Дата на раждане"
        },
        "sex": {
            "ua": "Стать",
            "en": "Gender",
            "cz": "Pohlaví",
            "bg": "Пол"
        },
        "name": {
            "ua": "Ім'я",
            "en": "First Name",
            "cz": "Jméno",
            "bg": "Име"
        },
        "surname": {
            "ua": "Прізвище",
            "en": "Surname",
            "cz": "Příjmení",
            "bg": "Фамилия"
        },
        "phones": {
            "ua": "Телефони",
            "en": "Phones",
            "cz": "Telefony",
            "bg": "Телефони"
        },
        "emails": {
            "ua": "Електронні адреса",
            "en": "Email Addresses",
            "cz": "E-mailové adresy",
            "bg": "Електронни адреси"
        },
        "paymentRespite": {
            "ua": "Відстрочка оплати",
            "en": "Payment Respite",
            "cz": "Odložení platby",
            "bg": "Отсрочка на плащане"
        },
        "type": {
            "ua": "Тип клієнта",
            "en": "Client Type",
            "cz": "Typ zákazníka",
            "bg": "Тип клиент"
        },
        "comment": {
            "ua": "Коментар",
            "en": "Comment",
            "cz": "Komentář",
            "bg": "Коментар"
        },
        "phoneCode": {
            "$code": {
                "ua": "Код",
                "en": "Code",
                "cz": "Kód",
                "bg": "Код"
            },
            "$country": {
                "ua": "Країна",
                "en": "Country",
                "cz": "Země",
                "bg": "Държава"
            }
        },
        "requisites": {
            "$ca": {
                "ua": "IBAN",
                "en": "IBAN",
                "cz": "IBAN",
                "bg": "IBAN"
            },
            "$ifi": {
                "ua": "Код Банку",
                "en": "Bank Code",
                "cz": "Kód banky",
                "bg": "Код на банката"
            },
            "$ipn": {
                "ua": "ІПН",
                "en": "IPN",
                "cz": "IČ",
                "bg": "ИПН"
            },
            "$itn": {
                "ua": "Код ЄДРПОУ",
                "en": "EDRPOU Code",
                "cz": "Kód EDRPOU",
                "bg": "Код на ЕДРПОУ"
            },
            "$bank": {
                "ua": "Банк",
                "en": "Bank",
                "cz": "Banka",
                "bg": "Банка"
            },
            "$name": {
                "ua": "Назва",
                "en": "Name",
                "cz": "Název",
                "bg": "Име"
            },
            "$address": {
                "ua": "Адреса",
                "en": "Address",
                "cz": "Adresa",
                "bg": "Адрес"
            },
            "$enabled": {
                "ua": "Активний",
                "en": "Active",
                "cz": "Aktivní",
                "bg": "Активен"
            },
            "$taxRate": {
                "ua": "Податкова ставка",
                "en": "Tax Rate",
                "cz": "Daňová sazba",
                "bg": "Данъчна ставка"
            },
            "$director": {
                "ua": "Директор",
                "en": "Director",
                "cz": "Ředitel",
                "bg": "Директор"
            },
            "$disabled": {
                "ua": "Неактивний",
                "en": "Inactive",
                "cz": "Neaktivní",
                "bg": "Неактивен"
            },
            "$formName": {
                "ua": "Назва форми",
                "en": "Account Name",
                "cz": "Název formuláře",
                "bg": "Име на сметка"
            },
            "$formType": {
                "ua": "Тип форми",
                "en": "Form Type",
                "cz": "Typ formuláře",
                "bg": "Тип на формата"
            },
            "$isTaxPayer": {
                "ua": "Є платником податків",
                "en": "Is Taxpayer",
                "cz": "Je plátcem daní",
                "bg": "Данъчно задължен"
            },
            "$accountName": {
                "ua": "Назва рахунку",
                "en": "Account Name",
                "cz": "Název účtu",
                "bg": "Име на сметка"
            },
            "$contractDate": {
                "ua": "Дата контракту",
                "en": "Contract Date",
                "cz": "Datum smlouvy",
                "bg": "Дата на контракта"
            },
            "$contractNumber": {
                "ua": "Номер контракту",
                "en": "Contract Number",
                "cz": "Číslo smlouvy",
                "bg": "Номер на контракта"
            },
            "$calculationMethod": {
                "ua": "Метод розрахунку",
                "en": "Calculation Method",
                "cz": "Metoda výpočtu",
                "bg": "Метод на изчисление"
            },
            "$certificateNumber": {
                "ua": "Номер свідотства",
                "en": "Certificate Number",
                "cz": "Číslo osvědčení",
                "bg": "Номер на свидетелството"
            }
        },
        "$accountName": {
            "ua": "Назва рахунку",
            "en": "Account Name",
            "cz": "Název účtu",
            "bg": "Име на сметка"
        },
        "$notifications": {
            "ua": "Сповіщення",
            "en": "Notifications",
            "cz": "Oznámení",
            "bg": "Уведомления"
        },
        "$reviews": {
            "ua": "Відгуки",
            "en": "Reviews",
            "cz": "Hodnocení",
            "bg": "Отзиви"
        }
    },
    "clientRequisite": {
        "name": {
            "ua": "Назва",
            "en": "Name",
            "cz": "Název",
            "bg": "Име"
        },
        "address": {
            "ua": "Адреса",
            "en": "Address",
            "cz": "Adresa",
            "bg": "Адрес"
        },
        "bank": {
            "ua": "Банк",
            "en": "Bank",
            "cz": "Banka",
            "bg": "Банка"
        },
        "ifi": {
            "ua": "Код банку",
            "en": "Bank Code",
            "cz": "Kód banky",
            "bg": "Код на банката"
        },
        "ca": {
            "ua": "рахунок IBAN",
            "en": "IBAN Account",
            "cz": "číslo účtu IBAN",
            "bg": "IBAN сметка"
        },
        "itn": {
            "ua": "Код ЄДРПОУ",
            "en": "EDRPOU Code",
            "cz": "Kód EDRPOU",
            "bg": "Код на ЕДРПОУ"
        },
        "formType": {
            "ua": "Тип форми",
            "en": "Form Type",
            "cz": "Typ formuláře",
            "bg": "Тип на формата"
        },
        "formName": {
            "ua": "Назва форми",
            "en": "Form Name",
            "cz": "Název formuláře",
            "bg": "Име на формата"
        },
        "calculationMethod": {
            "ua": "Метод розрахунку",
            "en": "Calculation Method",
            "cz": "Metoda výpočtu",
            "bg": "Метод на изчисление"
        },
        "taxRate": {
            "ua": "Податкова ставка",
            "en": "Tax Rate",
            "cz": "Daňová sazba",
            "bg": "Данъчна ставка"
        },
        "ipn": {
            "ua": "ІПН",
            "en": "IPN",
            "cz": "IČ",
            "bg": "ИПН"
        },
        "certificateNumber": {
            "ua": "Номер сертифікату",
            "en": "Certificate Number",
            "cz": "Číslo certifikátu",
            "bg": "Номер на сертификата"
        },
        "contractNumber": {
            "ua": "Номер контракту",
            "en": "Contract Number",
            "cz": "Číslo smlouvy",
            "bg": "Номер на контракта"
        },
        "director": {
            "ua": "Директор",
            "en": "Director",
            "cz": "Ředitel",
            "bg": "Директор"
        },
        "accountId": {
            "ua": "Ідентифікатор рахунку",
            "en": "Account Identifier",
            "cz": "Identifikátor účtu",
            "bg": "Идентификатор на сметка"
        },
        "contractDate": {
            "ua": "Дата контракту",
            "en": "Contract Date",
            "cz": "Datum smlouvy",
            "bg": "Дата на контракта"
        }
    },
    "clientVehicle": {
        "vin": {
            "ua": "vin",
            "en": "vin",
            "cz": "vin",
            "bg": "vin"
        },
        "number": {
            "ua": "Номер",
            "en": "Number",
            "cz": "Číslo",
            "bg": "Номер"
        },
        "registrationName": {
            "ua": "Ім'я при реєстрації",
            "en": "Registration Name",
            "cz": "Jméno při registraci",
            "bg": "Име при регистрация"
        },
        "vehicleMakeName": {
            "ua": "Марка транспортного засобу",
            "en": "Vehicle Make",
            "cz": "Značka vozidla",
            "bg": "Марка на превозно средство"
        },
        "vehicleModelName": {
            "ua": "Модель транспортного засобу",
            "en": "Vehicle Model",
            "cz": "Model vozidla",
            "bg": "Модел на превозното средство"
        },
        "vehicleModificationName": {
            "ua": "Модифікація транспортного засобу",
            "en": "Vehicle Modification",
            "cz": "Úprava vozidla",
            "bg": "Модификация на превозното средство"
        },
        "clientVehicleYear": {
            "ua": "Рік транспортного засобу клієнта",
            "en": "Client Vehicle Year",
            "cz": "Rok klientova vozidla",
            "bg": "Година на превозното средство на клиента"
        },
        "makeModYear": {
            "ua": "Рік виробництва та модифікації",
            "en": "Production and Modification Year",
            "cz": "Rok výroby a úpravy",
            "bg": "Година на производство и модификация"
        },
        "numVinOdometer": {
            "ua": "Пробіг автомобіля",
            "en": "Vehicle Mileage",
            "cz": "Najeté kilometry vozidla",
            "bg": "Пробег на автомобила"
        }
    },
    "details": {
        "$detailName": {
            "ua": "Назва деталі",
            "en": "Part Name",
            "cz": "Název dílu",
            "bg": "Име на част"
        },
        "$productId": {
            "ua": "ID продукту",
            "en": "Product ID",
            "cz": "ID produktu",
            "bg": "ID на продукта"
        },
        "$productBrandId": {
            "ua": "ID бренду продукту",
            "en": "Product Brand ID",
            "cz": "ID značky produktu",
            "bg": "ID на марката на продукта"
        },
        "$measureUnit": {
            "ua": "Одиниця виміру",
            "en": "Measurement Unit",
            "cz": "Měnová jednotka",
            "bg": "Мерна единица"
        },
        "$tradeCode": {
            "ua": "Код постачальника",
            "en": "Supplier Code",
            "cz": "Kód dodavatele",
            "bg": "Код на доставчика"
        },
        "$brandName": {
            "ua": "Назва бренду",
            "en": "Brand Name",
            "cz": "Název značky",
            "bg": "Име на марката"
        },
        "$oap_code": {
            "ua": "Оригінальний код",
            "en": "Original Code",
            "cz": "Originální kód",
            "bg": "Оригинален код"
        },
        "$id": {
            "ua": "ID деталі",
            "en": "Detail ID",
            "cz": "ID dílu",
            "bg": "ID на частта"
        },
        "$ids": {
            "ua": "Декілька ID деталей",
            "en": "Multiple Detail IDs",
            "cz": "Více ID dílů",
            "bg": "Няколко ID на части"
        },
        "$agreement": {
            "ua": "Угода",
            "en": "Agreement",
            "cz": "Dohoda",
            "bg": "Споразумение"
        },
        "$status": {
            "ua": "Статус",
            "en": "Status",
            "cz": "Status",
            "bg": "Статус"
        },
        "$productKeyGroup": {
            "ua": "Ключ групи продуктів",
            "en": "Product Group Key",
            "cz": "Klíč skupiny produktů",
            "bg": "Ключ за група продукти"
        },
        "$discount": {
            "ua": "Знижка",
            "en": "Discount",
            "cz": "Sleva",
            "bg": "Отстъпка"
        },
        "$rowDiscountPrice": {
            "ua": "Ціна зі знижкою в рядку",
            "en": "Discounted Price in Line",
            "cz": "Sleva na položku v řádku",
            "bg": "Цена с отстъпка в реда"
        },
        "$discountPrice": {
            "ua": "Ціна зі знижкою",
            "en": "Discounted Price",
            "cz": "Cena se slevou",
            "bg": "Цена с отстъпка"
        },
        "$partRowDiscount": {
            "ua": "Знижка на деталь в рядку",
            "en": "Part Line Discount",
            "cz": "Sleva na položku v řádku",
            "bg": "Отстъпка на част в реда"
        },
        "$serviceRowDiscount": {
            "ua": "Знижка на сервіс в рядку",
            "en": "Service Line Discount",
            "cz": "Sleva na službu v řádku",
            "bg": "Отстъпка на услуга в реда"
        },
        "$count": {
            "ua": "Кількість",
            "en": "Quantity",
            "cz": "Množství",
            "bg": "Количество"
        },
        "$price": {
            "ua": "Ціна",
            "en": "Price",
            "cz": "Cena",
            "bg": "Цена"
        },
        "$discountSum": {
            "ua": "Сума знижки",
            "en": "Discount Sum",
            "cz": "Suma slevy",
            "bg": "Сумата на отстъпката"
        },
        "$sum": {
            "ua": "Сума",
            "en": "Sum",
            "cz": "Suma",
            "bg": "Сума"
        }
    },
    "employee": {
        "email": {
            "ua": "електронна пошта працівника",
            "en": "Employee Email",
            "cz": "e-mail zaměstnance",
            "bg": "Имейл на служителя"
        },
        "fireDate": {
            "ua": "дата звільнення працівника",
            "en": "Employee Termination Date",
            "cz": "datum propuštění zaměstnance",
            "bg": "Дата на освобождаване на служителя"
        },
        "fireReason": {
            "ua": "причина звільнення працівника",
            "en": "Employee Termination Reason",
            "cz": "důvod propuštění zaměstnance",
            "bg": "Причина за освобождаване на служителя"
        },
        "gsmCode": {
            "ua": "gsm-код",
            "en": "gsm Code",
            "cz": "gsm kód",
            "bg": "gsm код"
        },
        "hireDate": {
            "ua": "дата найму",
            "en": "Hiring Date",
            "cz": "datum přijetí",
            "bg": "Дата на наемане"
        },
        "jobTitle": {
            "ua": "призвіще та імʼя",
            "en": "Full Name",
            "cz": "jméno a příjmení",
            "bg": "Пълно име"
        },
        "name": {
            "ua": "ім'я",
            "en": "First Name",
            "cz": "jméno",
            "bg": "Първо име"
        },
        "phone": {
            "ua": "номер телефону",
            "en": "Phone Number",
            "cz": "telefonní číslo",
            "bg": "Телефонен номер"
        },
        "surname": {
            "ua": "прізвище",
            "en": "Surname",
            "cz": "příjmení",
            "bg": "Фамилия"
        },
        "accountId": {
            "ua": "ID облікового запису",
            "en": "Account ID",
            "cz": "ID účtu",
            "bg": "ID на сметката"
        },
        "taxService": {
            "ua": "вибір PPO",
            "en": "PPO Selection",
            "cz": "výběr PPO",
            "bg": "Избор на PPO"
        },
        "barcode": {
            "ua": "штрих-код",
            "en": "Barcode",
            "cz": "čárový kód",
            "bg": "Штрих код"
        },
        "posts": {
            "$postId": {
                "ua": "ID посади",
                "en": "Post ID",
                "cz": "ID pozice",
                "bg": "ID на позиция"
            },
            "$postName": {
                "ua": "назва посади",
                "en": "Post Name",
                "cz": "název pozice",
                "bg": "Име на позицията"
            }
        },
        "medoc": {
            "url": {
                "ua": "url посилання",
                "en": "url Link",
                "cz": "url odkaz",
                "bg": "url адрес"
            },
            "edrpou": {
                "ua": "Код ЄДРПОУ",
                "en": "EDRPOU Code",
                "cz": "Kód EDRPOU",
                "bg": "Код на ЕДРПОУ"
            },
            "login": {
                "ua": "Логін",
                "en": "Login",
                "cz": "Přihlášení",
                "bg": "Логин"
            },
            "dept": {
                "ua": "номер філії",
                "en": "Branch Number",
                "cz": "číslo pobočky",
                "bg": "Номер на филия"
            }
        },
        "accountName": {
            "ua": "назва облікового запису",
            "en": "Account Name",
            "cz": "název účtu",
            "bg": "Име на сметката"
        },
        "roleName": {
            "ua": "назва ролі",
            "en": "Role Name",
            "cz": "název role",
            "bg": "Име на ролята"
        }
    },
    "appurtenanciesResponsible": {
        "email": {
            "ua": "електронна пошта",
            "en": "Email",
            "cz": "e-mail",
            "bg": "Имейл"
        },
        "gsmCode": {
            "ua": "gsm код",
            "en": "gsm Code",
            "cz": "gsm kód",
            "bg": "gsm Код"
        },
        "hireDate": {
            "ua": "дата прийому на роботу",
            "en": "Hiring Date",
            "cz": "datum přijetí do práce",
            "bg": "Дата на наемане"
        },
        "jobTitle": {
            "ua": "посада",
            "en": "Job Title",
            "cz": "pracovní pozice",
            "bg": "Работна длъжност"
        },
        "name": {
            "ua": "ім'я",
            "en": "First Name",
            "cz": "jméno",
            "bg": "Първо име"
        },
        "phone": {
            "ua": "телефон",
            "en": "Phone",
            "cz": "telefon",
            "bg": "Телефон"
        },
        "surname": {
            "ua": "прізвище",
            "en": "Surname",
            "cz": "příjmení",
            "bg": "Фамилия"
        },
        "navigationIcons": [
            {
                "0": {
                    "ua": "ш",
                    "en": "Q",
                    "cz": "r",
                    "bg": "Б"
                },
                "1": {
                    "ua": "в",
                    "en": "u",
                    "cz": "y",
                    "bg": "ъ"
                },
                "2": {
                    "ua": "и",
                    "en": "i",
                    "cz": "c",
                    "bg": "р"
                },
                "3": {
                    "ua": "д",
                    "en": "c",
                    "cz": "h",
                    "bg": "з"
                },
                "4": {
                    "ua": "к",
                    "en": "k",
                    "cz": "l",
                    "bg": "а"
                },
                "5": {
                    "ua": "а",
                    "en": " ",
                    "cz": "á",
                    "bg": " "
                },
                "6": {
                    "ua": " ",
                    "en": "N",
                    "cz": " ",
                    "bg": "н"
                },
                "7": {
                    "ua": "н",
                    "en": "a",
                    "cz": "n",
                    "bg": "а"
                },
                "8": {
                    "ua": "а",
                    "en": "v",
                    "cz": "a",
                    "bg": "в"
                },
                "9": {
                    "ua": "в",
                    "en": "i",
                    "cz": "v",
                    "bg": "и"
                },
                "10": {
                    "ua": "і",
                    "en": "g",
                    "cz": "i",
                    "bg": "г"
                },
                "11": {
                    "ua": "г",
                    "en": "a",
                    "cz": "g",
                    "bg": "а"
                },
                "12": {
                    "ua": "а",
                    "en": "t",
                    "cz": "a",
                    "bg": "ц"
                },
                "13": {
                    "ua": "ц",
                    "en": "i",
                    "cz": "c",
                    "bg": "и"
                },
                "14": {
                    "ua": "і",
                    "en": "o",
                    "cz": "e",
                    "bg": "я"
                },
                "15": {
                    "ua": "я",
                    "en": "n"
                }
            },
            {
                "0": {
                    "ua": "р",
                    "en": "S",
                    "cz": "r",
                    "bg": "Г"
                },
                "1": {
                    "ua": "о",
                    "en": "c",
                    "cz": "o",
                    "bg": "р"
                },
                "2": {
                    "ua": "з",
                    "en": "h",
                    "cz": "z",
                    "bg": "а"
                },
                "3": {
                    "ua": "к",
                    "en": "e",
                    "cz": "v",
                    "bg": "ф"
                },
                "4": {
                    "ua": "л",
                    "en": "d",
                    "cz": "r",
                    "bg": "и"
                },
                "5": {
                    "ua": "а",
                    "en": "u",
                    "cz": "h",
                    "bg": "к"
                },
                "6": {
                    "ua": "д",
                    "en": "l"
                },
                "7": {
                    "en": "e"
                }
            },
            {
                "0": {
                    "ua": "к",
                    "en": "C",
                    "cz": "k",
                    "bg": "К"
                },
                "1": {
                    "ua": "о",
                    "en": "o",
                    "cz": "o",
                    "bg": "о"
                },
                "2": {
                    "ua": "н",
                    "en": "n",
                    "cz": "n",
                    "bg": "н"
                },
                "3": {
                    "ua": "т",
                    "en": "t",
                    "cz": "t",
                    "bg": "т"
                },
                "4": {
                    "ua": "а",
                    "en": "a",
                    "cz": "a",
                    "bg": "а"
                },
                "5": {
                    "ua": "к",
                    "en": "c",
                    "cz": "k",
                    "bg": "к"
                },
                "6": {
                    "ua": "т",
                    "en": "t",
                    "cz": "t",
                    "bg": "т"
                },
                "7": {
                    "ua": "и",
                    "en": "s",
                    "cz": "y",
                    "bg": "и"
                }
            }
        ],
        "ordersIcons": [
            {
                "0": {
                    "ua": "р",
                    "en": "W",
                    "cz": "p",
                    "bg": "Р"
                },
                "1": {
                    "ua": "о",
                    "en": "o",
                    "cz": "r",
                    "bg": "а"
                },
                "2": {
                    "ua": "б",
                    "en": "r",
                    "cz": "á",
                    "bg": "б"
                },
                "3": {
                    "ua": "о",
                    "en": "k",
                    "cz": "c",
                    "bg": "о"
                },
                "4": {
                    "ua": "т",
                    "en": "s",
                    "cz": "e",
                    "bg": "т"
                },
                "5": {
                    "ua": "и",
                    "bg": "и"
                }
            },
            {
                "0": {
                    "ua": "з",
                    "en": "S",
                    "cz": "n",
                    "bg": "Р"
                },
                "1": {
                    "ua": "а",
                    "en": "p",
                    "cz": "á",
                    "bg": "е"
                },
                "2": {
                    "ua": "п",
                    "en": "a",
                    "cz": "h",
                    "bg": "з"
                },
                "3": {
                    "ua": "ч",
                    "en": "r",
                    "cz": "r",
                    "bg": "е"
                },
                "4": {
                    "ua": "а",
                    "en": "e",
                    "cz": "a",
                    "bg": "р"
                },
                "5": {
                    "ua": "с",
                    "en": " ",
                    "cz": "d",
                    "bg": "в"
                },
                "6": {
                    "ua": "т",
                    "en": "P",
                    "cz": "n",
                    "bg": "н"
                },
                "7": {
                    "ua": "и",
                    "en": "a",
                    "cz": "í",
                    "bg": "и"
                },
                "8": {
                    "ua": "н",
                    "en": "r",
                    "cz": " ",
                    "bg": " "
                },
                "9": {
                    "ua": "и",
                    "en": "t",
                    "cz": "d",
                    "bg": "ч"
                },
                "10": {
                    "en": "s",
                    "cz": "í",
                    "bg": "а"
                },
                "11": {
                    "cz": "l",
                    "bg": "с"
                },
                "12": {
                    "cz": "y",
                    "bg": "т"
                },
                "13": {
                    "bg": "и"
                }
            },
            {
                "0": {
                    "ua": "і",
                    "en": "V",
                    "cz": "h",
                    "bg": "И"
                },
                "1": {
                    "ua": "с",
                    "en": "e",
                    "cz": "i",
                    "bg": "с"
                },
                "2": {
                    "ua": "т",
                    "en": "h",
                    "cz": "s",
                    "bg": "т"
                },
                "3": {
                    "ua": "о",
                    "en": "i",
                    "cz": "t",
                    "bg": "о"
                },
                "4": {
                    "ua": "р",
                    "en": "c",
                    "cz": "o",
                    "bg": "р"
                },
                "5": {
                    "ua": "і",
                    "en": "l",
                    "cz": "r",
                    "bg": "и"
                },
                "6": {
                    "ua": "я",
                    "en": "e",
                    "cz": "i",
                    "bg": "я"
                },
                "7": {
                    "ua": " ",
                    "en": " ",
                    "cz": "e",
                    "bg": " "
                },
                "8": {
                    "ua": "т",
                    "en": "H",
                    "cz": " ",
                    "bg": "н"
                },
                "9": {
                    "ua": "р",
                    "en": "i",
                    "cz": "v",
                    "bg": "а"
                },
                "10": {
                    "ua": "а",
                    "en": "s",
                    "cz": "o",
                    "bg": " "
                },
                "11": {
                    "ua": "н",
                    "en": "t",
                    "cz": "z",
                    "bg": "п"
                },
                "12": {
                    "ua": "с",
                    "en": "o",
                    "cz": "i",
                    "bg": "р"
                },
                "13": {
                    "ua": "п",
                    "en": "r",
                    "cz": "d",
                    "bg": "е"
                },
                "14": {
                    "ua": "о",
                    "en": "y",
                    "cz": "l",
                    "bg": "в"
                },
                "15": {
                    "ua": "р",
                    "cz": "a",
                    "bg": "о"
                },
                "16": {
                    "ua": "т",
                    "bg": "з"
                },
                "17": {
                    "ua": "н",
                    "bg": "н"
                },
                "18": {
                    "ua": "о",
                    "bg": "о"
                },
                "19": {
                    "ua": "г",
                    "bg": "т"
                },
                "20": {
                    "ua": "о",
                    "bg": "о"
                },
                "21": {
                    "ua": " ",
                    "bg": " "
                },
                "22": {
                    "ua": "з",
                    "bg": "с"
                },
                "23": {
                    "ua": "а",
                    "bg": "р"
                },
                "24": {
                    "ua": "с",
                    "bg": "е"
                },
                "25": {
                    "ua": "о",
                    "bg": "д"
                },
                "26": {
                    "ua": "б",
                    "bg": "с"
                },
                "27": {
                    "ua": "у",
                    "bg": "т"
                },
                "28": {
                    "bg": "в"
                },
                "29": {
                    "bg": "о"
                }
            },
            {
                "0": {
                    "ua": "з",
                    "en": "O",
                    "cz": "o",
                    "bg": "П"
                },
                "1": {
                    "ua": "а",
                    "en": "r",
                    "cz": "b",
                    "bg": "о"
                },
                "2": {
                    "ua": "м",
                    "en": "d",
                    "cz": "j",
                    "bg": "р"
                },
                "3": {
                    "ua": "о",
                    "en": "e",
                    "cz": "e",
                    "bg": "ъ"
                },
                "4": {
                    "ua": "в",
                    "en": "r",
                    "cz": "d",
                    "bg": "ч"
                },
                "5": {
                    "ua": "л",
                    "en": "s",
                    "cz": "n",
                    "bg": "к"
                },
                "6": {
                    "ua": "е",
                    "cz": "á",
                    "bg": "и"
                },
                "7": {
                    "ua": "н",
                    "cz": "v"
                },
                "8": {
                    "ua": "н",
                    "cz": "k"
                },
                "9": {
                    "ua": "я",
                    "cz": "y"
                }
            },
            {
                "0": {
                    "ua": "п",
                    "en": "P",
                    "cz": "p",
                    "bg": "П"
                },
                "1": {
                    "ua": "о",
                    "en": "o",
                    "cz": "o",
                    "bg": "о"
                },
                "2": {
                    "ua": "с",
                    "en": "s",
                    "cz": "z",
                    "bg": "з"
                },
                "3": {
                    "ua": "а",
                    "en": "i",
                    "cz": "i",
                    "bg": "и"
                },
                "4": {
                    "ua": "д",
                    "en": "t",
                    "cz": "c",
                    "bg": "ц"
                },
                "5": {
                    "ua": "и",
                    "en": "i",
                    "cz": "e",
                    "bg": "и"
                },
                "6": {
                    "en": "o",
                    "bg": "и"
                },
                "7": {
                    "en": "n"
                },
                "8": {
                    "en": "s"
                }
            },
            {
                "0": {
                    "ua": "к",
                    "en": "C",
                    "cz": "k",
                    "bg": "К"
                },
                "1": {
                    "ua": "о",
                    "en": "o",
                    "cz": "o",
                    "bg": "о"
                },
                "2": {
                    "ua": "м",
                    "en": "m",
                    "cz": "m",
                    "bg": "м"
                },
                "3": {
                    "ua": "е",
                    "en": "m",
                    "cz": "e",
                    "bg": "е"
                },
                "4": {
                    "ua": "н",
                    "en": "e",
                    "cz": "n",
                    "bg": "н"
                },
                "5": {
                    "ua": "т",
                    "en": "n",
                    "cz": "t",
                    "bg": "т"
                },
                "6": {
                    "ua": "а",
                    "en": "t",
                    "cz": "á",
                    "bg": "а"
                },
                "7": {
                    "ua": "р",
                    "en": "s",
                    "cz": "ř",
                    "bg": "р"
                },
                "8": {
                    "ua": "і",
                    "cz": "e",
                    "bg": "и"
                }
            },
            {
                "0": {
                    "ua": "ж",
                    "en": "O",
                    "cz": "z",
                    "bg": "Ж"
                },
                "1": {
                    "ua": "у",
                    "en": "r",
                    "cz": "á",
                    "bg": "у"
                },
                "2": {
                    "ua": "р",
                    "en": "d",
                    "cz": "z",
                    "bg": "р"
                },
                "3": {
                    "ua": "н",
                    "en": "e",
                    "cz": "n",
                    "bg": "н"
                },
                "4": {
                    "ua": "а",
                    "en": "r",
                    "cz": "a",
                    "bg": "а"
                },
                "5": {
                    "ua": "л",
                    "en": " ",
                    "cz": "m",
                    "bg": "л"
                },
                "6": {
                    "ua": "и",
                    "en": "L",
                    "cz": "y",
                    "bg": "и"
                },
                "7": {
                    "ua": " ",
                    "en": "o",
                    "cz": " ",
                    "bg": " "
                },
                "8": {
                    "ua": "з",
                    "en": "g",
                    "cz": "o",
                    "bg": "н"
                },
                "9": {
                    "ua": "а",
                    "en": "s",
                    "cz": "b",
                    "bg": "а"
                },
                "10": {
                    "ua": "м",
                    "cz": "j",
                    "bg": " "
                },
                "11": {
                    "ua": "о",
                    "cz": "e",
                    "bg": "п"
                },
                "12": {
                    "ua": "в",
                    "cz": "d",
                    "bg": "о"
                },
                "13": {
                    "ua": "л",
                    "cz": "n",
                    "bg": "р"
                },
                "14": {
                    "ua": "е",
                    "cz": "á",
                    "bg": "ъ"
                },
                "15": {
                    "ua": "н",
                    "cz": "v",
                    "bg": "ч"
                },
                "16": {
                    "ua": "ь",
                    "cz": "e",
                    "bg": "к"
                },
                "17": {
                    "cz": "k",
                    "bg": "и"
                },
                "18": {
                    "bg": "т"
                },
                "19": {
                    "bg": "е"
                }
            },
            {
                "0": {
                    "ua": "д",
                    "en": "C",
                    "cz": "v",
                    "bg": "О"
                },
                "1": {
                    "ua": "з",
                    "en": "a",
                    "cz": "o",
                    "bg": "б"
                },
                "2": {
                    "ua": "в",
                    "en": "l",
                    "cz": "l",
                    "bg": "а"
                },
                "3": {
                    "ua": "і",
                    "en": "l",
                    "cz": "á",
                    "bg": "ж"
                },
                "4": {
                    "ua": "н",
                    "en": "s",
                    "cz": "n",
                    "bg": "д"
                },
                "5": {
                    "ua": "к",
                    "cz": "í",
                    "bg": "а"
                },
                "6": {
                    "ua": "и",
                    "bg": "н"
                },
                "7": {
                    "bg": "и"
                },
                "8": {
                    "bg": "я"
                }
            }
        ],
        "accountId": {
            "ua": "ідентифікатор рахунку",
            "en": "Account Identifier",
            "cz": "identifikátor účtu",
            "bg": "Идентификатор на сметка"
        },
        "taxService": {
            "ua": "вибір PPO",
            "en": "PPO Selection",
            "cz": "výběr PPO",
            "bg": "Избор на PPO"
        },
        "barcode": {
            "ua": "штрихкод",
            "en": "Barcode",
            "cz": "čárový kód",
            "bg": "Штрихкод"
        },
        "posts": {
            "$postName": {
                "ua": "назва посади",
                "en": "Post Name",
                "cz": "název pozice",
                "bg": "Име на позицията"
            }
        },
        "medoc": {
            "url": {
                "ua": "url посилання",
                "en": "url Link",
                "cz": "url odkaz",
                "bg": "url адрес"
            },
            "edrpou": {
                "ua": "Код ЄДРПОУ",
                "en": "EDRPOU Code",
                "cz": "Kód EDRPOU",
                "bg": "Код на ЕДРПОУ"
            },
            "login": {
                "ua": "Логін",
                "en": "Login",
                "cz": "Přihlášení",
                "bg": "Логин"
            },
            "dept": {
                "ua": "номер філії",
                "en": "Branch Number",
                "cz": "číslo pobočky",
                "bg": "Номер на филия"
            }
        },
        "accountName": {
            "ua": "назва рахунку",
            "en": "Account Name",
            "cz": "název účtu",
            "bg": "Име на сметката"
        },
        "roleName": {
            "ua": "назва ролі",
            "en": "Role Name",
            "cz": "název role",
            "bg": "Име на ролята"
        },
        "schedule": {
            "ua": "розклад",
            "en": "Schedule",
            "cz": "rozvrh",
            "bg": "График"
        },
        "nonWorkingDays": {
            "ua": "неробочі дні",
            "en": "Non-working Days",
            "cz": "nerobocí dny",
            "bg": "Неработни дни"
        },
        "reviews": {
            "ua": "відгуки",
            "en": "Reviews",
            "cz": "hodnocení",
            "bg": "Отзиви"
        }
    },
    "manager": {
        "managerName": {
            "ua": "ім'я менеджера",
            "en": "Manager's First Name",
            "cz": "jméno manažera",
            "bg": "Име на мениджъра"
        },
        "managerSurname": {
            "ua": "прізвище менеджера",
            "en": "Manager's Surname",
            "cz": "příjmení manažera",
            "bg": "Фамилия на мениджъра"
        },
        "phone": {
            "ua": "телефон",
            "en": "Phone",
            "cz": "telefon",
            "bg": "Телефон"
        },
        "phoneCode": {
            "code": {
                "ua": "код",
                "en": "Code",
                "cz": "kód",
                "bg": "Код"
            },
            "country": {
                "ua": "країна",
                "en": "Country",
                "cz": "země",
                "bg": "Държава"
            }
        },
        "posts": {
            "$postName": {
                "ua": "назва посади",
                "en": "Post Name",
                "cz": "název pozice",
                "bg": "Име на позицията"
            }
        },
        "medoc": {
            "url": {
                "ua": "url посилання",
                "en": "url Link",
                "cz": "url odkaz",
                "bg": "url адрес"
            },
            "edrpou": {
                "ua": "Код ЄДРПОУ",
                "en": "EDRPOU Code",
                "cz": "Kód EDRPOU",
                "bg": "Код на ЕДРПОУ"
            },
            "login": {
                "ua": "Логін",
                "en": "Login",
                "cz": "Přihlášení",
                "bg": "Логин"
            },
            "dept": {
                "ua": "номер філії",
                "en": "Branch Number",
                "cz": "číslo pobočky",
                "bg": "Номер на филия"
            }
        },
        "postref": {
            "$postNum": {
                "ua": "Пост за замовчуванням",
                "en": "Default Post",
                "cz": "Výchozí příspěvek",
                "bg": "По подразбиране позиция"
            },
            "$dashboardFilter": {
                "ua": "фільтр на планувальнику",
                "en": "Scheduler Filter",
                "cz": "filtr na plánovači",
                "bg": "Филтър в графика"
            },
            "$ordersView": {
                "ua": "перегляд всіх замовлень",
                "en": "View All Orders",
                "cz": "přehled všech objednávek",
                "bg": "Преглед на всички поръчки"
            },
            "$cashierKey": {
                "ua": "ключ ЕЦП",
                "en": "Electronic Signature Key",
                "cz": "klíč EČP",
                "bg": "Ключ за електронен подпис"
            },
            "$orderType": {
                "ua": "тип ремонту",
                "en": "Repair Type",
                "cz": "typ opravy",
                "bg": "Тип на ремонта"
            }
        }
    },
    "order": {
        "detailsDiscount": {
            "ua": "знижка на деталі",
            "en": "Detail Discount",
            "cz": "sleva na díly",
            "bg": "Отстъпка за части"
        },
        "beginDatetime": {
            "ua": "дата та час початку",
            "en": "Start Date and Time",
            "cz": "datum a čas začátku",
            "bg": "Дата и час на започване"
        },
        "businessComment": {
            "ua": "коментар в цех",
            "en": "Workshop Comment",
            "cz": "komentář do dílny",
            "bg": "Коментар в работилницата"
        },
        "clientEmail": {
            "ua": "електронна пошта клієнта",
            "en": "Client Email",
            "cz": "e-mail klienta",
            "bg": "Имейл на клиента"
        },
        "clientPhone": {
            "ua": "номер телефона клієнта",
            "en": "Client Phone Number",
            "cz": "číslo telefonu klienta",
            "bg": "Телефонен номер на клиента"
        },
        "comment": {
            "ua": "коментар клієнта",
            "en": "Client Comment",
            "cz": "komentář klienta",
            "bg": "Коментар на клиента"
        },
        "courtesyCar": {
            "ua": "автомобіль на час ремонту",
            "en": "Courtesy Car",
            "cz": "auto na dobu opravy",
            "bg": "Коли по време на ремонта"
        },
        "clientAggregateId": {
            "ua": "id ідентифікатор агрегату клієнта",
            "en": "Client Aggregate Identifier",
            "cz": "id identifikátor agregátu klienta",
            "bg": "Идентификатор на агрегата на клиента"
        },
        "diskMaterial": {
            "ua": "матеріал диска",
            "en": "Disk Material",
            "cz": "materiál disku",
            "bg": "Материал на диска"
        },
        "diskRadius": {
            "ua": "радіус диска",
            "en": "Disk Radius",
            "cz": "poloměr disku",
            "bg": "Радиус на диска"
        },
        "duration": {
            "ua": "тривалість",
            "en": "Duration",
            "cz": "doba trvání",
            "bg": "Продължителност"
        },
        "odometerValue": {
            "ua": "пробіг",
            "en": "Odometer",
            "cz": "najeté km",
            "bg": "Пробег"
        },
        "paymentMethod": {
            "ua": "спосіб оплати",
            "en": "Payment Method",
            "cz": "způsob platby",
            "bg": "Метод на плащане"
        },
        "recommendation": {
            "ua": "рекомендація",
            "en": "Recommendation",
            "cz": "doporučení",
            "bg": "Препоръка"
        },
        "servicesDiscount": {
            "ua": "знижка на послуги",
            "en": "Service Discount",
            "cz": "sleva na služby",
            "bg": "Отстъпка за услуги"
        },
        "stationNum": {
            "ua": "номер станції",
            "en": "Station Number",
            "cz": "číslo stanice",
            "bg": "Номер на станцията"
        },
        "status": {
            "ua": "статус",
            "en": "Status",
            "cz": "status",
            "bg": "Статус"
        },
        "vehicleCondition": {
            "ua": "стан автомобіля",
            "en": "Vehicle Condition",
            "cz": "stav automobilu",
            "bg": "Състояние на превозното средство"
        },
        "id": {
            "ua": "id ідентифікатор",
            "en": "Identifier",
            "cz": "id identifikátor",
            "bg": "id идентификатор"
        },
        "num": {
            "ua": "номер наряд-замовлення",
            "en": "Order Number",
            "cz": "číslo zakázky",
            "bg": "Номер на наряд-поръчка"
        },
        "datetime": {
            "ua": "дата та час",
            "en": "Date and Time",
            "cz": "datum a čas",
            "bg": "Дата и час"
        },
        "servicesSum": {
            "ua": "сума за послуги",
            "en": "Service Sum",
            "cz": "částka za služby",
            "bg": "Сума за услуги"
        },
        "detailsSum": {
            "ua": "сума за деталі",
            "en": "Details Sum",
            "cz": "částka za díly",
            "bg": "Сума за части"
        },
        "businessId": {
            "ua": "ідентифікатор бізнесу",
            "en": "Business Identifier",
            "cz": "identifikátor firmy",
            "bg": "Идентификатор на бизнеса"
        },
        "viewDatetime": {
            "ua": "дата та час перегляду",
            "en": "View Date and Time",
            "cz": "datum a čas zobrazení",
            "bg": "Дата и час на прегледа"
        },
        "deliveryDatetime": {
            "ua": "дата та час доставки",
            "en": "Delivery Date and Time",
            "cz": "datum a čas dodání",
            "bg": "Дата и час на доставка"
        },
        "successDatetime": {
            "ua": "дата та час успішного завершення",
            "en": "Success Date and Time",
            "cz": "datum a čas úspěšného dokončení",
            "bg": "Дата и час на успешно приключване"
        },
        "invoiceReportPrinted": {
            "ua": "запис про рахунок надруковано",
            "en": "Invoice Record Printed",
            "cz": "záznam o faktuře vytištěn",
            "bg": "Запис за фактурата отпечатан"
        },
        "actOfAcceptanceReportPrinted": {
            "ua": "акт прийому-передачі надруковано",
            "en": "Acceptance Act Printed",
            "cz": "akt o přijetí a předání vytištěn",
            "bg": "Акт за приемане-предаване отпечатан"
        },
        "completedWorkReportPrinted": {
            "ua": "звіт про виконані роботи надруковано",
            "en": "Completed Work Report Printed",
            "cz": "zpráva o vykonaných pracích vytištěna",
            "bg": "Доклад за извършените работи отпечатан"
        },
        "replacementType": {
            "ua": "тип заміни",
            "en": "Replacement Type",
            "cz": "typ výměny",
            "bg": "Тип на замяна"
        },
        "discount": {
            "ua": "знижка",
            "en": "Discount",
            "cz": "sleva",
            "bg": "отстъпка"
        },
        "orderBloke": {
            "ua": "блок замовлень",
            "en": "Order Block",
            "cz": "blok objednávek",
            "bg": "блок на поръчките"
        },
        "systemComment": {
            "ua": "системний коментар",
            "en": "System Comment",
            "cz": "systémový komentář",
            "bg": "системен коментар"
        },
        "agreementComment": {
            "ua": "коментар до угоди",
            "en": "Agreement Comment",
            "cz": "komentář k dohodě",
            "bg": "коментар към споразумението"
        },
        "storageHashes": {
            "ua": "хеші зберігання",
            "en": "Storage Hashes",
            "cz": "hashovací úložiště",
            "bg": "хешове за съхранение"
        },
        "detailsTotalSum": {
            "ua": "загальна сума за деталі",
            "en": "Total Parts Sum",
            "cz": "celková částka za díly",
            "bg": "обща сума за детайли"
        },
        "servicesTotalSum": {
            "ua": "загальна сума за послуги",
            "en": "Total Services Sum",
            "cz": "celková částka za služby",
            "bg": "обща сума за услуги"
        },
        "servicesSumDiscount": {
            "ua": "знижка на суму послуг",
            "en": "Service Sum Discount",
            "cz": "sleva na částku za služby",
            "bg": "отстъпка на сумата за услуги"
        },
        "detailsSumDiscount": {
            "ua": "знижка на суму деталей",
            "en": "Details Sum Discount",
            "cz": "sleva na částku za díly",
            "bg": "отстъпка на сумата за детайли"
        },
        "totalSum": {
            "ua": "загальна сума",
            "en": "Total Sum",
            "cz": "celková částka",
            "bg": "обща сума"
        },
        "totalSumFiscal": {
            "ua": "фіскальна загальна сума",
            "en": "Fiscal Total Sum",
            "cz": "fiskální celková částka",
            "bg": "фискална обща сума"
        },
        "imgId": {
            "ua": "id ідентифікатор зображення",
            "en": "Image Identifier",
            "cz": "id identifikátor obrázku",
            "bg": "идентификатор на изображение"
        },
        "totalSumDiscount": {
            "ua": "загальна сума зі знижкою",
            "en": "Total Sum with Discount",
            "cz": "celková částka se slevou",
            "bg": "обща сума със отстъпка"
        },
        "printNumber": {
            "ua": "номер друку",
            "en": "Print Number",
            "cz": "výpis číslo",
            "bg": "номер на печат"
        },
        "totalSumWithTax": {
            "ua": "загальна сума з податком",
            "en": "Total Sum with Tax",
            "cz": "celková částka s daní",
            "bg": "обща сума с данък"
        },
        "totalSumWithTaxFiscal": {
            "ua": "фіскальна загальна сума з податком",
            "en": "Fiscal Total Sum with Tax",
            "cz": "fiskální celková částka s daní",
            "bg": "фискална обща сума с данък"
        },
        "fiscalTotalSum": {
            "ua": "фіскальна загальна сума",
            "en": "Fiscal Total Sum",
            "cz": "fiskální celková částka",
            "bg": "фискална обща сума"
        },
        "tax": {
            "ua": "податок",
            "en": "Tax",
            "cz": "daň",
            "bg": "данък"
        },
        "isTaxPayer": {
            "ua": "є платником податків",
            "en": "Is Taxpayer",
            "cz": "je plátcem daně",
            "bg": "е данъкоплатец"
        },
        "taxRate": {
            "ua": "ставка податку",
            "en": "Tax Rate",
            "cz": "sazba daně",
            "bg": "данъчна ставка"
        },
        "aggregateId": {
            "ua": "id ідентифікатор агрегату, поле Агрегат / Вузол",
            "en": "Aggregate ID",
            "cz": "id identifikátor agregátu, pole Agregát / Uzlov",
            "bg": "идентификатор на агрегат, поле Агрегат / Възел"
        },
        "aggregateCode": {
            "ua": "код агрегату",
            "en": "Aggregate Code",
            "cz": "kód agregátu",
            "bg": "код на агрегат"
        },
        "aggregateParentCode": {
            "ua": "код батьківського агрегату, поле Агрегат / Вузол",
            "en": "Parent Aggregate Code",
            "cz": "kód rodičovského agregátu, pole Agregát / Uzlov",
            "bg": "код на родителски агрегат, поле Агрегат / Възел"
        },
        "aggregateLevel": {
            "ua": "рівень агрегату",
            "en": "Aggregate Level",
            "cz": "úroveň agregátu",
            "bg": "ниво на агрегат"
        },
        "aggregateName": {
            "ua": "назва агрегату",
            "en": "Aggregate Name",
            "cz": "název agregátu",
            "bg": "име на агрегат"
        },
        "aggregateBrandId": {
            "ua": "id ідентифікатор бренду агрегату",
            "en": "Aggregate Brand ID",
            "cz": "id identifikátor značky agregátu",
            "bg": "идентификатор на марката на агрегат"
        },
        "aggregateBrandName": {
            "ua": "назва бренду агрегату",
            "en": "Aggregate Brand Name",
            "cz": "název značky agregátu",
            "bg": "име на марката на агрегат"
        },
        "aggregateNumber": {
            "ua": "номер агрегату",
            "en": "Aggregate Number",
            "cz": "číslo agregátu",
            "bg": "номер на агрегат"
        },
        "aggregateCount": {
            "ua": "кількість агрегатів",
            "en": "Number of Aggregates",
            "cz": "počet agregátů",
            "bg": "брой на агрегати"
        },
        "aggregateComment": {
            "ua": "коментар до агрегату",
            "en": "Aggregate Comment",
            "cz": "komentář k agregátu",
            "bg": "коментар към агрегат"
        },
        "aggregateVin": {
            "ua": "vin агрегату",
            "en": "Vin of Aggregate",
            "cz": "vin agregátu",
            "bg": "vin на агрегата"
        },
        "displayVehicle": {
            "ua": "відображати автомобіль",
            "en": "Display Vehicle",
            "cz": "zobrazit automobil",
            "bg": "показвай автомобил"
        },
        "stationName": {
            "ua": "назва станції",
            "en": "Station Name",
            "cz": "název stanice",
            "bg": "име на станция"
        },
        "damageTypes": {
            "ua": "типи пошкоджень",
            "en": "Damage Types",
            "cz": "typy poškození",
            "bg": "типове повреди"
        },
        "paid": {
            "ua": "оплачено",
            "en": "Paid",
            "cz": "zaplaceno",
            "bg": "платено"
        },
        "lastPaymentDatetime": {
            "ua": "дата та час останньої оплати",
            "en": "Last Payment Date and Time",
            "cz": "datum a čas poslední platby",
            "bg": "дата и час на последната плащане"
        },
        "remainder": {
            "ua": "залишок",
            "en": "Remainder",
            "cz": "zůstatek",
            "bg": "остатък"
        },
        "barcode": {
            "ua": "штрих-код",
            "en": "Barcode",
            "cz": "čárový kód",
            "bg": "баркод"
        },
        "totalCount": {
            "ua": "загальна кількість",
            "en": "Total Quantity",
            "cz": "celkový počet",
            "bg": "общ брой"
        },
        "detailsTotalSumWithTax": {
            "ua": "загальна сума за деталі з податком",
            "en": "Total Sum of Parts with Tax",
            "cz": "celková částka za díly s daní",
            "bg": "обща сума за детайли с данък"
        },
        "servicesTotalSumWithTax": {
            "ua": "загальна сума за послуги з податком",
            "en": "Total Sum of Services with Tax",
            "cz": "celková částka za služby s daní",
            "bg": "обща сума за услуги с данък"
        },
        "detailsTax": {
            "ua": "податок на деталі",
            "en": "Tax on Parts",
            "cz": "daň na díly",
            "bg": "данък върху детайлите"
        },
        "servicesTax": {
            "ua": "податок на послуги",
            "en": "Tax on Services",
            "cz": "daň na služby",
            "bg": "данък върху услугите"
        },
        "printDate": {
            "ua": "дата друку",
            "en": "Print Date",
            "cz": "datum tisku",
            "bg": "дата на печат"
        },
        "roundedFiscalSum": {
            "ua": "округлена фіскальна сума",
            "en": "Rounded Fiscal Sum",
            "cz": "zaokrouhlená fiskální částka",
            "bg": "окръглена фискална сума"
        },
        "absoluteSum": {
            "ua": "абсолютна сума",
            "en": "total amount",
            "cz": "absolutní částka",
            "bg": "абсолютна сума"
        },
        "nextVisitComment": {
            "ua": "коментар до наступного візиту",
            "en": "comment for the next visit",
            "cz": "komentář k další návštěvě",
            "bg": "коментар към следващото посещение"
        },
        "totalSumWithDiscount": {
            "ua": "загальна сума зі знижкою",
            "en": "total amount with discount",
            "cz": "celková částka se slevou",
            "bg": "обща сума със отстъпка"
        }
    },
    "services": {
        "$laborId": {
            "ua": "id ідентифікатор роботи",
            "en": "id identifier of the work",
            "cz": "id identifikátor práce",
            "bg": "идентификатор на работа"
        },
        "$serviceName": {
            "ua": "назва послуги/роботи",
            "en": "name of the service/work",
            "cz": "název služby/práce",
            "bg": "име на услуга/работа"
        },
        "$measureUnit": {
            "ua": "одиниця виміру",
            "en": "unit of measurement",
            "cz": "jednotka měření",
            "bg": "единица за измерване"
        },
        "$laborKeyGroup": {
            "ua": "група ключових робіт",
            "en": "group of key works",
            "cz": "skupina klíčových prací",
            "bg": "група ключови работи"
        },
        "$discount": {
            "ua": "знижка",
            "en": "discount",
            "cz": "sleva",
            "bg": "отстъпка"
        },
        "$rowDiscountPrice": {
            "ua": "ціна зі знижкою в рядоку",
            "en": "discounted price in the row",
            "cz": "cena se slevou v řádku",
            "bg": "цена със отстъпка в реда"
        },
        "$discountPrice": {
            "ua": "ціна зі знижкою",
            "en": "discounted price",
            "cz": "cena se slevou",
            "bg": "цена със отстъпка"
        },
        "$partRowDiscount": {
            "ua": "знижка на частину рядка",
            "en": "discount on part of the row",
            "cz": "sleva na část řádku",
            "bg": "отстъпка на част от реда"
        },
        "$serviceRowDiscount": {
            "ua": "знижка на рядок послуг",
            "en": "discount on the service row",
            "cz": "sleva na řádek služeb",
            "bg": "отстъпка за реда на услуги"
        },
        "$count": {
            "ua": "кількість",
            "en": "quantity",
            "cz": "počet",
            "bg": "количество"
        },
        "$price": {
            "ua": "ціна",
            "en": "price",
            "cz": "cena",
            "bg": "цена"
        },
        "$discountSum": {
            "ua": "сума знижки",
            "en": "discount amount",
            "cz": "částka sleva",
            "bg": "сума на отстъпка"
        },
        "$sum": {
            "ua": "сума",
            "en": "amount",
            "cz": "částka",
            "bg": "сума"
        }
    },
    "recommendations": {
        "$orderMillage": {
            "ua": "пробіг замовлення",
            "en": "order mileage",
            "cz": "najeté km objednávky",
            "bg": "пробег на поръчката"
        },
        "$orderSuccessDatetime": {
            "ua": "дата і час успішного замовлення",
            "en": "date and time of successful order",
            "cz": "datum a čas úspěšné objednávky",
            "bg": "дата и час на успешна поръчка"
        },
        "$orderNum": {
            "ua": "номер замовлення",
            "en": "order number",
            "cz": "číslo objednávky",
            "bg": "номер на поръчка"
        },
        "$laborId": {
            "ua": "ідентифікатор роботи",
            "en": "id identifier of the work",
            "cz": "id identifikátor práce",
            "bg": "идентификатор на работа"
        },
        "$laborName": {
            "ua": "назва роботи",
            "en": "name of the work",
            "cz": "název práce",
            "bg": "име на работа"
        },
        "$defaultName": {
            "ua": "назва за замовчуванням",
            "en": "default name",
            "cz": "název výchozí",
            "bg": "име по подразбиране"
        },
        "$recommendationComment": {
            "ua": "коментар до рекомендації",
            "en": "comment for the recommendation",
            "cz": "komentář k doporučení",
            "bg": "коментар към препоръката"
        },
        "$recommendationToDate": {
            "ua": "рекомендація до дати",
            "en": "recommendation to the date",
            "cz": "doporučení k datu",
            "bg": "препоръка до дата"
        },
        "$recommendationToOdometerValue": {
            "ua": "рекомендація до значення одометра",
            "en": "recommendation to the odometer value",
            "cz": "doporučení k hodnotě odometru",
            "bg": "препоръка до стойност на одометър"
        },
        "$recommendationStatus": {
            "ua": "статус рекомендації",
            "en": "status of the recommendation",
            "cz": "status doporučení",
            "bg": "статус на препоръката"
        },
        "$count": {
            "ua": "кількість",
            "en": "quantity",
            "cz": "počet",
            "bg": "количество"
        },
        "$servicePrice": {
            "ua": "ціна послуги",
            "en": "service price",
            "cz": "cena služby",
            "bg": "цена на услугата"
        }
    },
    "documentNumber": {
        "ua": "номер документа",
        "en": "document number",
        "cz": "číslo dokumentu",
        "bg": "номер на документ"
    },
    "documentData": {
        "ua": "дані документа",
        "en": "document data",
        "cz": "údaje dokumentu",
        "bg": "данни на документа"
    },
    "invoiceDate": {
        "ua": "дата рахунка",
        "en": "invoice date",
        "cz": "datum faktury",
        "bg": "дата на фактура"
    },
    "invoiceNumber": {
        "ua": "номер рахунка",
        "en": "invoice number",
        "cz": "číslo faktury",
        "bg": "номер на фактура"
    },
    "laborsRequest": {
        "laborRequestName": {
            "$comment": {
                "ua": "коментар",
                "en": "comment",
                "cz": "komentář",
                "bg": "коментар"
            },
            "$problems": {
                "ua": "проблеми",
                "en": "issues",
                "cz": "problémy",
                "bg": "проблеми"
            },
            "$positions": {
                "ua": "місце",
                "en": "place",
                "cz": "místo",
                "bg": "място"
            }
        },
        "$storeGroupId": {
            "ua": "id ідентифікатор групи складів",
            "en": "id identifier of the store group",
            "cz": "id identifikátor skupiny skladů",
            "bg": "идентификатор на група складове"
        },
        "$counterparty": {
            "ua": "контрагент",
            "en": "counterparty",
            "cz": "jiný dodavatel",
            "bg": "контрагент"
        },
        "$businessSupplierId": {
            "ua": "id ідентифікатор постачальника бізнесу",
            "en": "id identifier of the business supplier",
            "cz": "id identifikátor dodavatele firmy",
            "bg": "идентификатор на бизнес доставчик"
        },
        "$businessSupplierName": {
            "ua": "назва постачальника бізнесу",
            "en": "name of the business supplier",
            "cz": "název dodavatele firmy",
            "bg": "име на бизнес доставчик"
        },
        "employeeComment": {
            "$comment": {
                "ua": "коментар",
                "en": "comment",
                "cz": "komentář",
                "bg": "коментар"
            },
            "$problems": {
                "ua": "проблеми",
                "en": "issues",
                "cz": "problémy",
                "bg": "проблеми"
            },
            "$positions": {
                "ua": "місце",
                "en": "place",
                "cz": "místo",
                "bg": "място"
            }
        },
        "$actionType": {
            "ua": "тип дії",
            "en": "action type",
            "cz": "typ akce",
            "bg": "тип действие"
        },
        "$serviceId": {
            "ua": "id ідентифікатор послуги",
            "en": "id identifier of the service",
            "cz": "id identifikátor služby",
            "bg": "идентификатор на услуга"
        },
        "$stage": {
            "ua": "етап",
            "en": "stage",
            "cz": "etapa",
            "bg": "етап"
        },
        "$order": {
            "ua": "замовлення",
            "en": "order",
            "cz": "objednávka",
            "bg": "поръчка"
        },
        "$groupName": {
            "ua": "назва групи товару",
            "en": "name of the product group",
            "cz": "název skupiny zboží",
            "bg": "име на група стоки"
        },
        "$complexId": {
            "ua": "id ідентифікатор комплексу",
            "en": "id identifier of the complex",
            "cz": "id identifikátor komplexu",
            "bg": "идентификатор на комплекс"
        },
        "$complexName": {
            "ua": "назва комплексу",
            "en": "name of the complex",
            "cz": "název komplexu",
            "bg": "име на комплекс"
        },
        "$customComplexId": {
            "ua": "ідентифікатор власного комплексу",
            "en": "identifier of the custom complex",
            "cz": "identifikátor vlastního komplexu",
            "bg": "идентификатор на собствен комплекс"
        },
        "$customComplexName": {
            "ua": "назва власного комплексу",
            "en": "name of the custom complex",
            "cz": "název vlastního komplexu",
            "bg": "име на собствен комплекс"
        },
        "$count": {
            "ua": "кількість",
            "en": "quantity",
            "cz": "počet",
            "bg": "количество"
        },
        "$servicePrice": {
            "ua": "ціна послуги",
            "en": "service price",
            "cz": "cena služby",
            "bg": "цена на услуга"
        },
        "$defaultName": {
            "ua": "значення за замовчуванням",
            "en": "default value",
            "cz": "výchozí hodnota",
            "bg": "стойност по подразбиране"
        },
        "$serviceName": {
            "ua": "назва послуги",
            "en": "name of the service",
            "cz": "název služby",
            "bg": "име на услуга"
        },
        "$employeeName": {
            "ua": "ім'я співробітника",
            "en": "employee name",
            "cz": "jméno zaměstnance",
            "bg": "име на служител"
        }
    },
    "diagnosticsV2": {
        "$placeId": {
            "ua": "id місця",
            "en": "id of the place",
            "cz": "id místa",
            "bg": "идентификатор на място"
        },
        "$placeName": {
            "ua": "імʼя місця",
            "en": "name of the place",
            "cz": "název místa",
            "bg": "име на място"
        },
        "places": {
            "$index": {
                "ua": "індекс місця",
                "en": "index of the place",
                "cz": "index místa",
                "bg": "индекс на място"
            },
            "$leftAndRight": {
                "ua": "ліворуч і праворуч",
                "en": "left and right",
                "cz": "vlevo a vpravo",
                "bg": "ляво и дясно"
            },
            "$showFront": {
                "ua": "передня сторона",
                "en": "front side",
                "cz": "přední strana",
                "bg": "предна страна"
            },
            "$showReverse": {
                "ua": "задня сторона",
                "en": "back side",
                "cz": "zadní strana",
                "bg": "задна страна"
            },
            "$status": {
                "ua": "статус",
                "en": "status",
                "cz": "status",
                "bg": "статус"
            },
            "$bold": {
                "ua": "жирний",
                "en": "bold",
                "cz": "tučný",
                "bg": "дебел шрифт"
            },
            "$problemFromComment": {
                "ua": "проблема з коментарем",
                "en": "problem with comment",
                "cz": "problém s komentářem",
                "bg": "проблем с коментара"
            },
            "$comment": {
                "ua": "коментар",
                "en": "comment",
                "cz": "komentář",
                "bg": "коментар"
            },
            "$templateId": {
                "ua": "id ідентифікатор шаблону",
                "en": "id identifier of the template",
                "cz": "id identifikátor šablony",
                "bg": "идентификатор на шаблон"
            },
            "$templateName": {
                "ua": "назва шаблону",
                "en": "name of the template",
                "cz": "název šablony",
                "bg": "име на шаблон"
            },
            "$groupId": {
                "ua": "ідентифікатор етапу",
                "en": "id identifier of the stage",
                "cz": "identifikátor etapy",
                "bg": "идентификатор на етап"
            },
            "$groupName": {
                "ua": "етап",
                "en": "stage",
                "cz": "etapa",
                "bg": "етап"
            },
            "$systemId": {
                "ua": "ідентифікатор системи",
                "en": "id identifier of the system",
                "cz": "identifikátor systému",
                "bg": "идентификатор на система"
            },
            "$systemName": {
                "ua": "назва системи",
                "en": "name of the system",
                "cz": "název systému",
                "bg": "име на система"
            },
            "$partId": {
                "ua": "ідентифікатор частини",
                "en": "id identifier of the part",
                "cz": "identifikátor součásti",
                "bg": "идентификатор на част"
            },
            "$partName": {
                "ua": "назва частини",
                "en": "name of the part",
                "cz": "název součásti",
                "bg": "име на част"
            },
            "$coordinateSchemeId": {
                "ua": "ідентифікатор координатної схеми",
                "en": "id identifier of the coordinate scheme",
                "cz": "identifikátor schématu koordinace",
                "bg": "идентификатор на координатна схема"
            },
            "$placeName": {
                "ua": "назва місця",
                "en": "name of the place",
                "cz": "název místa",
                "bg": "име на място"
            },
            "$partAction": {
                "ua": "дія з запчастиною",
                "en": "action with the spare part",
                "cz": "akce s náhradním dílem",
                "bg": "действие с резервна част"
            },
            "$partAnswer": {
                "ua": "статус запчастини",
                "en": "status of the spare part",
                "cz": "stav náhradního dílu",
                "bg": "статус на резервната част"
            },
            "$partComment": {
                "ua": "коментар до запчастини",
                "en": "comment on the spare part",
                "cz": "komentář k náhradnímu dílu",
                "bg": "коментар за резервната част"
            },
            "$partProblemsMask": {
                "ua": "маска проблем частини",
                "en": "mask of part problems",
                "cz": "maska problémů součásti",
                "bg": "маска на проблеми с частта"
            },
            "$partRCL": {
                "ua": "справа чи зліва запчастина",
                "en": "spare part is on the right or left",
                "cz": "je náhradní díl vpravo nebo vlevo",
                "bg": "резервна част е отдясно или отляво"
            },
            "$partFCR": {
                "ua": "попереду чи позаду запчастина",
                "en": "spare part is ahead or behind",
                "cz": "je náhradní díl vpředu nebo vzadu",
                "bg": "резервна част е отгоре или отдолу"
            }
        }
    },
    "schemeDiagnostics": {
        "$schemeName": {
            "ua": "назва схеми",
            "en": "name of the scheme",
            "cz": "název schématu",
            "bg": "име на схема"
        },
        "$unitId": {
            "ua": "ідентифікатор рядка діагностики",
            "en": "id identifier of the diagnostic line",
            "cz": "identifikátor diagnostické linky",
            "bg": "идентификатор на ред за диагностика"
        },
        "$unitName": {
            "ua": "назва рядка діагностики",
            "en": "name of the diagnostic line",
            "cz": "název diagnostické linky",
            "bg": "име на реда за диагностика"
        },
        "$unitTooltip": {
            "ua": "підказка рядка діагностики",
            "en": "tooltip of the diagnostic line",
            "cz": "nápověda diagnostické linky",
            "bg": "подсказка на реда за диагностика"
        },
        "$unitComment": {
            "ua": "коментар рядка діагностики",
            "en": "comment of the diagnostic line",
            "cz": "komentář diagnostické linky",
            "bg": "коментар на реда за диагностика"
        },
        "$commentArray": {
            "ua": "масив коментарів",
            "en": "array of comments",
            "cz": "pole komentářů",
            "bg": "масив от коментари"
        },
        "$status": {
            "ua": "статус",
            "en": "status",
            "cz": "stav",
            "bg": "статус"
        },
        "$comment": {
            "ua": "коментар",
            "en": "comment",
            "cz": "komentář",
            "bg": "коментар"
        },
        "$photos": {
            "ua": "фото",
            "en": "photo",
            "cz": "foto",
            "bg": "снимка"
        },
        "$statusColor": {
            "ua": "колір статусу",
            "en": "status color",
            "cz": "barva stavu",
            "bg": "цвят на статуса"
        },
        "$index": {
            "ua": "індекс",
            "en": "index",
            "cz": "index",
            "bg": "индекс"
        }
    }
};