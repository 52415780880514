import { GlobalOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Button, Drawer, Image, Tabs } from 'antd';
import { Layout } from 'commons';
import { get, isArray } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI, getSessionId } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import {
    AccountingTabSettings,
    ContactsSettings,
    CountryTabSettings,
    GeneralSettings,
    OtherTabSettings,
    PostsSettings,
    PricesTabSettings,
    ScheduleSettings
} from './components';
import { AdminCarbookTab } from './components/adminCarbookTab';
import { NotificationTab } from './components/notificationTab';
import './styles.less';
import Styles from './styles.m.css';

const { TabPane } = Tabs;
const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

const formatCB24data = ({
    address,
    business_partners_networks: businessPartnersNetworks,
    business_vehicle_makes: businessVehicleMakes,
    count_stations: countStations,
    description,
    email,
    facebook_link: facebookLink,
    facebook_widget: facebookWidget,
    features,
    ga_id: googleAnalytics,
    google_link: googleLink,
    google_widget: googleWidget,
    is_car_wash: isCarWash,
    is_garage: isGarage,
    is_tire_station: isTireStation,
    language_id: language,
    latitude: mapLatitude,
    longitude: mapLongitude,
    map_zoom: mapZoom,
    meta_description: metaDescription,
    minimal_time_order: minimalTimeOrder,
    name,
    nonWorkingDays,
    owner_email: ownerEmail,
    owner_name: ownerName,
    owner_phone: ownerPhone,
    phones,
    responsible_email: responsibleEmail,
    responsible_name: responsibleName,
    responsible_phone: responsiblePhone,
    schedule,
    site,
    synonym,
    theme,
    time_to_end_working_day: timeToEndWorkingDay,
    title,
    twitter_link: twitterLink,
    vk_link: vkLink,
    vk_widget: vkWidget,
    youtube
}) => ({
    address,
    businessPartnersNetworks,
    businessVehicleMakes,
    countStations,
    description,
    email,
    facebookLink,
    facebookWidget,
    features,
    googleAnalytics,
    googleLink,
    googleWidget,
    isCarWash,
    isGarage,
    isTireStation,
    language,
    mapLatitude,
    mapLongitude,
    mapZoom,
    metaDescription,
    minimalTimeOrder,
    name,
    nonWorkingDays,
    ownerEmail,
    ownerName,
    ownerPhone,
    phones,
    responsibleEmail,
    responsibleName,
    responsiblePhone,
    schedule,
    sessionId: getSessionId(),
    site,
    synonym,
    theme,
    timeToEndWorkingDay,
    title,
    twitterLink,
    vkLink,
    vkWidget,
    youtube
});

const SettingsPage = injectIntl(({ intl: { formatMessage }, user }) => {
    const sessionId = getSessionId();
    const [cb24data, setCB24data] = useState();
    const [activeKey, setActiveKey] = useState();

    const [helperDrawerOpen, sethelperDrawerOpen] = useState(false);
    const [allLinks, setAllLinks] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [carbookValues, setCarbookValues] = useState({});
    const [businessNetworks, setBusinessNetworks] = useState({});

    const generalSettingsRef = useRef();

    const fetchCB24data = async () => {
        setCB24data(null);
        const cb24data = await fetchAPI(
            'GET',
            'rest/v1/business',
            {
                sessionId
            },
            null,
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        setCB24data(cb24data);
    };

    const fetchHelperLinks = async () => {
        const links = await fetchAPI(
            'GET',
            '/helps',
            { helpId: 'directories_and_settings_station_settings' },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        setAllLinks(links);
    };

    const getProfileCarbook = async () => {
        const res = await fetchAPI('GET', '/businesses/network', null, null, {
            handleErrorInternally: true
        });

        setBusinessNetworks(res);
    };

    const getSettedNetworks = async () => {
        const res = await fetchAPI('GET', '/business', null, null, {
            handleErrorInternally: true
        });

        setCarbookValues(res);
    };

    const saveBussinessInfo = async payload => {
        await fetchAPI(
            'PUT',
            'businesses',
            null,
            {
                ...payload
            },
            { handleErrorInternally: true }
        );
    };

    const saveCB24data = async values => {
        const payload = {
            ...formatCB24data(cb24data),
            ...values
        };

        const features = {};

        payload.features.forEach(({ type, lock, name, description }) => {
            features[type] = {
                value: Boolean(lock),
                label: name,
                description
            };
        });

        payload.features = features;

        try {
            await fetchAPI('PUT', 'rest/v1/business', null, payload, {
                handleErrorInternally: true,
                url: __OLD_APP_API_URL__
            });
            fetchCB24data();
        } catch (e) {
            fetchCB24data();
        }
    };

    const getAccountsList = async query => {
        const response = await fetchAPI('GET', '/general_ledger/accounts', { query }, null, {
            handleErrorInternally: true
        });
        setAccounts(response);
    };

    useEffect(() => {
        getProfileCarbook();
        getSettedNetworks();
    }, []);

    return (
        <Layout
            className='settings_page'
            controls={
                <React.Fragment>
                    <a href={book.oldApp.settings}>
                        <Button icon={<GlobalOutlined />} size='large' />
                    </a>
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            sethelperDrawerOpen(true);
                            await fetchHelperLinks();
                        }}
                        style={{
                            fontSize: 22,
                            marginLeft: 8,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        type='text'
                    />
                </React.Fragment>
            }
            description={<FormattedMessage id='navigation.main_settings' />}
            title={<FormattedMessage id='navigation.settings' />}
        >
            <Tabs activeKey={activeKey} onChange={setActiveKey} tabPosition='top'>
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_GENERAL_INFORMATION) && (
                    <TabPane
                        key='general'
                        className='settings_form_tab'
                        tab={<FormattedMessage id='settings_page.general_information' />}
                    >
                        <GeneralSettings
                            ref={generalSettingsRef}
                            carbookValues={carbookValues}
                            fetchData={getSettedNetworks}
                            formatMessage={formatMessage}
                            getProfileCarbook={getProfileCarbook}
                            isDisabledCRUD={
                                !isGrantAccessed(
                                    user,
                                    grants.SETTINGS_BUSINESS_SETTINGS_GENERAL_INFORMATION,
                                    accesses.ROWO
                                )
                            }
                            saveBussinessInfo={saveBussinessInfo}
                            saveData={saveCB24data}
                            sessionId={sessionId}
                            user={user}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_ADDRESS_AND_MAP) && (
                    <TabPane key='contacts' tab={<FormattedMessage id='settings_page.contacts' />}>
                        <Wrapper apiKey='AIzaSyB44HA5xmBlrPN6PwKGxEZoU95aZAy-UXc&libraries=drawing,places&lang=ua'>
                            <ContactsSettings
                                cb24data={cb24data}
                                formatMessage={formatMessage}
                                initialValues={carbookValues}
                                isDisabledCRUD={
                                    !isGrantAccessed(
                                        user,
                                        grants.SETTINGS_BUSINESS_SETTINGS_ADDRESS_AND_MAP,
                                        accesses.ROWO
                                    )
                                }
                                saveData={saveBussinessInfo}
                            />
                        </Wrapper>
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_WORKING_HOURS) && (
                    <TabPane key='schedule' tab={<FormattedMessage id='settings_page.work_time' />}>
                        <ScheduleSettings
                            cb24data={cb24data}
                            formatMessage={formatMessage}
                            initialValues={carbookValues}
                            isDisabledCRUD={
                                !isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_WORKING_HOURS, accesses.ROWO)
                            }
                            saveData={saveBussinessInfo}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_POSTS) && (
                    <TabPane key='posts' tab={<FormattedMessage id='settings_page.posts' />}>
                        <PostsSettings
                            cb24data={cb24data}
                            fetchData={fetchCB24data}
                            formatMessage={formatMessage}
                            initialValues={carbookValues}
                            isDisabledCRUD={
                                !isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_POSTS, accesses.ROWO)
                            }
                            sessionId={sessionId}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_COUNTRY) && (
                    <TabPane key='country' tab={<FormattedMessage id='settings_page.country' />}>
                        <CountryTabSettings
                            cb24data={cb24data}
                            fetchData={fetchCB24data}
                            formatMessage={formatMessage}
                            isDisabledCRUD={
                                !isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_COUNTRY, accesses.ROWO)
                            }
                            sessionId={sessionId}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_PRICES) && (
                    <TabPane key='price' tab={<FormattedMessage id='prices' />}>
                        <PricesTabSettings
                            cb24data={cb24data}
                            fetchData={fetchCB24data}
                            formatMessage={formatMessage}
                            isDisabledCRUD={
                                !isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_PRICES, accesses.ROWO)
                            }
                            sessionId={sessionId}
                        />
                    </TabPane>
                )}
                {/* {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_PRINTED_FORMS) && (
                    <TabPane key='print' tab={<FormattedMessage id='settings_page.print_documents' />}>
                        <PrintTabSettings
                            fetchData={fetchCB24data}
                            formatMessage={formatMessage}
                            isDisabledCRUD={
                                !isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_PRINTED_FORMS, accesses.ROWO)
                            }
                        />
                    </TabPane>
                )} */}
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_ACCOUNTING) && (
                    <TabPane key='tables' tab={<FormattedMessage id='settings_page.tables' />}>
                        <AccountingTabSettings
                            accounts={accounts}
                            fetchData={getSettedNetworks}
                            formatMessage={formatMessage}
                            generalLedgerEntries={get(carbookValues, 'generalLedgerEntries', false)}
                            getAccountsList={getAccountsList}
                            isDisabledCRUD={
                                !isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_ACCOUNTING, accesses.ROWO)
                            }
                            sessionId={sessionId}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_OTHER) && (
                    <TabPane key='other' tab={<FormattedMessage id='settings_page.other' />}>
                        <OtherTabSettings
                            cb24data={cb24data}
                            fetchData={fetchCB24data}
                            formatMessage={formatMessage}
                            isDisabledCRUD={
                                !isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_OTHER, accesses.ROWO)
                            }
                            sessionId={sessionId}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_NETWORK) && (
                    <TabPane key='CARBOOK' tab={<FormattedMessage id='Admin CARBOOK' />}>
                        <AdminCarbookTab
                            businessNetworks={businessNetworks}
                            carbookValues={carbookValues}
                            cb24data={cb24data}
                            fetchData={fetchCB24data}
                            formatMessage={formatMessage}
                            isDisabledCRUD={
                                !isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_NETWORK, accesses.ROWO)
                            }
                            sessionId={sessionId}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_NETWORK) && (
                    <TabPane key='notific' tab={<FormattedMessage id='settings_page.notific' />}>
                        <NotificationTab
                            businessNetworks={businessNetworks}
                            carbookValues={carbookValues}
                            cb24data={cb24data}
                            fetchData={fetchCB24data}
                            formatMessage={formatMessage}
                            isDisabledCRUD={
                                !isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_OTHER, accesses.ROWO)
                            }
                            sessionId={sessionId}
                        />
                    </TabPane>
                )}
            </Tabs>

            <Drawer
                onClose={() => {
                    sethelperDrawerOpen(false);
                }}
                open={helperDrawerOpen}
                title={<FormattedMessage id='navigation.helper' />}
                width={420}
            >
                <div>
                    {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                        <div className={Styles.linkBlock}>
                            <div className={Styles.ogTitle}>
                                {index + 1}. {ogTitle}
                            </div>
                            <div className={Styles.ogDesc}>{ogDescription}</div>
                            <div className={Styles.ogImg}>
                                <Image src={isArray(ogImage) ? get(ogImage, '[0].url', []) : get(ogImage, 'url', [])} />
                            </div>
                            <a href={ogUrl} rel='noreferrer' target='_blank'>
                                <Button
                                    style={{
                                        width: '100%'
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='repair_map_table.goto' />
                                </Button>
                            </a>
                        </div>
                    ))}
                </div>
            </Drawer>
        </Layout>
    );
});

const mapStateToProps = state => ({
    user: state.auth
});

export default connect(mapStateToProps)(SettingsPage);
