/* eslint-disable no-underscore-dangle */
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Image, InputNumber, Modal, Select, Table, TimePicker, notification } from 'antd';
import { Catcher } from 'commons';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import _, { get, isArray, isNil, keys, values } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';
import { columnsConfig } from './tableConfig';

const { Option } = Select;

const dateFormat = 'DD/MM/YYYY';

@injectIntl
export default class StationsTable extends Component {
    constructor(props) {
        super(props);
        const stationLoads = props.stationLoads || [];

        this.uuid = stationLoads.length;
        this.state = {
            open: false,
            keys: [...keys(stationLoads), this.uuid++],
            helperDrawerOpen: false,
            allLinks: [],
            groupAdd: false,
            loadingModal: false,
            duration: 0.5
        };
    }

    componentDidUpdate() {
        const loads = get(this.props.fields, 'stationLoads', []);
        const propsLoadsLength = loads.length;
        const keysLength = this.state.keys.length;

        if (propsLoadsLength === keysLength && !loads.some(load => values(load).some(isNil))) {
            this._handleAdd();
        }
    }

    // fetchBussinessSchedule = async () => {
    //     const res = await fetchAPI('GET', '/business', undefined, undefined, {
    //         handleErrorInternally: true
    //     });
    //     this.setState({
    //         bussinessSchedule: res && res.schedule
    //     });
    // };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'order_mrd_station' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    _bodyUpdateIsForbidden = () => isForbidden(this.props.user, permissions.ACCESS_ORDER_BODY);

    _onDelete = redundantKey => {
        const { keys } = this.state;

        this.setState({ keys: keys.filter(key => redundantKey !== key) });
        this.props.form.setFieldsValue({
            [`stationLoads[${redundantKey}]`]: void 0
        });
    };

    _handleAdd = () => {
        const { keys } = this.state;
        this.setState({ keys: [...keys, this.uuid++] });
    };

    _handleOpenModal = () => {
        this.setState({ open: true });
    };

    _handleOpenModalGroupAdd = () => {
        this.setState({ open: true, groupAdd: true });
    };

    handleLongRepair = async (startDate, endDate) => {
        const { schedule } = this.props;
        const { duration, stationNum, startTime, keys } = this.state;
        let start = dayjs(startDate);
        const end = dayjs(endDate);

        this.setState({
            loadingModal: true
        });

        const filteredSchedule = schedule
            .filter(elem => !elem.beginTime)
            .map(elem => elem.days)
            .flat();

        const dateArray = [];

        let remainingDuration = duration;

        while (remainingDuration > 0) {
            const [hour, minute] = get(schedule, '[0].beginTime', '09:00').split(':');

            const dayIndex = start.day() || 7;

            if (filteredSchedule.includes(dayIndex)) {
                start = start.add(1, 'd');
            } else {
                const endOfDay = get(schedule, '[0].endTime').split(':');
                const startOfDay = !dateArray.length
                    ? dayjs(startTime).format('HH:mm').split(':')
                    : dayjs().set('h', hour).set('m', minute).format('HH:mm').split(':');

                const endOfTime = Number(endOfDay[0]) * 60 + Number(endOfDay[1]);
                const startOfTime = Number(startOfDay[0]) * 60 + Number(startOfDay[1]);

                if (endOfTime < startOfTime) {
                    notification.error({
                        message: this.props.intl.formatMessage({
                            id: 'dashboard-page.title.stations_long_repair_error'
                        })
                    });

                    return;
                }

                // Calculate available hours in the current day from start time
                const hoursRemainingToday = (Number(endOfTime) - Number(startOfTime)) / 60;

                // Assign hours for the current day

                const estimatedDuration = Math.min(remainingDuration, hoursRemainingToday);

                remainingDuration -= estimatedDuration;

                dateArray.push({
                    beginDate: start,
                    beginTime: !dateArray.length ? startTime : dayjs().set('h', hour).set('m', minute),
                    duration: estimatedDuration,
                    stationNum,
                    status: 'TO_DO'
                });

                // Move to the next day
                start = start.add(1, 'd');
            }
        }

        const stationLoadsEntity = dateArray
            .filter(item => item)
            .filter(({ beginDate, beginTime }) => ![beginDate, beginTime].some(_.isNil))
            .map(obj => {
                const utcOffset = dayjs().utcOffset() / 60;
                const hour = obj.beginTime && dayjs(obj.beginTime).hour();
                const hourUtc = obj.beginTime && dayjs(obj.beginTime).utc().hour();

                const dayPart = obj.beginDate && dayjs(obj.beginDate);
                const hourPart = obj.beginTime && dayjs(obj.beginTime).utc();

                if (utcOffset > 0 && hourUtc > hour) {
                    dayPart.subtract(1, 'day');
                } else if (utcOffset < 0 && hour > hourUtc) {
                    dayPart.add(1, 'day');
                }

                const beginDatetime =
                    dayPart &&
                    hourPart &&
                    dayjs(`${dayPart.format('YYYY-MM-DD')}T${hourPart.format('HH:mm')}:00.000Z`).toISOString();

                return {
                    ...obj,
                    station: obj.stationNum,
                    beginDatetime
                };
            });

        const arr = new Array(this.props.stationLoads.length + stationLoadsEntity.length).fill(1);

        this.uuid = arr.length;

        this.setState({
            keys: [...arr.map((e, index) => index), this.uuid++]
        });

        const mapperStationLoads = this.props.stationLoads.map(elem => {
            return {
                ...elem,
                station: elem.stationNum,
                beginTime: dayjs(elem.beginDatetime),
                beginDate: dayjs(elem.beginDatetime)
            };
        });

        this.props.form.setFieldsValue({
            stationLoads: [...mapperStationLoads, ...stationLoadsEntity]
        });

        await this.hideModal();

        notification.success({
            message: this.props.intl.formatMessage({ id: 'barcode.success' })
        });

        return stationLoadsEntity;
    };

    handleGroupAddition = async (startDate, endDate) => {
        const { duration, stationNum, startTime, keys } = this.state;
        const { schedule } = this.props;
        let start = dayjs(startDate);
        const end = dayjs(endDate);

        this.setState({
            loadingModal: true
        });

        try {
            const filteredSchedule = schedule
                .filter(elem => !elem.beginTime)
                .map(elem => elem.days)
                .flat();

            const dateArray = [];

            while (start <= end) {
                const dayIndex = start.day() || 7;

                console.log(dayIndex, 'dayIndex');

                if (filteredSchedule.includes(dayIndex)) {
                    start = start.add(1, 'd');
                } else {
                    dateArray.push({
                        beginDate: start,
                        beginTime: startTime,
                        duration,
                        stationNum,
                        status: 'TO_DO'
                    });

                    // Move to the next day
                    start = start.add(1, 'd');
                }
            }

            const stationLoadsEntity = dateArray
                .filter(item => item)
                .filter(({ beginDate, beginTime }) => ![beginDate, beginTime].some(_.isNil))
                .map(obj => {
                    const utcOffset = dayjs().utcOffset() / 60;
                    const hour = obj.beginTime && dayjs(obj.beginTime).hour();
                    const hourUtc = obj.beginTime && dayjs(obj.beginTime).utc().hour();

                    const dayPart = obj.beginDate && dayjs(obj.beginDate);
                    const hourPart = obj.beginTime && dayjs(obj.beginTime).utc();

                    if (utcOffset > 0 && hourUtc > hour) {
                        dayPart.subtract(1, 'day');
                    } else if (utcOffset < 0 && hour > hourUtc) {
                        dayPart.add(1, 'day');
                    }

                    const beginDatetime =
                        dayPart &&
                        hourPart &&
                        dayjs(`${dayPart.format('YYYY-MM-DD')}T${hourPart.format('HH:mm')}:00.000Z`).toISOString();

                    return {
                        ...obj,
                        station: obj.stationNum || undefined,
                        beginDatetime
                    };
                });

            const arr = new Array(this.props.stationLoads.length + stationLoadsEntity.length).fill(1);

            this.uuid = arr.length;

            this.setState({
                keys: [...arr.map((e, index) => index), this.uuid++]
            });

            const mapperStationLoads = this.props.stationLoads.map(elem => {
                return {
                    ...elem,
                    station: Number(elem.stationNum) || undefined,
                    beginTime: dayjs(elem.beginDatetime),
                    beginDate: dayjs(elem.beginDatetime)
                };
            });

            this.props.form.setFieldsValue({
                stationLoads: [...mapperStationLoads, ...stationLoadsEntity]
            });

            await this.hideModal();

            notification.success({
                message: this.props.intl.formatMessage({ id: 'barcode.success' })
            });

            return stationLoadsEntity;
        } catch (err) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'error' })
            });
        }
    };

    handleOk = async () => {
        const { startDate, endDate, groupAdd } = this.state;
        if (groupAdd) {
            await this.handleGroupAddition(startDate, endDate);
        } else {
            await this.handleLongRepair(startDate);
        }

        await this.hideModal();
    };

    hideModal = () => {
        this.setState({
            open: false,
            groupAdd: false,
            startTime: undefined,
            startDate: undefined,
            endDate: undefined,
            duration: 0.5,
            stationNum: undefined,
            beginTime: undefined,
            loadingModal: false
        });
    };

    render() {
        const {
            fetchedOrder,
            intl: { formatMessage },
            user
        } = this.props;
        const {
            keys,
            helperDrawerOpen,
            allLinks,
            open,
            startDate,
            startTime,
            endDate,
            duration,
            stationNum,
            loadingModal,
            groupAdd
        } = this.state;

        const columns = columnsConfig(
            this.props,
            this.state,
            formatMessage,
            this._onDelete,
            this._bodyUpdateIsForbidden,
            this._handleOpenModal,
            this._handleOpenModalGroupAdd,
            fetchedOrder,
            user
        );

        const disabled = groupAdd
            ? !startDate || !startTime || !endDate || !duration || !stationNum
            : !startDate || !startTime || !duration || !stationNum;

        return (
            <Catcher>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginBottom: 8
                    }}
                >
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            const links = await fetchAPI('GET', '/helps', { helpId: 'order_mrd_station' }, undefined, {
                                handleErrorInternally: true
                            });
                            this.setState({
                                allLinks: links,
                                helperDrawerOpen: true
                            });
                        }}
                        style={{
                            fontSize: 22,

                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        type='text'
                    />
                </div>
                <Table
                    bordered
                    className={Styles.stationLoadsTable}
                    columns={columns}
                    dataSource={keys.map(key => ({ key }))}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                    rowClassName={({ key }) => {
                        if (Number(key) === 0) {
                            return Styles.staticStationLoadsRow;
                        }
                    }}
                    size='small'
                />
                <Modal
                    maskClosable={false}
                    okButtonProps={{
                        loading: loadingModal,
                        disabled
                    }}
                    onCancel={() => {
                        this.hideModal();
                    }}
                    onOk={() => this.handleOk()}
                    open={open}
                    title={
                        groupAdd ? (
                            <FormattedMessage id='dashboard-page.title.stations_group_add_repair' />
                        ) : (
                            <FormattedMessage id='dashboard-page.title.stations_long_repair' />
                        )
                    }
                    width={groupAdd ? '60%' : '40%'}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        {groupAdd && (
                            <div>
                                <FormattedMessage id='business-package-container.activation_datetime' /> -{' '}
                                <FormattedMessage id='business-package-container.expiration_datetime' />
                                <div>
                                    <DateRangePicker
                                        allowClear
                                        dateRange={[this.state.startDate, this.state.endDate]}
                                        format={dateFormat}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onDateChange={async dateRange => {
                                            await this.setState({
                                                startDate: dateRange[0],
                                                endDate: dateRange[1]
                                            });
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        )}
                        {!groupAdd && (
                            <div>
                                <FormattedMessage id='business-package-container.activation_datetime' />
                                <div>
                                    <DatePicker
                                        allowClear={false}
                                        format='YYYY-MM-DD'
                                        onChange={value =>
                                            this.setState({
                                                startDate: value
                                            })
                                        }
                                        placeholder={formatMessage({
                                            id: 'add_order_form.select_date'
                                        })}
                                        showTime={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        value={startDate}
                                    />
                                </div>
                            </div>
                        )}
                        <div>
                            <FormattedMessage id='BEGIN_HOURS' />
                            <div>
                                <TimePicker
                                    allowClear={false}
                                    format='HH:mm'
                                    hideDisabledOptions
                                    minuteStep={30}
                                    onChange={value => {
                                        this.setState({
                                            startTime: value
                                        });
                                    }}
                                    placeholder={formatMessage({
                                        id: 'add_order_form.provide_time'
                                    })}
                                    style={{
                                        width: '100%'
                                    }}
                                    value={startTime}
                                />
                            </div>
                        </div>
                        {/* {groupAdd && (
                            <div>
                                <FormattedMessage id='business-package-container.expiration_datetime' />
                                <div>
                                    <DatePicker
                                        allowClear={false}
                                        format='YYYY-MM-DD'
                                        onChange={value =>
                                            this.setState({
                                                endDate: value
                                            })
                                        }
                                        placeholder={formatMessage({
                                            id: 'add_order_form.select_date'
                                        })}
                                        showTime={false}
                                        style={{
                                            width: '100%',
                                            marginRight: 8
                                        }}
                                        value={endDate}
                                    />
                                </div>
                            </div>
                        )} */}
                        <div
                            style={{
                                width: groupAdd ? '15%' : undefined
                            }}
                        >
                            <FormattedMessage id='order_form_table.duration' />
                            {groupAdd ? (
                                <div
                                    style={{
                                        width: '100%'
                                    }}
                                >
                                    <Select
                                        onChange={value => {
                                            this.setState({
                                                duration: value
                                            });
                                        }}
                                        options={_(Array.from(Array(13).keys()))
                                            .map(option => [
                                                {
                                                    value: option
                                                },
                                                {
                                                    value: option + 0.5
                                                }
                                            ])
                                            .flatten()
                                            .value()}
                                        placeholder={formatMessage({
                                            id: 'order_form_table.duration'
                                        })}
                                        value={duration}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <InputNumber
                                        onChange={value => {
                                            this.setState({
                                                duration: value
                                            });
                                        }}
                                        placeholder={formatMessage({
                                            id: 'order_form_table.duration'
                                        })}
                                        value={duration}
                                    />
                                </div>
                            )}
                        </div>
                        <div
                            style={{
                                width: '25%'
                            }}
                        >
                            <FormattedMessage id='orders.post' />
                            <Select
                                onChange={value => {
                                    this.setState({
                                        stationNum: value
                                    });
                                }}
                                placeholder={formatMessage({
                                    id: 'add_order_form.select_station'
                                })}
                                value={stationNum}
                            >
                                {this.props.stations.map(({ name, num }) => {
                                    return (
                                        <Option key={String(num)} value={num}>
                                            {name || String(num)}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                </Modal>
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={isArray(ogImage) ? get(ogImage, '[0].url', []) : get(ogImage, 'url', [])}
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Catcher>
        );
    }
}
