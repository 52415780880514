import { Button, Form, Select, Spin, notification } from 'antd';
import { onChangeProfileForm, submitProfileFormOther } from 'core/forms/profileForm/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.less';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    colon: false
};

const mapDispatchToProps = {
    onChangeProfileForm,
    submitProfileFormOther
};

const mapStateToProps = state => {
    return {
        user: state.auth,
        profileUpdating: state.ui.profileUpdating
    };
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class PricesTabSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultMarkup: undefined,
            markups: [],
            priorityMarkup: [{}],
            loading: false
        };
    }

    componentDidMount() {
        this.fetchMarkupsSettings();
        this.fetchMarkups();
    }

    _submitPrices = async values => {
        await fetchAPI(
            'PUT',
            '/business_markups_priority',
            null,
            {
                markupGroupId: _.get(values, 'markupGroupId') || 1.5,
                priorityMarkup: _.get(values, 'priorityMarkup') || 'PRICE_LIST'
            },
            {
                handleErrorInternally: true
            }
        );
        await this.fetchMarkupsSettings();
        notification.success({
            message: this.props.formatMessage({ id: 'barcode.success' })
        });
    };

    fetchMarkupsSettings = async () => {
        this.setState({
            loading: true
        });
        const response = await fetchAPI('GET', '/business_markups_priority', null, null);
        this.setState({
            markupsSettings: response.query
        });
        this.setState({
            loading: false
        });
    };

    fetchMarkups = async () => {
        const response = await fetchAPI('GET', '/markups', null, null);
        this.setState({
            markups: response
        });
    };

    render() {
        const {
            user,
            isDisabledCRUD,
            intl: { formatMessage }
        } = this.props;

        const { markups, defaultMarkup, priorityMarkup, markupsSettings, loading } = this.state;

        // const firstPriority = _.get(markupsSettings, 'priorityMarkup', []).findIndex(({ order }) => order === '1');
        // const secondPriority = _.get(markupsSettings, 'priorityMarkup', []).findIndex(({ order }) => order === '2');
        // const thirdPriority = _.get(markupsSettings, 'priorityMarkup', []).findIndex(({ order }) => order === '3');

        return (
            <Spin spinning={loading}>
                {markupsSettings && (
                    <Form
                        className={Styles.profileForm}
                        disabled={isDisabledCRUD}
                        initialValues={{
                            markupGroupId: _.get(markupsSettings, 'markupGroupId', undefined),
                            priorityMarkup: _.get(markupsSettings, 'priorityMarkup', 'PRICE_LIST')
                        }}
                        labelCol={formItemLayout.labelCol}
                        name='profileForm'
                        onFinish={this._submitPrices}
                        wrapperCol={formItemLayout.wrapperCol}
                    >
                        <Form.Item label={<FormattedMessage id='default' />} name='markupGroupId'>
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                placeholder={formatMessage({
                                    id: 'new-document-page.item.markup-group'
                                })}
                            >
                                {markups
                                    .filter(({ enabled }) => enabled)
                                    .map(markup => {
                                        return (
                                            <Option key={markup.using} value={markup.id}>
                                                {markup.name}{' '}
                                                {<FormattedMessage id={`product_markups.${markup.using}`} />} (
                                                {markup.markups[0] ? markup.markups[0].lowerLimit : undefined} -{' '}
                                                {markup.markups[0] ? markup.markups[0].markup : undefined};{' '}
                                                {markup.markups[1] ? markup.markups[1].lowerLimit : undefined} -{' '}
                                                {markup.markups[1] ? markup.markups[1].markup : undefined};
                                                {markup.markups[2] ? markup.markups[2].lowerLimit : undefined} -{' '}
                                                {markup.markups[2] ? markup.markups[2].markup : undefined};
                                                {markup.markups[3] ? '...' : ''})
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <div>
                                    <FormattedMessage id='calculate_based_on' />
                                </div>
                            }
                            name='priorityMarkup'
                        >
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                placeholder={formatMessage({
                                    id: 'new-document-page.item.markup-group'
                                })}
                            >
                                <Option key='PRICE_LIST' value='PRICE_LIST'>
                                    <FormattedMessage id='product_markups.PRICE_LIST' />
                                </Option>
                                <Option key='PRODUCT_GROUP' value='PRODUCT_GROUP'>
                                    <FormattedMessage id='product_markups.PRODUCT_GROUP' />
                                </Option>
                                <Option key='CUSTOMER_GROUP' value='CUSTOMER_GROUP'>
                                    <FormattedMessage id='product_markups.CUSTOMER_GROUP' />
                                </Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            className={Styles.itemBtn}
                            wrapperCol={{
                                offset: 11,
                                span: 12
                            }}
                        >
                            <Button className={Styles.saveBtn} htmlType='submit' type='primary'>
                                <FormattedMessage id='save' />
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </Spin>
        );
    }
}
