import { CarFilled, CopyOutlined, DollarOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, InputNumber, Select, Spin, Tooltip, notification } from 'antd';
import { Numeral } from 'commons';
import { selectCashSum } from 'core/forms/orderForm/duck';
import { MODALS, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { RefactoredCashOrderModal } from 'modals';
import React, { useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import book from 'routes/book';
import { fetchAPI, getCurrency } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;

const OrderDrawerContainer = ({ dataSource, onClose, open, intl: { formatMessage }, cashSum, user, setModal }) => {
    const [order, setOrder] = useState([]);
    const [labors, setLabors] = useState([]);
    const [details, setDetails] = useState([]);
    const [vehicle, setVehicle] = useState();
    const [client, setClient] = useState();
    const [managers, setManagers] = useState([]);
    const [managerId, setManagerId] = useState();
    const [employees, setEmployees] = useState([]);
    const [employeeId, setEmployeeId] = useState();
    const [odometr, setOdometr] = useState();
    const [loading, setLoading] = useState();
    const [appurtenanciesResponsible, setAppurtenancyResponsible] = useState();
    const [clientComment, setClientComment] = useState('');
    const [systemComment, setSystemComment] = useState('');

    const fetchOrder = useMemo(
        () => async query => {
            setLoading(true);
            const res = await fetchAPI('GET', `orders/${open}`, null, null, {
                handleErrorInternally: true
            });

            if (res && res.order.clientVehicleId) {
                const vehicle = await fetchAPI('GET', `clients/vehicles/${res.order.clientVehicleId}`, null, null, {
                    handleErrorInternally: true
                });
                setVehicle(vehicle && vehicle);
            }

            if (res && res.order.clientId) {
                const client = await fetchAPI('GET', `clients/${res.order.clientId}`, null, null, {
                    handleErrorInternally: true
                });
                setClient(client && client);
            }

            setOrder(res && res.order);
            setLabors(res && res.orderServices);
            setDetails(res && res.orderDetails);
            setManagers(res && res.managers);
            setEmployees(res && res.employees);

            setLoading(false);
        },
        [open]
    );

    const handleOk = useMemo(
        () => async () => {
            await fetchAPI(
                'PUT',
                `orders/${open}`,
                null,
                {
                    appurtenanciesResponsibleId: appurtenanciesResponsible,
                    comment: clientComment,
                    managerId,
                    employeeId,
                    odometerValue: odometr,
                    systemComment
                },
                {
                    handleErrorInternally: true
                }
            );

            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        },

        [appurtenanciesResponsible, clientComment, employeeId, formatMessage, managerId, odometr, open, systemComment]
    );

    useEffect(() => {
        if (open && order) {
            setManagerId(order.managerId);
            setEmployeeId(order.employeeId);
            setAppurtenancyResponsible(order.appurtenanciesResponsibleId);
            setOdometr(order.odometerValue);
            setClientComment(order.comment);
            setSystemComment(order.systemComment);
        }
    }, [open, order]);

    useEffect(() => {
        if (open) {
            fetchOrder();
        }
    }, [fetchOrder, open]);

    const mask = '0,0.00';

    const remainPrice =
        order && order.isTaxPayer ? ((order.remainingSum + order.tax) * 100) / 100 : (order.remainingSum * 100) / 100;

    const showCahOrderModal = () => {
        setModal(MODALS.REFACTORED_CASH_ORDER, {
            initValues: {
                orderId: open,
                clientId: order.clientId,
                sum: remainPrice
            },
            onCashOrderCreatedCallback: () => fetchOrder()
        });
    };

    const recommendationAutoSize = { minRows: 2, maxRows: 6 };

    const disabledActiveFields =
        order.status === 'success' ||
        order.status === 'cancel' ||
        !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER);

    return (
        <React.Fragment>
            <Drawer onClose={onClose} open={open} title={formatMessage({ id: 'directories.orders' })} width={600}>
                {loading ? (
                    <Spin />
                ) : (
                    <div>
                        <div className={Styles.block}>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='directories.orders' />
                                </span>
                                <div className={Styles.margin}>
                                    <a
                                        href={`${order.num && order.num.startsWith('RTL') ? book.rtl : book.order}/${
                                            order.id
                                        }`}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                    >
                                        {order.num}
                                    </a>
                                </div>
                            </span>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='repair_type' />
                                </span>
                                <div className={Styles.rightContent}>
                                    <FormattedMessage id={`aggregate.${order.orderBloke}`} />
                                </div>
                            </span>
                        </div>
                        <div className={Styles.block}>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='storage.created_datetime' />
                                </span>
                                <div className={Styles.margin}>{dayjs(order.datetime).format('DD-MM-YYYY')} </div>
                            </span>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='orders.record_date' />
                                </span>
                                <div className={Styles.rightContent}>
                                    {dayjs(order.beginDatetime).format('DD-MM-YYYY')}
                                </div>
                            </span>
                        </div>
                        <div className={Styles.block}>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='client' />
                                </span>
                                <div className={Styles.margin}>
                                    {client ? (
                                        <Link to={`${book.client}/${order.clientId}`}>
                                            {`${client && client.name} ${
                                                client && client.surname !== null ? client.surname : ''
                                            }`}
                                        </Link>
                                    ) : (
                                        ''
                                    )}
                                    <div>{order && get(order, 'clientPhone', '')}</div>
                                </div>
                            </span>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='status' />
                                </span>
                                <div className={Styles.rightContent}>
                                    <FormattedMessage id={`order_statuses_mapper.${order.status}`} />
                                </div>
                            </span>
                        </div>
                        <div className={Styles.block}>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='vehicle_page.vehicle_auto' />{' '}
                                    {vehicle && (
                                        <Link to={`${book.vehicle}/${order.clientVehicleId}`}>
                                            <CarFilled
                                                style={{
                                                    color: vehicle.colorCode,
                                                    fontSize: 20
                                                }}
                                            />
                                        </Link>
                                    )}
                                </span>
                                <div className={Styles.margin}>
                                    <div>
                                        <span className={Styles.title}>
                                            <FormattedMessage id='nRegNew' />
                                        </span>
                                        : {vehicle && vehicle.number}
                                    </div>
                                    <div>
                                        <span className={Styles.title}>
                                            <FormattedMessage id='VIN' />
                                        </span>
                                        : {vehicle && vehicle.vin}
                                        {vehicle && vehicle.vin && (
                                            <CopyToClipboard text={`${vehicle && vehicle.vin}`}>
                                                <Tooltip
                                                    placement='bottom'
                                                    title={<FormattedMessage id='order_form.copy_vin' />}
                                                >
                                                    <CopyOutlined
                                                        className={Styles.icon}
                                                        onClick={() => {
                                                            notification.success({
                                                                message: formatMessage({ id: 'vin.success_copied' })
                                                            });
                                                        }}
                                                    />
                                                </Tooltip>
                                            </CopyToClipboard>
                                        )}
                                    </div>
                                    <div>
                                        <span className={Styles.title}>
                                            <FormattedMessage id='universal_filters_tags.make' />
                                        </span>
                                        : {vehicle && vehicle.make}
                                    </div>
                                    <div>
                                        <span className={Styles.title}>
                                            <FormattedMessage id='model' />
                                        </span>
                                        : {vehicle && vehicle.model}
                                    </div>
                                    <div>
                                        <span className={Styles.title}>
                                            <FormattedMessage id='add_client_form.modification' />
                                        </span>
                                        : {vehicle && vehicle.modification}
                                    </div>
                                    <div>
                                        <span className={Styles.title}>
                                            <FormattedMessage id='year' />
                                        </span>
                                        : {vehicle && vehicle.year}
                                    </div>
                                </div>
                            </span>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='add_order_form.odometr' />
                                </span>
                                <div className={Styles.margin}>
                                    <InputNumber
                                        onChange={value => setOdometr(value)}
                                        placeholder={formatMessage({ id: 'add_order_form.provide_odometr' })}
                                        style={{
                                            width: '100%',
                                            maxWidth: 134
                                        }}
                                        value={odometr}
                                    />
                                </div>
                            </span>
                        </div>
                        <div className={Styles.block}>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='responsible' />
                                </span>
                                <div className={Styles.margin}>
                                    <Select
                                        disabled={disabledActiveFields}
                                        onChange={value => setManagerId(value)}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({ id: 'add_order_form.select_manager' })}
                                        showSearch
                                        style={{
                                            width: '100%',
                                            maxWidth: 134
                                        }}
                                        value={managerId}
                                    >
                                        {managers
                                            .filter(
                                                ({ posts, id }) =>
                                                    posts.findIndex(
                                                        ({ postName }) => postName === 'MASTER_RECEIVER'
                                                    ) !== -1 || get(order, 'managerId') === id
                                            )
                                            .sort((a, b) => a.managerSurname.localeCompare(b.managerSurname))
                                            .map(manager => (
                                                <Option
                                                    key={`manager-${manager.id}`}
                                                    disabled={manager.disabled}
                                                    value={manager.id}
                                                >
                                                    {`${manager.managerSurname} ${manager.managerName}`}
                                                </Option>
                                            ))}
                                    </Select>
                                </div>
                            </span>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='order_form_table.master' />
                                </span>
                                <div className={Styles.margin}>
                                    <Select
                                        disabled={disabledActiveFields}
                                        onChange={value => setEmployeeId(value)}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({ id: 'order_form_table.select_master' })}
                                        showSearch
                                        style={{
                                            width: '100%',
                                            maxWidth: 134
                                        }}
                                        value={employeeId}
                                    >
                                        {employees
                                            .filter(
                                                ({ disabled, posts, id }) =>
                                                    (!disabled &&
                                                        posts.findIndex(({ postName }) => postName === 'MECHANIC') !==
                                                            -1) ||
                                                    get(order, 'employeeId') === id
                                            )
                                            .sort((a, b) => a.surname.localeCompare(b.surname))
                                            .map(employee => (
                                                <Option
                                                    key={`employee-${employee.id}`}
                                                    disabled={employee.disabled}
                                                    post={get(employee, 'postref[0].postNum')}
                                                    value={employee.id}
                                                >
                                                    {`${employee.surname} ${employee.name}`}
                                                </Option>
                                            ))}
                                    </Select>
                                </div>
                            </span>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='order_form_table.appurtenancies_responsible' />
                                </span>
                                <div className={Styles.margin}>
                                    <Select
                                        disabled={disabledActiveFields}
                                        onChange={value => setAppurtenancyResponsible(value)}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({
                                            id: 'add_order_form.select_appurtenancies_responsible'
                                        })}
                                        showSearch
                                        style={{
                                            width: '100%',
                                            maxWidth: 134
                                        }}
                                        value={appurtenanciesResponsible}
                                    >
                                        {employees
                                            .filter(
                                                ({ disabled, posts, id }) =>
                                                    (!disabled &&
                                                        posts.findIndex(
                                                            ({ postName }) => postName === 'SPARE_PARTS_SPECIALIST'
                                                        ) !== -1) ||
                                                    _.get(order, 'appurtenanciesResponsibleId') === id
                                            )
                                            .sort((a, b) => a.surname.localeCompare(b.surname))
                                            .map(employee => (
                                                <Option
                                                    key={`employee-${employee.id}`}
                                                    disabled={employee.disabled}
                                                    value={employee.id}
                                                >
                                                    {`${employee.surname} ${employee.name}`}
                                                </Option>
                                            ))}
                                    </Select>
                                </div>
                            </span>
                        </div>
                        <div className={Styles.block}>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='sum' /> <FormattedMessage id='without' />{' '}
                                    <FormattedMessage id='VAT' />
                                </span>
                                <div className={Styles.margin}>
                                    <Numeral
                                        className={Styles.totalSum}
                                        currency={getCurrency()}
                                        mask={mask}
                                        nullText='0'
                                    >
                                        {order.totalSum || 0}
                                    </Numeral>
                                </div>
                            </span>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='sum' /> <FormattedMessage id='with' />{' '}
                                    <FormattedMessage id='VAT' />
                                </span>
                                <div className={Styles.margin}>
                                    <Numeral
                                        className={Styles.totalSum}
                                        currency={getCurrency()}
                                        mask={mask}
                                        nullText='0'
                                    >
                                        {order.totalSumWithTax || 0}
                                    </Numeral>
                                </div>
                            </span>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='storage.remain' />
                                    {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER) && (
                                        <Tooltip title={<FormattedMessage id='order-page.pay' />}>
                                            <DollarOutlined
                                                onClick={() => showCahOrderModal()}
                                                style={{
                                                    marginLeft: 4
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </span>
                                <div className={Styles.rightContent}>
                                    <Numeral
                                        className={Styles.totalSum}
                                        currency={getCurrency()}
                                        mask={mask}
                                        nullText='0'
                                    >
                                        {remainPrice || 0}
                                    </Numeral>
                                </div>
                            </span>
                        </div>
                        <div className={Styles.finalBlock}>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='add_order_form.client_comments' />
                                </span>
                                <div className={Styles.margin}>
                                    <Input.TextArea
                                        autoSize={recommendationAutoSize}
                                        disabled={disabledActiveFields}
                                        onChange={event => {
                                            setClientComment(event.target.value);
                                        }}
                                        placeholder={formatMessage({ id: 'add_order_form.client_comments' })}
                                        value={clientComment}
                                    />
                                </div>
                            </span>
                        </div>
                        <div className={Styles.finalBlock}>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='add_order_form.system_comments' />
                                </span>
                                <div className={Styles.margin}>
                                    <Input.TextArea
                                        autoSize={recommendationAutoSize}
                                        disabled={disabledActiveFields}
                                        onChange={event => {
                                            setSystemComment(event.target.value);
                                        }}
                                        placeholder={formatMessage({ id: 'add_order_form.system_comments' })}
                                        style={{
                                            width: '100%'
                                        }}
                                        value={systemComment}
                                    />
                                </div>
                            </span>
                        </div>

                        <div className={Styles.okBtn}>
                            <Button onClick={handleOk} type='primary'>
                                <FormattedMessage id='save' />
                            </Button>
                        </div>

                        <div className={Styles.block}>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='top_3_labors' />{' '}
                                </span>
                                <div className={Styles.margin}>
                                    {labors &&
                                        labors.slice(0, 3).map(({ serviceName, defaultName, laborId }, index) => {
                                            return (
                                                <div className={Styles.margin}>
                                                    {index + 1}. {serviceName || defaultName}
                                                </div>
                                            );
                                        })}
                                </div>
                            </span>
                            <span>
                                <span className={Styles.title}>
                                    <FormattedMessage id='top_3_details' />{' '}
                                </span>
                                <div className={Styles.margin}>
                                    {details &&
                                        details.slice(0, 3).map(({ detailName, detailCode }, index) => {
                                            return (
                                                <div className={Styles.margin}>
                                                    {index + 1}. {detailName}
                                                </div>
                                            );
                                        })}
                                </div>
                            </span>
                        </div>
                    </div>
                )}
            </Drawer>
            <RefactoredCashOrderModal />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    cashSum: selectCashSum(state)
});

const mapDispatchToProps = {
    setModal
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderDrawerContainer));
