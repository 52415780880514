import { Button, Flex, Spin, Table, notification } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

import './3d.css';
import path from './models/car.glb';
import { VisualAddColumns as columns } from './utils/columns';
import { SCENE_HEIGHT, SCENE_WIDTH, SCENE_WIDTH_VERTICAL } from './utils/constants';
import { useThreeScene } from './utils/useThreeScene';

const Tab3D = ({
    open,
    setOrderData,
    resetModal,
    orderId,
    reloadOrderForm,
    toOrderData,
    setToOrderData,
    setUnitComment,
    setUnitCommentPlaces,
    defaultEmployeeId,
    user,
    intl: { formatMessage },
    isActive
}) => {
    const [systems, setSystems] = useState([]);
    const [units, setUnits] = useState([]);
    const [verticalLayout, setVericalLayout] = useState(false);
    const mountRef = useRef(null);

    useEffect(() => {
        const fetchSystems = async () => {
            const result = await fetchAPI('GET', '/graphic_systems', { twoDimensional: false, id: 5 }, null, {
                handleErrorInternally: true
            });
            setSystems(result);
            setUnits(get(result, '[0].units', []));
        };
        if (open && !systems.length) {
            fetchSystems();
        }
    }, [open, systems.length]);

    const addUnit = useCallback(
        unit => {
            setUnitComment(unit.unitComment);
            setUnitCommentPlaces(unit.commentPointers);
            setOrderData({
                storeGroups: unit.storeGroups,
                labors: unit.labors
            });
        },
        [setUnitComment, setUnitCommentPlaces, setOrderData]
    );

    const { loading, showTooltip, tooltipContent, tooltipPosition } = useThreeScene({
        mountRef,
        modelPath: path,
        sphereUnits: units,
        onUnitClick: addUnit,
        palette: user.palette,
        initialCameraPosition: { x: -4.46, y: 4.88, z: -5.59 },
        sphereRadius: 0.13,
        verticalLayout
    });

    const handleCloseToOrderModal = useCallback(() => {
        setOrderData(undefined);
    }, [setOrderData]);

    const handleAddToOrder = useCallback(() => {
        const updateOrder = async () => {
            const payload = {
                insertMode: true,
                services: toOrderData.labors.map(
                    ({ laborId, laborName, laborHour, laborPrice, measureUnitId, comment }) => ({
                        serviceId: laborId,
                        serviceName: laborName,
                        count: laborHour,
                        servicePrice: laborPrice,
                        laborUnitId: measureUnitId,
                        employeeId: defaultEmployeeId,
                        comment
                    })
                ),
                details: toOrderData.storeGroups.map(({ storeGroupId, storeGroupName, measureUnitId, comment }) => ({
                    storeGroupId,
                    name: storeGroupName,
                    partUnitId: measureUnitId,
                    comment
                }))
            };

            if (!payload.services.length) {
                delete payload.services;
            }
            if (!payload.details.length) {
                delete payload.details;
            }

            await fetchAPI('PUT', `orders/${orderId}`, null, payload);
            notification.success({
                message: formatMessage({
                    id: 'barcode.success'
                })
            });
            resetModal();
            reloadOrderForm();
        };
        updateOrder();
        setToOrderData({ labors: [], storeGroups: [] });
        handleCloseToOrderModal();
    }, [
        handleCloseToOrderModal,
        toOrderData,
        orderId,
        formatMessage,
        resetModal,
        reloadOrderForm,
        defaultEmployeeId,
        setToOrderData
    ]);

    return (
        <Flex vertical={verticalLayout}>
            <div
                style={{
                    width: '100%',
                    minWidth: verticalLayout ? SCENE_WIDTH_VERTICAL : SCENE_WIDTH,
                    height: SCENE_HEIGHT,
                    position: 'relative'
                }}
            >
                {loading && (
                    <div className='loading-indicator'>
                        <Spin size='large' />
                    </div>
                )}
                <div ref={mountRef} style={{ width: '100%', height: '100%' }} />
                {showTooltip && (
                    <div
                        style={{
                            position: 'absolute',
                            left: tooltipPosition.x,
                            top: tooltipPosition.y,
                            pointerEvents: 'none',
                            backgroundColor: 'rgba(0,0,0,0.7)',
                            color: 'white',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            transform: 'translate(-50%, -100%)'
                        }}
                    >
                        {tooltipContent}
                    </div>
                )}
            </div>
            <div
                style={{
                    background: 'white',
                    minWidth: 360,
                    width: '100%'
                }}
            >
                <Table
                    bordered
                    columns={columns({ setToOrderData, setVericalLayout, verticalLayout, mountRef })}
                    dataSource={[...get(toOrderData, 'labors', []), ...get(toOrderData, 'storeGroups', [])]}
                    footer={() => (
                        <Flex justify='end'>
                            <Button
                                block
                                disabled={
                                    ![...get(toOrderData, 'labors', []), ...get(toOrderData, 'storeGroups', [])].length
                                }
                                onClick={handleAddToOrder}
                                type='primary'
                            >
                                <FormattedMessage id='ok' />
                            </Button>
                        </Flex>
                    )}
                    pagination={false}
                    rowKey={(record, index) => `${record.laborId || record.storeGroupId}-${index}`}
                    size='small'
                />
            </div>
        </Flex>
    );
};

export default injectIntl(Tab3D);
