import { CommentOutlined, VideoCameraFilled } from '@ant-design/icons';
import { Button, Form, Input, Tabs } from 'antd';
import { MODALS } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ImportReceiptDocumentModal, VinCodeModal, VinDiagnosticModal, VisualAdditionModal } from 'modals';
import AddRTNModal from 'pages/RetailsPage/components/addRTNModal';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { DocumentsIcon, LogsIcon, RepairIcon, SparePartsIcon, StatisticsIcon, VehicleHistoryIcon } from 'theme';
import { buildStoreGroupsTree, fetchAPI, getRtlIcons, getStorageToken, orderTypeMapper } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import {
    DetailsTable,
    DiagnosticTable,
    DiscountPanel,
    // TasksTable,
    HistoryTable,
    LogsTable,
    OrderDocumentsTable,
    OrderMediaTab,
    ServicesTable,
    StatisticsTab
} from '../OrderFormTables';
import ChassisDiagnosticTab from './components/ChassisDiagnosticTab';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

// function hideTasks(orderTasks, managerId) {
// 	const newOrderTasks = _.cloneDeep(orderTasks);
// 	_.each(_.get(newOrderTasks, 'orderTasks'), (newOrderTask) => {
// 		newOrderTask.history = _.filter(newOrderTask.history, {
// 			responsibleId: managerId,
// 		});
// 	});
//
// 	return newOrderTasks;
// }

@withRouter
@injectIntl
export default class RetailCheckTabs extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: props.isRTN ? 'services' : 'details',
            action: undefined,
            detailsTreeData: undefined,
            purchasePrices: false,
            units: [],
            labors: [],
            unitDefault: [],
            allLinks: [],
            suppliers: [],
            fileList: [],
            bsStats: {},
            helperDrawerOpen: false,
            diagnosticSelectedKey: 'chassis_diagnostic',
            rtnModalDetails: [],
            rtnModalServices: []
        };
        this._localizationMap = {};
        this.commentsRules = [
            {
                max: 2000,
                message: this.props.intl.formatMessage({
                    id: 'field_should_be_below_2000_chars'
                })
            }
        ];
        this.commentsAutoSize = { minRows: 2, maxRows: 6 };
        this.setActiveTab = this.setActiveTab.bind(this);
    }

    componentDidMount() {
        const { location, showCahOrderModal } = this.props;

        if (location.state && location.state.activeTab) {
            this.setState({
                activeKey: location.state.activeTab
            });
        }
        if (location.state && location.state.openCashOrderModal) {
            showCahOrderModal();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!prevProps.showOilModal && this.props.showOilModal) {
            this.setState({
                activeKey: 'details'
            });
        }
        if (this.props.scrollToMapId) {
            this.setState({
                activeKey: 'map'
            });
            await document.getElementById('OrderTabs').scrollIntoView({ behavior: 'smooth', block: 'center' });
            await this.props.scrollToMap(undefined);
        }
        if (!this.state.detailsTreeData && this.props.details && this.props.details.length) {
            const detailsTreeData = buildStoreGroupsTree(this.props.details);
            this.fetchUnits();
            this.fetchUnitsSettings();
            this.fetchLabors();
            this.fetchSuppliersOptions();
            this.setState({ detailsTreeData });
        }
        if (prevState.action) {
            this.setState({ action: undefined });
        }
    }

    // TODO: move into utils
    getLocalization(key) {
        if (!this._localizationMap[key]) {
            this._localizationMap[key] = this.props.intl.formatMessage({
                id: key
            });
        }

        return this._localizationMap[key];
    }

    fetchSuppliersOptions = async (query, all = true) => {
        const suppliers = await fetchAPI(
            'GET',
            'business_suppliers',
            {
                showHidden: false,
                query,
                all
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            suppliers
        });
    };

    handleSearchSuppliers = _.debounce(value => {
        this.fetchSuppliersOptions(value, true);
    }, 1000);

    setActiveTab(tab, action) {
        this.setState({
            activeKey: tab,
            action
        });
    }

    fetchUnits = async () => {
        const units = await fetchAPI('GET', 'business/measurement/units', undefined, undefined);
        this.setState({
            units
        });
    };

    fetchHelperLinks = async id => {
        const links = await fetchAPI('GET', '/helps', { helpId: id }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    fetchUnitsSettings = async () => {
        const unitDefault = await fetchAPI('GET', 'business/measurement/units/settings', undefined, undefined);
        this.setState({
            unitDefault: unitDefault.map(({ measurementUnits }) => measurementUnits).flat()
        });
    };

    fetchLabors = async query => {
        const { labors } = await fetchAPI(
            'GET',
            'labors',
            { search: query ? query.replace(/-/g, '') : undefined, page: 1, pageSize: 25 },
            null,
            {
                handleErrorInternally: true
            }
        );
        this.setState({ labors });
    };

    handleDiagnosticKeyChange = key => this.setState({ diagnosticSelectedKey: key });

    formatToMilliseconds = date => {
        return date.valueOf();
    };

    fetchFileList = async () => {
        const { orderId } = this.props;
        const formattedStartDate = this.formatToMilliseconds(dayjs().subtract(7, 'days'));
        const formattedEndDate = this.formatToMilliseconds(dayjs());
        const res = await fetchAPI(
            'GET',
            '/api/files',
            {
                page: 1,
                pageSize: 25,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                ord: orderId
            },
            null,
            {
                handleErrorInternally: true,
                url: __GALLARY_IMAGES_URL__,
                noAuth: true,
                headers: {
                    Authorization: getStorageToken()
                }
            }
        );

        this.setState({ fileList: res.list });
    };

    bussinessStats = () => {
        const res = fetchAPI('GET', '/api/business/stats', null, null, {
            handleErrorInternally: true,
            url: __GALLARY_IMAGES_URL__,
            noAuth: true,
            headers: {
                Authorization: getStorageToken()
            }
        });

        this.setState({
            bsStats: res
        });
    };

    openRTNModal = async () => {
        const {
            fetchedOrder: {
                order: { parentOrderId }
            }
        } = this.props;
        const { orderDetails, orderServices } = await fetchAPI(
            'GET',
            `/orders/${parentOrderId}`,
            {
                onlyLabors: true,
                onlyDetails: true
            },
            undefined,
            { handleErrorInternally: true }
        );
        this.setState({
            rtnModalOpen: true,
            rtnModalDetails: orderDetails.filter(({ agreement }) => agreement !== 'REJECTED'),
            rtnModalServices: orderServices.filter(({ agreement }) => agreement !== 'REJECTED')
        });
    };

    render() {
        const {
            setModal,
            defaultEmployeeId,
            modal,
            fetchOrderForm,
            resetModal,
            // fetchOrderTask,
            fetchTecdocSuggestions,
            fetchTecdocDetailsSuggestions,
            clearTecdocDetailsSuggestions,
            clearTecdocSuggestions,

            addOrderForm,
            detailsSuggestionsFetching,
            suggestionsFetching,

            // orderTasks,
            orderTasksStats,
            orderCalls,
            orderServices,
            orderRecommendations,
            orderDetails,
            orderDiagnostic,
            orderId,
            allServices,
            allDetails,
            details,
            employees,
            selectedClient,
            detailsSuggestions,
            suggestions,
            fetchedOrder,
            user,
            schedule,
            stations,
            availableHours,
            stationLoads,
            tecdocId,
            clientVehicleId,
            clientId,

            // fields
            initialStation,
            initialBeginDatetime,

            // stats
            priceDetails,
            priceServices,
            commentsCount,
            stationsCount,
            totalDetailsProfit,
            totalServicesProfit,

            intl: { formatMessage },
            form,
            errors,

            normHourPrice,

            showOilModal,
            oilModalData,
            clearOilData,
            modals,
            focusOnRef,
            showCahOrderModal,
            laborTimeMultiplier,
            selectedVehicle,
            orderStatus,
            onStatusChange,
            orderBloke,
            defaultResponsibleId,
            orderLaborsRequest,
            managers,
            bodyId,
            reloadOrderForm,
            isRetailOrder,
            isRTN
        } = this.props;
        const {
            fetchedOrder: {
                order: { parentOrderId: parentId }
            }
        } = this.props;

        const {
            purchasePrices,
            unitDefault,
            allLinks,
            activeKey,
            labors,
            suppliers,
            rtnModalOpen,
            rtnModalDetails,
            rtnModalServices
        } = this.state;

        const orderRecommendationsNumber = _.get(fetchedOrder, 'orderRecommendations.list', []);

        let orderServicesSize = 0;
        let orderDetailsSize = 0;
        let orderRecommendationsSize = 0;

        orderRecommendationsNumber.map(x => {
            if (x.recommendationId) orderRecommendationsSize++;
        });

        orderServices.map(x => {
            if (x.id) orderServicesSize++;
        });

        orderDetails.map(x => {
            if (x.id) orderDetailsSize++;
        });

        const activeTabs = orderTypeMapper[orderBloke || 'VEHICLE'].tabs;

        const closedEditing =
            (this.props.orderStatus == 'success' || this.props.orderStatus == 'cancel') &&
            !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_DOCUMENT_UNPOSTING);

        // if (
        //     (this.state.activeKey == 'services' && !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_JOBS)) ||
        //     !activeTabs.includes('services')
        // ) {
        //     if (isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS)) {
        //         this.setState({
        //             activeKey: 'details'
        //         });
        //     } else if (isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_HISTORY)) {
        //         this.setState({
        //             activeKey: 'history'
        //         });
        //     } else if (isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_REQUEST)) {
        //         this.setState({
        //             activeKey: 'request'
        //         });
        //     } else if (isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_REGULATIONS)) {
        //         this.setState({
        //             activeKey: 'regulations'
        //         });
        //     } else if (isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_INSPECTION)) {
        //         this.setState({
        //             activeKey: 'acceptance'
        //         });
        //     } else if (isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_POSTS)) {
        //         this.setState({
        //             activeKey: 'station'
        //         });
        //     }
        // }

        // const canCreateTask =
        // 	viewAllTasks && orderTasks.orderTasks && orderTasks.orderTasks.length < 1;
        //
        // const tasks = viewAllTasks ? orderTasks : hideTasks(orderTasks, user.id);

        const servicesTableFieldsProps = _.pick(this.props.fields, ['services', 'clientVehicle']);
        const detailsTableFieldsProps = _.pick(this.props.fields, ['details']);
        const discountTabFieldsProps = _.pick(this.props.fields, ['servicesDiscount', 'detailsDiscount']);
        const stationLoadsFieldsProps = _.pick(this.props.fields, ['stationLoads']);
        const commentLabelCol = { span: 24 };

        const rtlIcons = getRtlIcons();

        const allServicesSum = _.get(fetchedOrder, 'orderServices', [])
            .filter(({ agreement }) => agreement !== 'REJECTED')
            .reduce((acc, cur) => acc + cur.sum, 0);
        const allDetailsSum = _.get(fetchedOrder, 'orderDetails', [])
            .filter(({ agreement }) => agreement !== 'REJECTED')
            .reduce((acc, cur) => acc + cur.sum, 0);
        const generalDiscountServices = _.get(fetchedOrder, 'order.servicesDiscount');
        const generalDiscountDetails = _.get(fetchedOrder, 'order.detailsDiscount');
        const beginDatetime = _.get(fetchedOrder, 'order.beginDatetime');

        const totalLaborCount = _.get(fetchedOrder, 'orderServices', [])
            .filter(elem => elem.agreement !== 'REJECTED')
            .reduce((accumulator, { count }) => accumulator + count, 0)
            .toFixed(2);

        const getDiagnosticItems = () => [
            {
                key: 'vin_diagnostic',
                label: this.props.intl.formatMessage({ id: 'order-page.vin-diagnostic' }),
                children: (
                    <DiagnosticTable
                        action={this.state.action}
                        activeKey={this.state.activeKey}
                        allLinks={allLinks}
                        bodyId={bodyId}
                        bussinessStats={this.bussinessStats}
                        defaultEmployeeId={this.props.defaultEmployeeId}
                        details={details}
                        detailsTreeData={this.state.detailsTreeData}
                        disabled={orderStatus === 'success' || orderStatus === 'cancel'}
                        fetchFileList={this.fetchFileList}
                        fetchHelperLinks={this.fetchHelperLinks}
                        fetchLabors={this.fetchLabors}
                        forbidden={false}
                        form={form}
                        labors={labors}
                        laborTimeMultiplier={laborTimeMultiplier}
                        modals={modals}
                        normHourPrice={normHourPrice}
                        orderDetails={orderDetails}
                        orderDiagnostic={orderDiagnostic}
                        orderId={orderId}
                        orderImgId={_.get(fetchedOrder, 'order.orderImgId')}
                        orderImgVehicleType={_.get(fetchedOrder, 'order.orderImgVehicleType')}
                        orderNum={_.get(fetchedOrder, 'order.num', undefined)}
                        orderServices={orderServices}
                        reloadOrderForm={this.props.reloadOrderForm}
                        reloadOrderPageComponents={this.props.reloadOrderPageComponents}
                        selectedClient={selectedClient}
                        selectedVehicle={selectedVehicle}
                        setModal={setModal}
                        tecdocId={tecdocId}
                        unitDefault={unitDefault}
                        user={user}
                    />
                )
            },
            {
                key: 'chassis_diagnostic',
                label: this.props.intl.formatMessage({ id: 'order_form_table.chassis_diagnostic' }),
                children: (
                    <ChassisDiagnosticTab
                        bussinessStats={this.bussinessStats}
                        disabledPhoto={!isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_FILE_STORAGE)}
                        fetchFileList={this.fetchFileList}
                        orderId={orderId}
                        orderNum={_.get(fetchedOrder, 'order.num', undefined)}
                        reloadOrderForm={this.props.reloadOrderForm}
                    />
                )
            }
        ];

        return (
            <React.Fragment>
                <Tabs
                    activeKey={this.state.activeKey}
                    className={Styles.orderFormsTabs}
                    onTabClick={key => {
                        this.setState({
                            activeKey: key,
                            action: undefined
                        });
                    }}
                    size='small'
                    type='card'
                >
                    <TabPane
                        key='group1'
                        disabled
                        tab={
                            <div className={Styles.tabStyle}>
                                <span className={Styles.groupsStyle}>
                                    <FormattedMessage id='add_order_form.data' />
                                </span>
                            </div>
                        }
                    />
                    {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_JOBS) &&
                        !addOrderForm &&
                        activeTabs.includes('services') &&
                        isRTN && (
                            <TabPane
                                key='services'
                                // forceRender
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <RepairIcon className={Styles.iconsStyle} />
                                        <div>
                                            {`${formatMessage({
                                                id: 'add_order_form.services',
                                                defaultMessage: 'Services'
                                            })} (${orderServicesSize})`}
                                        </div>
                                    </div>
                                }
                            >
                                <ServicesTable
                                    activeKey={activeKey}
                                    agreementCompleted={_.get(fetchedOrder, 'order.agreementCompleted')}
                                    allServices={allServices}
                                    beginDatetime={beginDatetime}
                                    bodyId={bodyId}
                                    clientId={clientId}
                                    completedDiagnostic={orderDiagnostic ? orderDiagnostic.completed : null}
                                    crews={[]}
                                    defaultEmployeeId={defaultEmployeeId}
                                    details={details}
                                    detailsTreeData={this.state.detailsTreeData}
                                    disabled={
                                        closedEditing ||
                                        !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_JOBS, accesses.ROWO) ||
                                        orderStatus === 'success' ||
                                        orderStatus === 'cancel'
                                    }
                                    employees={employees}
                                    errors={errors}
                                    fetchCrewsOptions={this.fetchCrewsOptions}
                                    fetchedOrder={fetchedOrder}
                                    fetchLabors={this.fetchLabors}
                                    fetchSuppliersOptions={this.fetchSuppliersOptions}
                                    fetchTecdocSuggestions={fetchTecdocSuggestions}
                                    fields={servicesTableFieldsProps}
                                    form={form}
                                    isRTN={isRTN}
                                    labors={labors}
                                    laborTimeMultiplier={laborTimeMultiplier}
                                    modal={modal}
                                    normHourPrice={normHourPrice}
                                    openRTNModal={this.openRTNModal}
                                    orderDetails={orderDetails}
                                    orderId={orderId}
                                    orderServices={orderServices}
                                    purchasePrices={purchasePrices}
                                    reloadOrderForm={this.props.reloadOrderForm}
                                    resetModal={resetModal}
                                    selectedClient={selectedClient}
                                    selectedVehicle={selectedVehicle}
                                    setModal={setModal}
                                    showPurchasePrices={() => {
                                        this.setState({
                                            purchasePrices: !purchasePrices
                                        });
                                    }}
                                    suppliers={suppliers}
                                    tecdocId={tecdocId}
                                    unitDefault={unitDefault}
                                    units={this.state.units}
                                    user={user}
                                />
                                <DiscountPanel
                                    discountFieldName='servicesDiscount'
                                    fetchedOrder={fetchedOrder}
                                    fields={discountTabFieldsProps}
                                    forbidden={orderStatus === 'success' || orderStatus === 'cancel'}
                                    form={form}
                                    generalDiscount={generalDiscountServices}
                                    generalSumProfit={allServicesSum}
                                    isServiceMarkupForbidden={orderStatus === 'success' || orderStatus === 'cancel'}
                                    laborTimeMultiplier={laborTimeMultiplier}
                                    orderDetails={orderDetails}
                                    orderId={orderId}
                                    orderServices={orderServices}
                                    price={_.get(fetchedOrder, 'order.servicesPriceSum', 0)}
                                    purchasePrices={purchasePrices}
                                    reloadOrderForm={this.props.reloadOrderForm}
                                    servicesMode
                                    showPurchasePrices={() => {
                                        this.setState({
                                            purchasePrices: !purchasePrices
                                        });
                                    }}
                                    totalLaborCount={totalLaborCount}
                                    totalServicesProfit={totalServicesProfit}
                                />
                            </TabPane>
                        )}

                    {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS) && rtlIcons.includes('PARTS') && (
                        <TabPane
                            key='details'
                            tab={
                                <div className={Styles.tabStyle}>
                                    <SparePartsIcon className={Styles.iconsStyleSpecific} />
                                    <div>
                                        {`${formatMessage({
                                            id: 'add_order_form.details',
                                            defaultMessage: 'Details'
                                        })} (${orderDetailsSize})`}
                                    </div>
                                </div>
                            }
                        >
                            <DetailsTable
                                activeKey={this.state.activeKey}
                                agreementCompleted={_.get(fetchedOrder, 'order.agreementCompleted')}
                                allDetails={allDetails}
                                allLinks={allLinks}
                                bussinessStats={this.bussinessStats}
                                clearOilData={clearOilData}
                                clearTecdocDetailsSuggestions={clearTecdocDetailsSuggestions}
                                clearTecdocSuggestions={clearTecdocSuggestions}
                                clientId={clientId}
                                clientVehicleId={clientVehicleId}
                                clientVehicleVin={this.props.clientVehicleVin}
                                completedDiagnostic={orderDiagnostic ? orderDiagnostic.completed : null}
                                defaultResponsibleId={defaultResponsibleId}
                                details={details}
                                detailsSuggestions={detailsSuggestions}
                                detailsSuggestionsFetching={detailsSuggestionsFetching}
                                detailsTreeData={this.state.detailsTreeData}
                                disabled={
                                    orderStatus === 'success' ||
                                    orderStatus === 'cancel' ||
                                    !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS, accesses.ROWO)
                                }
                                discount={_.get(fetchedOrder, 'order.detailsDiscount', 0)}
                                employees={employees}
                                errors={errors}
                                fetchedOrder={fetchedOrder}
                                fetchFileList={this.fetchFileList}
                                fetchHelperLinks={this.fetchHelperLinks}
                                fetchRecommendedPrice={this.props.fetchRecommendedPrice}
                                fetchSuppliers={this.fetchSuppliersOptions}
                                fetchTecdocDetailsSuggestions={fetchTecdocDetailsSuggestions}
                                fields={detailsTableFieldsProps}
                                form={form}
                                isRetailOrder={this.props.isRetailOrder}
                                isRTN={isRTN}
                                labors={labors}
                                modal={modal}
                                oilModalData={oilModalData}
                                openRTNModal={this.openRTNModal}
                                orderDetails={orderDetails}
                                orderId={orderId}
                                orderNum={_.get(fetchedOrder, 'order.num', undefined)}
                                orderServices={orderServices}
                                orderStatus={_.get(fetchedOrder, 'order.status', undefined)}
                                purchasePrices={purchasePrices}
                                recommendedPrice={this.props.recommendedPrice}
                                recommendedPriceLoading={this.props.recommendedPriceLoading}
                                reloadOrderForm={this.props.reloadOrderForm}
                                resetModal={resetModal}
                                selectedVehicle={selectedVehicle}
                                setModal={setModal}
                                setStoreProductsSearchQuery={this.props.setStoreProductsSearchQuery}
                                showOilModal={showOilModal}
                                showPurchasePrices={() => {
                                    this.setState({
                                        purchasePrices: !purchasePrices
                                    });
                                }}
                                storeProducts={this.props.storeProducts}
                                suggestions={suggestions}
                                suggestionsFetching={suggestionsFetching}
                                suppliers={suppliers}
                                tecdocId={tecdocId}
                                units={this.state.units}
                                user={user}
                            />
                            <DiscountPanel
                                detailsMode
                                discountFieldName='detailsDiscount'
                                fetchedOrder={fetchedOrder}
                                fields={discountTabFieldsProps}
                                forbidden={orderStatus === 'success' || orderStatus === 'cancel'}
                                form={form}
                                generalDiscount={generalDiscountDetails}
                                generalSumProfit={allDetailsSum}
                                isRetailOrder={this.props.isRetailOrder}
                                orderDetails={orderDetails}
                                orderId={orderId}
                                price={_.get(fetchedOrder, 'order.appurtenanciesPriceSum', 0)}
                                purchasePrices={purchasePrices}
                                reloadOrderForm={this.props.reloadOrderForm}
                                showPurchasePrices={() => {
                                    this.setState({
                                        purchasePrices: !purchasePrices
                                    });
                                }}
                                totalDetailsProfit={totalDetailsProfit}
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_HISTORY) &&
                        rtlIcons.includes('VEHICLE_HISTORY') && (
                            <TabPane
                                key='history'
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <VehicleHistoryIcon className={Styles.iconsStyleSpecific} />
                                        <div>
                                            {`${formatMessage({
                                                id: 'order_form_table.history'
                                            })}`}
                                        </div>
                                    </div>
                                }
                            >
                                <HistoryTable
                                    allLinks={allLinks}
                                    clientVehicleId={_.get(fetchedOrder, 'order.clientVehicleId')}
                                    fetchHelperLinks={this.fetchHelperLinks}
                                    fetchOrderForm={fetchOrderForm}
                                    setHistoryCount={historyCount => {
                                        this.setState({
                                            historyCount
                                        });
                                    }}
                                    user={user}
                                />
                            </TabPane>
                        )}

                    <TabPane
                        key='group3'
                        disabled
                        tab={
                            <div className={Styles.tabStyle}>
                                <span className={Styles.groupsStyle}>
                                    <FormattedMessage id='add_order_form.other' />
                                </span>
                            </div>
                        }
                    />
                    {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_COMMENTS) &&
                        rtlIcons.includes('COMMENTS') && (
                            <TabPane
                                key='comments'
                                className={Styles.groupFirst}
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <CommentOutlined className={Styles.iconsStyle} />
                                        <div>
                                            {`${formatMessage({
                                                id: 'add_order_form.comments'
                                            })} (${commentsCount})`}
                                        </div>
                                    </div>
                                }
                            >
                                <div className={Styles.commentBlock}>
                                    <Form.Item
                                        initialValue={_.get(fetchedOrder, 'order.comment')}
                                        label={this.getLocalization('add_order_form.client_comments')}
                                        labelCol={commentLabelCol}
                                        name='comment'
                                        rules={this.commentsRules}
                                    >
                                        <Input.TextArea
                                            autoSize={this.commentsAutoSize}
                                            className={Styles.commentInput}
                                            data-qa='txt_area_comment_order_form_tabs_order_page'
                                            disabled={
                                                !isGrantAccessed(
                                                    user,
                                                    grants.OPERATIONS_ORDER_DOCUMENT_COMMENTS,
                                                    accesses.ROWO
                                                )
                                            }
                                            maxLength={2000}
                                            placeholder={this.getLocalization('add_order_form.client_comments')}
                                        />
                                    </Form.Item>
                                </div>
                                <div className={Styles.commentBlock}>
                                    <Form.Item
                                        initialValue={_.get(fetchedOrder, 'order.agreementComment')}
                                        label={this.getLocalization('add_order_form.aggrement_comment')}
                                        labelCol={commentLabelCol}
                                        name='agreementComment'
                                        rules={this.commentsRules}
                                    >
                                        <Input.TextArea
                                            autoSize={this.commentsAutoSize}
                                            className={Styles.commentInput}
                                            data-qa='txt_area_comment_order_form_tabs_order_page'
                                            disabled
                                            maxLength={2000}
                                            placeholder={this.getLocalization('add_order_form.client_comments')}
                                        />
                                    </Form.Item>
                                </div>

                                <div className={Styles.commentBlock}>
                                    <Form.Item
                                        initialValue={_.get(fetchedOrder, 'order.vehicleCondition')}
                                        label={this.getLocalization('add_order_form.vehicle_condition')}
                                        labelCol={commentLabelCol}
                                        name='vehicleCondition'
                                        rules={this.commentsRules}
                                    >
                                        <Input.TextArea
                                            autoSize={this.commentsAutoSize}
                                            className={Styles.commentInput}
                                            data-qa='txt_area_comment_vehicle_condition_order_form_tabs_order_page'
                                            disabled={
                                                !isGrantAccessed(
                                                    user,
                                                    grants.OPERATIONS_ORDER_DOCUMENT_COMMENTS,
                                                    accesses.ROWO
                                                )
                                            }
                                            maxLength={2000}
                                            placeholder={this.getLocalization('add_order_form.vehicle_condition')}
                                        />
                                    </Form.Item>
                                </div>
                                <div className={Styles.commentBlock}>
                                    <Form.Item
                                        initialValue={_.get(fetchedOrder, 'order.businessComment')}
                                        label={this.getLocalization('add_order_form.business_comment')}
                                        labelCol={commentLabelCol}
                                        name='businessComment'
                                        rules={this.commentsRules}
                                    >
                                        <Input.TextArea
                                            autoSize={this.commentsAutoSize}
                                            className={Styles.commentInput}
                                            data-qa='txt_area_business_comment_order_form_tabs_order_page'
                                            disabled={
                                                !isGrantAccessed(
                                                    user,
                                                    grants.OPERATIONS_ORDER_DOCUMENT_COMMENTS,
                                                    accesses.ROWO
                                                )
                                            }
                                            maxLength={2000}
                                            placeholder={this.getLocalization('add_order_form.business_comment')}
                                        />
                                    </Form.Item>
                                </div>

                                <div className={Styles.commentBlock}>
                                    <Form.Item
                                        initialValue={_.get(fetchedOrder, 'order.recommendation')}
                                        label={this.getLocalization('add_order_form.service_recommendations')}
                                        labelCol={commentLabelCol}
                                        name='recommendation'
                                        rules={this.commentsRules}
                                    >
                                        <Input.TextArea
                                            autoSize={this.commentsAutoSize}
                                            className={Styles.commentInput}
                                            data-qa='txt_area_comment_service_recommendation_order_form_tabs_order_page'
                                            disabled={
                                                !isGrantAccessed(
                                                    user,
                                                    grants.OPERATIONS_ORDER_DOCUMENT_COMMENTS,
                                                    accesses.ROWO
                                                )
                                            }
                                            maxLength={2000}
                                            placeholder={this.getLocalization('add_order_form.service_recommendations')}
                                        />
                                    </Form.Item>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Button
                                        disabled={
                                            !isGrantAccessed(
                                                user,
                                                grants.OPERATIONS_ORDER_DOCUMENT_COMMENTS,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={() => {
                                            if (orderStatus) {
                                                onStatusChange(orderStatus);
                                            }
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='save' />
                                    </Button>
                                </div>
                            </TabPane>
                        )}
                    {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_LOGS) &&
                        rtlIcons.includes('ORDERS_LOGS') && (
                            <TabPane
                                key='orders_logs'
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <LogsIcon className={Styles.iconsStyle} />
                                        <div>
                                            {formatMessage({
                                                id: 'add_order_form.logs'
                                            })}{' '}
                                        </div>
                                    </div>
                                }
                            >
                                <LogsTable
                                    activeKey={activeKey}
                                    employees={employees}
                                    managers={managers}
                                    orderId={orderId}
                                />
                            </TabPane>
                        )}

                    {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_FILE_STORAGE) &&
                        rtlIcons.includes('FS') && (
                            <TabPane
                                key='media'
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <VideoCameraFilled className={Styles.iconsStyle} />
                                        <div>
                                            {formatMessage({
                                                id: 'gallary.media'
                                            })}
                                        </div>
                                    </div>
                                }
                            >
                                <OrderMediaTab
                                    activeKey={activeKey}
                                    allLinks={allLinks}
                                    bsStats={this.state.bsStats}
                                    fetchHelperLinks={this.fetchHelperLinks}
                                    files={this.state.fileList}
                                    orderCalls={orderCalls}
                                    orderId={orderId}
                                    orderNum={_.get(fetchedOrder, 'order.num', undefined)}
                                />
                            </TabPane>
                        )}
                    {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENTS) && rtlIcons.includes('DOCUMENTS') && (
                        <TabPane
                            key='documents'
                            tab={
                                <div className={Styles.tabStyle}>
                                    <DocumentsIcon className={Styles.iconsStyle} />
                                    <div>
                                        {formatMessage({
                                            id: 'add_order_form.docs'
                                        })}{' '}
                                    </div>
                                </div>
                            }
                        >
                            <OrderDocumentsTable
                                allLinks={allLinks}
                                clientId={clientId}
                                fetchHelperLinks={this.fetchHelperLinks}
                                orderId={orderId}
                                selectedClient={selectedClient}
                                user={user}
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_STATISTICS) &&
                        rtlIcons.includes('STATISTICS') && (
                            <TabPane
                                key='statistics'
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <StatisticsIcon className={Styles.iconsStyle} />
                                        <div>
                                            {formatMessage({
                                                id: 'add_order_form.statistics'
                                            })}{' '}
                                        </div>
                                    </div>
                                }
                            >
                                <StatisticsTab
                                    allLinks={allLinks}
                                    fetchedOrder={fetchedOrder}
                                    fetchHelperLinks={this.fetchHelperLinks}
                                    orderId={orderId}
                                />
                            </TabPane>
                        )}
                </Tabs>

                <VinCodeModal
                    detailsTreeData={this.state.detailsTreeData}
                    disabled={false}
                    modal={modal}
                    resetModal={resetModal}
                    vin={this.props.clientVehicleVin}
                />
                <VinDiagnosticModal
                    detailsTreeData={this.state.detailsTreeData}
                    disabled={false}
                    modal={modal}
                    resetModal={resetModal}
                    vin={this.props.clientVehicleVin}
                />
                <VisualAdditionModal
                    isRetailOrder={isRetailOrder}
                    open={modal === MODALS.VISUAL_ADDITION}
                    orderId={orderId}
                    reloadOrderForm={reloadOrderForm}
                    resetModal={resetModal}
                />
                <ImportReceiptDocumentModal
                    handleSearchSuppliers={this.handleSearchSuppliers}
                    onConfirm={() => reloadOrderForm(() => {})}
                    orderId={orderId}
                    resetModal={() => resetModal()}
                    suppliers={suppliers || []}
                    visible={modal}
                />
                <AddRTNModal
                    onCancel={() => {
                        this.setState({ rtnModalOpen: false, rtnModalDetails: [], rtnModalServices: [] });
                    }}
                    onOk={async (_, _1, details, services) => {
                        console.log(details, services);
                        const data = {
                            insertMode: true,
                            details: details.map(detail => {
                                return detail.productId
                                    ? {
                                          parentId: detail.id,
                                          storeGroupId: detail.storeGroupId,
                                          name: detail.name || detail.detailName,
                                          productId: detail.storeId || detail.productId,
                                          productCode: detail.detailCode || detail.productCode || null,
                                          purchasePrice: detail.purchasePrice || 0,
                                          count: detail.count ? detail.count : 1,
                                          price: detail.price ? detail.price : 0,
                                          supplierBrandId: detail.supplierBrandId || detail.brandId,
                                          supplierId: detail.supplierId,
                                          supplierOriginalCode: detail.supplierOriginalCode,
                                          supplierProductNumber: detail.supplierProductNumber,
                                          supplierPartNumber: detail.supplierPartNumber,
                                          cellAddress: detail.cellAddress || null,
                                          warehouseId: detail.warehouseId,
                                          partUnitId: detail.partUnitId || detail.productUnitId,
                                          oeCode: detail.oeCode || null,
                                          partRowDiscount: detail.partRowDiscount || 0,
                                          comment: detail.comment || {
                                              comment: undefined,
                                              positions: []
                                          }
                                      }
                                    : {
                                          parentId: detail.id,
                                          storeGroupId: detail.storeGroupId,
                                          name: detail.name || detail.detailName,
                                          productCode: detail.detailCode || detail.productCode || null,
                                          supplierId: detail.supplierId,
                                          supplierBrandId: detail.supplierBrandId || detail.brandId || null,
                                          supplierOriginalCode: detail.supplierOriginalCode,
                                          supplierProductNumber: detail.supplierProductNumber,
                                          supplierPartNumber: detail.supplierPartNumber,
                                          purchasePrice: detail.purchasePrice || 0,
                                          count: detail.count,
                                          price: detail.price ? detail.price : 0,
                                          partUnitId: detail.partUnitId || detail.productUnitId,
                                          oeCode: detail.oeCode || null,
                                          partRowDiscount: detail.partRowDiscount || 0,
                                          comment: detail.comment || {
                                              comment: undefined,
                                              positions: []
                                          }
                                      };
                            }),
                            services: services.map(labor => ({
                                parentId: labor.id,
                                serviceId: labor.laborId,
                                laborUnitId: labor.laborUnitId || 1,
                                serviceName: labor.serviceName,
                                counterparty: labor.counterparty,
                                employeeId: labor.employeeId || null,
                                crewId: labor.crewId || null,
                                businessSupplierId: labor.businessSupplierId || null,
                                serviceHours: labor.hours,
                                purchasePrice: labor.purchasePrice || 0,
                                count: labor.count,
                                servicePrice: labor.price || 0,
                                serviceRowDiscount: labor.serviceRowDiscount || 0,
                                comment: labor.comment || {
                                    comment: undefined,
                                    positions: [],
                                    problems: []
                                }
                            }))
                        };
                        if (!data.services.length) delete data.services;
                        if (!data.details.length) delete data.details;
                        await fetchAPI('PUT', `/return/${orderId}`, null, data, { handleErrorInternally: true });
                        reloadOrderForm();
                    }}
                    open={rtnModalOpen}
                    orderDetails={rtnModalDetails}
                    orderServices={rtnModalServices}
                />
            </React.Fragment>
        );
    }
}
