import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { getLocale } from 'utils';
import { SCENE_HEIGHT, SCENE_WIDTH, SCENE_WIDTH_VERTICAL } from './constants';

function createSphere(unit, onClick, renderer, palette, radius) {
    const primaryColorStr = palette?.primary ?? 'rgb(155, 89, 182)';
    const hoverColorStr = palette?.secondary ?? 'rgb(81, 205, 102)';

    const primaryColor = new THREE.Color(primaryColorStr);
    const hoverColor = new THREE.Color(hoverColorStr);

    const geometry = new THREE.SphereGeometry(radius, 16, 16);
    // Using MeshNormalMaterial for “rainbow” effect, but you could do MeshBasicMaterial({ color: primaryColor }) too
    const material = new THREE.MeshNormalMaterial({
        color: primaryColor,
        wireframe: true
    });
    const sphere = new THREE.Mesh(geometry, material);

    sphere.onClick = () => onClick(unit);
    sphere.onPointerOver = () => {
        // material.color?.set(hoverColor);
        material.setValues({ wireframe: false });
        renderer.domElement.style.cursor = 'pointer';
    };
    sphere.onPointerOut = () => {
        // material.color?.set(primaryColor);
        material.setValues({ wireframe: true });
        renderer.domElement.style.cursor = 'auto';
    };

    return sphere;
}

export function useThreeScene({
    mountRef,
    modelPath,
    initialCameraPosition = { x: 0, y: 3, z: -7 },
    sphereUnits,
    onUnitClick,
    palette,
    sphereRadius = 0.13,
    showCoordinateSphere,
    lodLevel,
    objectsData,
    verticalLayout,
    units
}) {
    const [loading, setLoading] = useState(true);
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [showTooltip, setShowTooltip] = useState(false);

    const [coordinateSphere, setCoordinateSphere] = useState(null);
    const modelRef = useRef(null);
    const sceneRef = useRef(null);
    const rendererRef = useRef(null);
    const animationId = useRef(null);
    const cameraRef = useRef(null);
    const controlsRef = useRef(null);

    // Keep track of the object currently hovered by cursor
    const hoveredObjectRef = useRef(null);

    useEffect(() => {
        // If we show a coordinate sphere, move it to the provided coordinates
        if (showCoordinateSphere && coordinateSphere) {
            coordinateSphere.position.set(showCoordinateSphere.xx, showCoordinateSphere.yy, showCoordinateSphere.zz);
        }
    }, [coordinateSphere, showCoordinateSphere]);

    useEffect(() => {
        if (!mountRef.current) return;

        const width = mountRef.current.clientWidth;
        const height = mountRef.current.clientHeight;

        const scene = new THREE.Scene();
        sceneRef.current = scene;

        const camera = new THREE.PerspectiveCamera(80, width / height, 0.1, 1000);
        cameraRef.current = camera;
        camera.position.set(initialCameraPosition.x, initialCameraPosition.y, initialCameraPosition.z);

        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(width, height);
        renderer.setClearColor(0xffffff);
        mountRef.current.appendChild(renderer.domElement);
        rendererRef.current = renderer;

        // Add lights
        const ambientLight = new THREE.AmbientLight(0xfffff0, 1);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffeedd, 1);
        directionalLight.position.set(9, 10, 10.5);
        scene.add(directionalLight);

        const hemisphereLight = new THREE.HemisphereLight(0xddeeff, 0x202020, 1);
        scene.add(hemisphereLight);

        // OrbitControls
        const controls = new OrbitControls(camera, renderer.domElement);
        controlsRef.current = controls;
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.enableZoom = true;
        controls.enablePan = true;

        // This boolean will be used to prevent “click” if we’re orbiting/panning
        let isControled = false;

        const handleControlStart = () => {
            isControled = true;
        };
        const handleControlEnd = () => {
            isControled = false;
        };

        controls.addEventListener('start', handleControlStart);
        controls.addEventListener('end', handleControlEnd);

        // Load Model
        const loader = new GLTFLoader();
        loader.load(
            modelPath,
            gltf => {
                const model = gltf.scene;
                model.traverse(child => {
                    const matchedObj = objectsData?.find(obj => obj.name === child.name);
                    if (child.isMesh) {
                        child.material.metalness = 0.2;
                        child.material.roughness = 0.5;

                        child.material.emissiveIntensity = 0.5;
                        child.material.emissive = new THREE.Color(0x000);

                        child.onClick = obj => {
                            const unit = units.find(({ id }) => id === matchedObj?.unitId) || {};
                            const { storeGroups, labors, commentPointers, unitComment } = unit;

                            onUnitClick({
                                storeGroups,
                                labors,
                                commentPointers,
                                unitComment
                            });
                        };
                        child.userData = {
                            originalMaterial: child.material.clone(),
                            detailName:
                                get(matchedObj, `tooltip.${getLocale()}`) ||
                                matchedObj?.name_ua ||
                                matchedObj?.name ||
                                child.name ||
                                'Part'
                        };
                    }
                });
                scene.add(model);
                modelRef.current = model;
                setLoading(false);

                // If there’s no objectsData – show spheres for each unit
                if (!objectsData || objectsData.length === 0) {
                    if (!showCoordinateSphere) {
                        sphereUnits.forEach(unit => {
                            const sphere = createSphere(unit, onUnitClick, renderer, palette, sphereRadius);
                            sphere.position.set(unit.plusX, unit.plusY, unit.plusZ);
                            sphere.userData.unit = unit;
                            sphere.userData.sphere = true;
                            scene.add(sphere);
                        });
                    } else {
                        // Coordinate sphere
                        const primaryColor = new THREE.Color('rgb(155, 89, 182)');
                        const geometry = new THREE.SphereGeometry(12, 16, 16);
                        const material = new THREE.MeshNormalMaterial({
                            color: primaryColor,
                            wireframe: true
                        });
                        const coordSphere = new THREE.Mesh(geometry, material);
                        setCoordinateSphere(coordSphere);
                        scene.add(coordSphere);
                    }
                }
            },
            undefined,
            error => {
                console.error(error);
                setLoading(false);
            }
        );

        // Raycaster & Mouse
        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();
        let intersects = [];

        const handlePointerMove = e => {
            if (!rendererRef.current || !cameraRef.current || !sceneRef.current) {
                return;
            }
            const renderer = rendererRef.current;
            const camera = cameraRef.current;
            const scene = sceneRef.current;

            const rect = renderer.domElement.getBoundingClientRect();
            const mouseX = e.clientX - rect.left;
            const mouseY = e.clientY - rect.top;
            mouse.set((mouseX / rect.width) * 2 - 1, -(mouseY / rect.height) * 2 + 1);

            raycaster.setFromCamera(mouse, camera);
            intersects = raycaster.intersectObjects(scene.children, true).filter(({ object }) => object.visible);

            if (intersects.length > 0) {
                const intersectionObj = intersects[0].object;

                // If we hovered on a new object, un-hover the previous one
                if (hoveredObjectRef.current && hoveredObjectRef.current.uuid !== intersectionObj.uuid) {
                    const prevObj = hoveredObjectRef.current;
                    if (prevObj.userData?.originalMaterial) {
                        prevObj.material = prevObj.userData.originalMaterial.clone();
                        prevObj.material.emissive = new THREE.Color(0x000);
                    }
                    if (prevObj.onPointerOut) {
                        prevObj.onPointerOut({ object: prevObj });
                    }
                    hoveredObjectRef.current = null;
                }

                // Now hover the new object if none is hovered yet
                if (!hoveredObjectRef.current) {
                    hoveredObjectRef.current = intersectionObj;
                    if (intersectionObj.onPointerOver) {
                        intersectionObj.onPointerOver({ object: intersectionObj });
                    }

                    // If the hovered object is not our sphere, override with a new highlight material
                    if (!intersectionObj.userData?.sphere) {
                        intersectionObj.material = new THREE.MeshNormalMaterial();
                    }
                }

                // Update tooltip if it has a name or unit
                if (intersectionObj.userData?.detailName) {
                    setTooltipContent(intersectionObj.userData.detailName);
                    setTooltipPosition({ x: mouseX, y: mouseY - 8 });
                    setShowTooltip(true);
                    renderer.domElement.style.cursor = 'pointer';
                } else if (intersectionObj.userData?.unit) {
                    const { unit } = intersectionObj.userData;
                    setTooltipContent(unit.name);
                    setTooltipPosition({ x: mouseX, y: mouseY - 8 });
                    setShowTooltip(true);
                    renderer.domElement.style.cursor = 'pointer';
                } else {
                    setShowTooltip(false);
                }
            } else {
                // Nothing hovered, un-hover if we had something
                if (hoveredObjectRef.current) {
                    const prevObj = hoveredObjectRef.current;
                    if (prevObj.userData?.originalMaterial) {
                        prevObj.material = prevObj.userData.originalMaterial.clone();
                        prevObj.material.emissive = new THREE.Color(0x000);
                    }
                    if (prevObj.onPointerOut) {
                        prevObj.onPointerOut({ object: prevObj });
                    }
                    hoveredObjectRef.current = null;
                }
                setShowTooltip(false);
            }
        };

        const handleClick = e => {
            // If OrbitControls is active (dragging), skip click
            if (isControled) return;

            if (intersects.length > 0) {
                const intersection = intersects[0];
                if (intersection.object.onClick) {
                    intersection.object.onClick(intersection);
                }
            }
        };

        renderer.domElement.addEventListener('pointermove', handlePointerMove);
        renderer.domElement.addEventListener('click', handleClick);

        // Handle resize
        const handleResize = () => {
            if (!mountRef.current || !rendererRef.current || !cameraRef.current) return;
            const renderer = rendererRef.current;
            const camera = cameraRef.current;

            const newWidth = mountRef.current.clientWidth;
            const newHeight = mountRef.current.clientHeight;
            renderer.setSize(newWidth, newHeight);
            camera.aspect = newWidth / newHeight;
            camera.updateProjectionMatrix();
        };
        window.addEventListener('resize', handleResize);

        // Animation loop
        const animate = () => {
            animationId.current = requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        };
        animate();

        // Cleanup on unmount
        return () => {
            cancelAnimationFrame(animationId.current);
            window.removeEventListener('resize', handleResize);

            if (renderer.domElement) {
                renderer.domElement.removeEventListener('pointermove', handlePointerMove);
                renderer.domElement.removeEventListener('click', handleClick);
            }

            controls.removeEventListener('start', handleControlStart);
            controls.removeEventListener('end', handleControlEnd);

            // Clean up model
            if (modelRef.current) {
                modelRef.current.traverse(child => {
                    if (child.isMesh) {
                        child.geometry?.dispose();
                        if (Array.isArray(child.material)) {
                            child.material.forEach(mat => mat.dispose());
                        } else {
                            child.material?.dispose();
                        }
                    }
                });
                sceneRef.current.remove(modelRef.current);
                modelRef.current = null;
            }

            // Clean up coordinate sphere
            if (coordinateSphere) {
                coordinateSphere.geometry?.dispose();
                coordinateSphere.material?.dispose();
                sceneRef.current.remove(coordinateSphere);
            }

            if (renderer) {
                renderer.dispose();
            }

            if (mountRef.current && renderer.domElement) {
                mountRef.current.removeChild(renderer.domElement);
            }
        };
    }, [mountRef, objectsData, sphereUnits, units]);

    // Handle vertical vs. horizontal layout
    useEffect(() => {
        if (typeof verticalLayout === 'boolean') {
            const width = !verticalLayout ? SCENE_WIDTH : SCENE_WIDTH_VERTICAL;
            const height = SCENE_HEIGHT;
            cameraRef.current.aspect = width / height;
            cameraRef.current.updateProjectionMatrix();
            rendererRef.current.setSize(width, height);
        }
    }, [verticalLayout]);

    // LOD Logic
    useEffect(() => {
        if (!modelRef.current || !objectsData || objectsData.length === 0) return;

        const lodObjects = objectsData.filter(obj => obj.lod.includes(lodLevel));
        modelRef.current.traverse(child => {
            if (child.isMesh) {
                // If LOD_4 => show everything
                if (lodLevel === 'LOD_4') {
                    child.visible = true;
                } else {
                    child.visible = lodObjects.some(obj => obj.name === child.name);
                }
            }
        });
    }, [lodLevel, objectsData]);

    return {
        loading,
        showTooltip,
        tooltipContent,
        tooltipPosition
    };
}
