import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, List, Tabs } from 'antd';
import classNames from 'classnames/bind';
import { Layout, Loader } from 'commons';
import { EmployeeFeedback, EmployeeStatistics, PositionsSettings, SettingsTab } from 'components';
import { EmployeeDebtContainer } from 'containers';
import { fetchEmployees } from 'core/employees/duck';
import {
    fetchEmployeeById,
    fireEmployee,
    resetEmployeeForm,
    saveEmployee,
    selectInitialEmployee
} from 'core/forms/employeeForm/duck';
import dayjs from 'dayjs';
import { EmployeeForm, SettingSalaryForm } from 'forms';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, isForbidden, linkTo, permissions } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import WorkingDaysTab from './components/WorkingDaysTab';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

const cx = classNames.bind(Styles);

const mapStateToProps = state => ({
    employees: state.employees.employees,
    employeeName: state.forms.employeeForm.employeeName,
    initialEmployee: selectInitialEmployee(state),
    user: state.auth
});

const mapDispatchToProps = {
    saveEmployee,
    fetchEmployees,
    fetchEmployeeById,
    resetEmployeeForm,
    fireEmployee
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class EditEmployeePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            factSchedule: [],
            roles: []
        };
    }

    componentDidMount() {
        this.props.fetchEmployeeById(this.props.match.params.id);
        this.props.fetchEmployees();
        this.fetchFactSchedule(this.props.match.params.id, dayjs().format('YYYY-MM-DD'));
        this.fetchAllRoles();
    }

    fireEmployee = () => {
        const { form } = this.employeeFormRef.props;
        const employeesData = form.getFieldsValue();
        const { id } = this.props.match.params;
        this.props.fireEmployee(employeesData, id, this.props.initialEmployee.fireDate ? null : dayjs());
    };

    saveEmployeeFormRef = formRef => {
        this.employeeFormRef = formRef;
    };

    fetchAllRoles = async () => {
        const res = await fetchAPI('GET', '/v2/roles_and_accesses/standard_roles/simple_list', null, null, {
            handleErrorInternally: true
        });
        await this.setState({
            roles: res
        });
    };

    fetchFactSchedule = async (employeeId, date) => {
        const response = await fetchAPI(
            'GET',
            'registred_hours',
            {
                employeeId,
                date
            },
            null,
            {
                handleErrorInternally: true
            }
        );
        await this.setState({
            factSchedule: response
        });
    };

    saveEmployee = () => {
        const { form } = this.employeeFormRef.props;
        form.validateFields((err, values) => {
            if (!err) {
                const { id } = this.props.match.params;
                this.props.saveEmployee(values, id);
            }
        });
    };

    _linkToEmployee = id => {
        linkTo(`${book.employeesPage}/${id}`);
        this.props.fetchEmployeeById(id);
        this.props.fetchEmployees();
    };

    render() {
        const employeeTabs = this._renderEmployeeTabs();
        const employeesList = this._renderEmployeesList();

        return this.props.initialEmployee ? (
            <Layout
                controls={
                    <Link to={book.employeesPage}>
                        <Button type='default'>
                            <ArrowLeftOutlined />
                            <FormattedMessage id='back-to-list' />
                        </Button>
                    </Link>
                }
                title={this.props.employeeName}
            >
                <div className={Styles.employeePage}>
                    <section className={`${Styles.employeeSection} ${Styles.employeeTabs}`}>{employeeTabs}</section>
                    <section className={`${Styles.employeeSection} ${Styles.employeesList}`}>{employeesList}</section>
                </div>
            </Layout>
        ) : (
            <Loader loading={!this.props.initialEmployee} />
        );
    }

    _renderEmployeeTabs = () => {
        const { user, initialEmployee, location } = this.props;
        const { factSchedule, roles } = this.state;
        const employeeId = this.props.match.params.id;

        const specificTab = location && location.state ? location.state.specificTab : undefined;

        console.log(initialEmployee, 'initialEmployee');

        return (
            <Tabs defaultActiveKey={specificTab || '1'}>
                {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_GENERAL_DATA) && (
                    <TabPane
                        key='1'
                        tab={this.props.intl.formatMessage({
                            id: 'employee.general_data'
                        })}
                    >
                        <EmployeeForm
                            fireEmployee={this.fireEmployee}
                            initialEmployee={initialEmployee}
                            saveEmployee={this.saveEmployee}
                            user={user}
                            wrappedComponentRef={this.saveEmployeeFormRef}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_POSITIONS) && (
                    <TabPane
                        key='2'
                        tab={this.props.intl.formatMessage({
                            id: 'employee-page.positions'
                        })}
                    >
                        <PositionsSettings
                            employeeId={employeeId}
                            fetchEmployee={() => this.props.fetchEmployeeById(this.props.match.params.id)}
                            initialEmployee={initialEmployee}
                            positions={_.get(initialEmployee, 'posts')}
                            roles={roles}
                            user={user}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_WORK_SCHEDULE) && (
                    <TabPane
                        key='6'
                        tab={this.props.intl.formatMessage({
                            id: 'add-employee-page.schedule'
                        })}
                    >
                        <WorkingDaysTab
                            employee={
                                this.props.employees && this.props.employees.filter(e => e.id === Number(employeeId))
                            }
                            user={user}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_SALARY_SETTINGS) && (
                    <TabPane
                        key='settingSalary'
                        disabled={isForbidden(this.props.user, permissions.EMPLOYEES_SALARIES)}
                        tab={this.props.intl.formatMessage({
                            id: 'employee_salary_rules.salary_table'
                        })}
                    >
                        <SettingSalaryForm
                            employeeId={employeeId}
                            fetchEmployee={() => this.props.fetchEmployeeById(this.props.match.params.id)}
                            initialEmployee={initialEmployee}
                            positions={_.get(initialEmployee, 'posts')}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS) && (
                    <TabPane
                        key='empDebtContainer'
                        tab={this.props.intl.formatMessage({
                            id: 'navigation.receivables_and_payables'
                        })}
                    >
                        <EmployeeDebtContainer employeeId={employeeId} initialEmployee={initialEmployee} />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_WINDOW_SETTINGS) && (
                    <TabPane
                        key='4'
                        tab={this.props.intl.formatMessage({
                            id: 'employee-page.settings_tab'
                        })}
                    >
                        <SettingsTab employeeId={employeeId} positions={_.get(initialEmployee, 'posts')} />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_STATISTICS) && (
                    <TabPane
                        key='empStatTab'
                        tab={this.props.intl.formatMessage({
                            id: 'employee-page.statistics'
                        })}
                    >
                        <EmployeeStatistics
                            employeeId={employeeId}
                            factSchedule={factSchedule}
                            fetchFactSchedule={this.fetchFactSchedule}
                            labourHours={_.get(initialEmployee, 'labourHours')}
                            ordersCount={_.get(initialEmployee, 'ordersCount')}
                            user={user}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_REVIEWS) && (
                    <TabPane
                        key='7'
                        tab={this.props.intl.formatMessage({
                            id: 'employee-page.feedback'
                        })}
                    >
                        <EmployeeFeedback reviews={_.get(initialEmployee, 'reviews')} user={user} />
                    </TabPane>
                )}
            </Tabs>
        );
    };

    _renderEmployeesList = () => {
        const {
            employees,
            loading,
            history,
            intl: { formatMessage }
        } = this.props;
        const currentEmployeeId = Number(history.location.pathname.split('/')[2]);

        const _listItemStyles = id =>
            cx({
                listItem: true,
                listItemActive: currentEmployeeId === id
            });

        return employees ? (
            <List
                bordered
                className={Styles.switchBusinessList}
                dataSource={employees
                    .filter(item => item.disabled !== true)
                    .sort((a, b) => `${a.surname}`.localeCompare(`${b.surname}`))}
                loading={loading}
                locale={{ emptyText: formatMessage({ id: 'no_data' }) }}
                renderItem={item => (
                    <List.Item className={_listItemStyles(item.id)} onClick={() => this._linkToEmployee(item.id)}>
                        <List.Item.Meta
                            className={Styles.employeeListItem}
                            description={<div className={Styles.employeeListItemDesc}>{item.jobTitle}</div>}
                            title={<div className={Styles.employeeListItem}>{`${item.surname} ${item.name}`}</div>}
                        />
                    </List.Item>
                )}
            />
        ) : (
            <Loader loading={!employees} />
        );
    };
}
