import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const { confirm, warning } = Modal;

@injectIntl
export default class ReserveButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            brandId: undefined,
            brandSearchValue: ''
        };
    }

    getStoreProduct = (detailCode, brandId) => {
        const { detail, updateDetail } = this.props;
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/store_products?all=true`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                const product = data.list.find(product => product.code == detailCode && product.brandId == brandId);
                if (product) {
                    detail.productId = product.id;
                    detail.defaultWarehouseId = product.defaultWarehouseId;
                    updateDetail(detail);
                }
            })
            .catch(function (error) {
                console.log('error', error);
                that.setState({
                    fetched: true,
                    codeFilter: that.props.codeFilter
                });
            });
    };

    reserveProduct = async () => {
        const { detail, updateDetail } = this.props;
        if (!Number(detail.reservedCount)) {
            try {
                const payload = await fetchAPI(
                    'POST',
                    'store_docs/reserve_all_possible',
                    undefined,
                    {
                        ordersAppurtenanciesIds: [detail.id]
                    },
                    { handleErrorInternally: true }
                );
                if (payload.created) {
                    notification.success({
                        message: this.props.intl.formatMessage({ id: 'details_table.reserved' })
                    });
                } else {
                    notification.error({
                        message: this.props.intl.formatMessage({ id: 'details_table.not_reserved' })
                    });
                }
            } catch (err) {
                notification.error({ message: err.response.message });
            }
        } else {
            await fetchAPI(
                'POST',
                'store_docs/unreserve_all_possible',
                undefined,
                {
                    ordersAppurtenanciesIds: [detail.id]
                },
                { handleErrorInternally: true }
            );
            await notification.success({
                message: this.props.intl.formatMessage({ id: 'details_table.unreserved' })
            });
        }

        await updateDetail(detail);
    };

    addProduct = async () => {
        const {
            detail,
            updateDetail,
            mainWarehouseId,
            brands,
            intl: { formatMessage }
        } = this.props;
        const that = this;
        confirm({
            title: formatMessage({ id: 'storage_document.error.product_not_found' }),
            onOk: async () => {
                const postData = {
                    name: detail.detailName,
                    groupId: detail.storeGroupId,
                    code: detail.detailCode,
                    brandId: detail.brandId,
                    measureUnit: 'PIECE',
                    defaultWarehouseId: mainWarehouseId
                };
                if (detail.brandName && !detail.brandId) {
                    const defaultBrand = brands.find(brand => brand.brandName == detail.brandName);
                    if (defaultBrand) {
                        detail.brandId = defaultBrand.brandId;
                        postData.brandId = defaultBrand.brandId;
                    }
                }
                const token = localStorage.getItem('_my.carbook.pro_token');
                try {
                    const url = 'store_products';
                    const data = await fetchAPI('POST', url, null, postData, {
                        handleErrorInternally: true
                    });
                    if (data.created) {
                        updateDetail({ ...detail, productId: data.id });
                    }
                } catch (error) {
                    console.log('error', error);
                    that.getStoreProduct(detail.detailCode, detail.brandId);
                }
                // fetch(url, {
                //     method: 'POST',
                //     headers: {
                //         Authorization: token
                //     },
                //     body: JSON.stringify(postData)
                // })
                //     .then(function (response) {
                //         if (response.status !== 200) {
                //             return Promise.reject(new Error(response.statusText));
                //         }

                //         return Promise.resolve(response);
                //     })
                //     .then(function (response) {
                //         return response.json();
                //     })
                //     .then(function (data) {
                //         if (data.created) {
                //             updateDetail({ ...detail, productId: data.id });
                //         }
                //     })
                //     .catch(function (error) {
                //         console.log('error', error);
                //         that.getStoreProduct(detail.detailCode, detail.brandId);
                //     });
            }
        });
    };

    render() {
        const { detail, disabled, onClick } = this.props;

        return (
            <div>
                {detail.isFromStock ? (
                    <Button
                        disabled={disabled}
                        onClick={() => {
                            if (onClick) onClick();
                            this.reserveProduct();
                        }}
                        style={{
                            color: Number(detail.reservedCount)
                                ? Number(detail.reservedCount) == Number(detail.count)
                                    ? 'var(--green)'
                                    : 'red'
                                : null
                        }}
                    >
                        <p>{Number(detail.reservedCount) ? Number(detail.reservedCount).toFixed(2) : 0}</p>
                    </Button>
                ) : (
                    <Button disabled={disabled} icon={<PlusOutlined />} onClick={this.addProduct} />
                )}
            </div>
        );
    }
}
