export const ALL_CSH = {
    "cashOrders": {
        "stats": {
            "totalCount": {
                "ua": "загальна кількість",
                "en": "total count",
                "cz": "celkový počet",
                "bg": "общо брой"
            },
            "increase": {
                "ua": "збільшення",
                "en": "increase",
                "cz": "zvýšení",
                "bg": "увеличение"
            },
            "decrease": {
                "ua": "зменшення",
                "en": "decrease",
                "cz": "snížení",
                "bg": "намаление"
            },
            "balance": {
                "ua": "баланс",
                "en": "balance",
                "cz": "bilance",
                "bg": "баланс"
            }
        },
        "list": {
            "$id": {
                "ua": "id ідентифікатор",
                "en": "id identifier",
                "cz": "id identifikátor",
                "bg": "id идентификатор"
            },
            "$type": {
                "ua": "тип",
                "en": "type",
                "cz": "typ",
                "bg": "тип"
            },
            "$cashBoxId": {
                "ua": "id ідентифікатор каси",
                "en": "id cash register identifier",
                "cz": "id identifikátor pokladny",
                "bg": "id идентификатор каса"
            },
            "$datetime": {
                "ua": "дата та час",
                "en": "date and time",
                "cz": "datum a čas",
                "bg": "дата и час"
            },
            "$createdAt": {
                "ua": "створено",
                "en": "created",
                "cz": "vytvořeno",
                "bg": "създадено"
            },
            "$updatedAt": {
                "ua": "оновлено",
                "en": "updated",
                "cz": "aktualizováno",
                "bg": "актуализирано"
            },
            "$orderId": {
                "ua": "ідентифікатор замовлення",
                "en": "order identifier",
                "cz": "identifikátor objednávky",
                "bg": "идентификатор на поръчка"
            },
            "$increase": {
                "ua": "збільшення",
                "en": "increase",
                "cz": "zvýšení",
                "bg": "увеличение"
            },
            "$decrease": {
                "ua": "зменшення",
                "en": "decrease",
                "cz": "snížení",
                "bg": "намаление"
            },
            "$createdBy": {
                "ua": "ким створено",
                "en": "created by",
                "cz": "vytvořeno kým",
                "bg": "създаден от"
            },
            "$updatedBy": {
                "ua": "ким оновлено",
                "en": "updated by",
                "cz": "aktualizováno kým",
                "bg": "актуализирано от"
            },
            "$description": {
                "ua": "опис",
                "en": "description",
                "cz": "popis",
                "bg": "описание"
            },
            "$clientId": {
                "ua": "id ідентифікатор клієнта",
                "en": "id client identifier",
                "cz": "id identifikátor klienta",
                "bg": "id идентификатор на клиент"
            },
            "$employeeId": {
                "ua": "id ідентифікатор співробітника",
                "en": "id employee identifier",
                "cz": "id identifikátor zaměstnance",
                "bg": "id идентификатор на служител"
            },
            "$businessSupplierId": {
                "ua": "id ідентифікатор постачальника бізнесу",
                "en": "id business supplier identifier",
                "cz": "id identifikátor dodavatele podnikání",
                "bg": "id идентификатор на доставчик на бизнес"
            },
            "$otherCounterparty": {
                "ua": "інший контрагент",
                "en": "other counterparty",
                "cz": "jiný dodavatel",
                "bg": "друг контрагент"
            },
            "$tag": {
                "ua": "мітка",
                "en": "tag",
                "cz": "značka",
                "bg": "етикет"
            },
            "$externalId": {
                "ua": "id зовнішній ідентифікатор",
                "en": "id external identifier",
                "cz": "id externí identifikátor",
                "bg": "id външен идентификатор"
            },
            "$storeDocId": {
                "ua": "id ідентифікатор документа зберігання",
                "en": "id storage document identifier",
                "cz": "id identifikátor dokumentu úschovy",
                "bg": "id идентификатор на документ за съхранение"
            },
            "$salaryDocId": {
                "ua": "id ідентифікатор документа зарплати",
                "en": "id salary document identifier",
                "cz": "id identifikátor mzdového dokumentu",
                "bg": "id идентификатор на документ за заплата"
            },
            "$analyticsUniqueId": {
                "ua": "id унікальний ідентифікатор аналітики",
                "en": "id unique analytics identifier",
                "cz": "id unikátní identifikátor analýzy",
                "bg": "id уникален идентификатор на аналитика"
            },
            "$isRegisteredWithRst": {
                "ua": "зареєстровано в розрахунку",
                "en": "registered with calculation",
                "cz": "registrováno v výpočtu",
                "bg": "регистрирано в изчислението"
            },
            "$cashBoxName": {
                "ua": "назва каси",
                "en": "cash register name",
                "cz": "název pokladny",
                "bg": "име на каса"
            },
            "$fiscalNumber": {
                "ua": "фіскальний номер",
                "en": "fiscal number",
                "cz": "fiskální číslo",
                "bg": "фискален номер"
            },
            "$rst": {
                "ua": "РРО",
                "en": "Cash Register",
                "cz": "RP",
                "bg": "РРО"
            },
            "$counterPartName": {
                "ua": "назва контрагента",
                "en": "counterparty name",
                "cz": "název kontrahenta",
                "bg": "име на контрагент"
            },
            "$clientName": {
                "ua": "ім'я клієнта",
                "en": "client name",
                "cz": "jméno klienta",
                "bg": "име на клиент"
            },
            "$clientSurname": {
                "ua": "прізвище клієнта",
                "en": "client surname",
                "cz": "příjmení klienta",
                "bg": "фамилия на клиент"
            },
            "$employeeName": {
                "ua": "ім'я співробітника",
                "en": "employee name",
                "cz": "jméno zaměstnance",
                "bg": "име на служител"
            },
            "$employeeSurname": {
                "ua": "прізвище співробітника",
                "en": "employee surname",
                "cz": "příjmení zaměstnance",
                "bg": "фамилия на служител"
            },
            "$businessSupplierName": {
                "ua": "назва станції",
                "en": "business supplier name",
                "cz": "název stanice",
                "bg": "име на станция"
            },
            "$orderNum": {
                "ua": "номер замовлення",
                "en": "order number",
                "cz": "číslo objednávky",
                "bg": "номер на поръчка"
            },
            "$businessItn": {
                "ua": "Код ЄДРПОУ бізнесу",
                "en": "Business EDRPOU Code",
                "cz": "Kód EDRPOU podnikání",
                "bg": "Код ЕДРПОУ на бизнеса"
            },
            "$clientItn": {
                "ua": "Код ЄДРПОУ клієнта",
                "en": "Client EDRPOU Code",
                "cz": "Kód EDRPOU klienta",
                "bg": "Код ЕДРПОУ на клиента"
            },
            "$analyticsName": {
                "ua": "назва аналітики",
                "en": "analytics name",
                "cz": "název analýzy",
                "bg": "име на аналитика"
            },
            "$documentNumber": {
                "ua": "номер документа",
                "en": "document number",
                "cz": "číslo dokumentu",
                "bg": "номер на документ"
            },
            "$sequentialNumber": {
                "ua": "послідовний номер",
                "en": "sequential number",
                "cz": "pořadové číslo",
                "bg": "последователен номер"
            }
        }
    },
    "businessName": {
        "ua": "Назва станції",
        "en": "Business Station Name",
        "cz": "Název stanice",
        "bg": "Име на станция"
    },
    "businessItns": {
        "ua": "Код ЄДРПОУ станції",
        "en": "Business Station EDRPOU Code",
        "cz": "Kód EDRPOU stanice",
        "bg": "Код ЕДРПОУ на станцията"
    }
};